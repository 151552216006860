import React from 'react';
import {User} from '../models/User';

interface WelcomeProps {
  loggedInUser: User;
}

const Welcome: React.FunctionComponent<WelcomeProps> = (
  props: WelcomeProps,
) => {
  return (
    <div className={'box'}>
      <h1>
        Welcome back to your Onsite7 Admin System,{' '}
        {props.loggedInUser.firstName}
      </h1>
      <p>
        If you need any assistance then please contact{' '}
        <a href={'mailto: support@onsite7.co.uk'}>support@onsite7.co.uk</a>
      </p>
    </div>
  );
};

export default Welcome;
