import {startCase} from 'lodash';
import {DropdownOption} from '../components/Data/DataConst';

export interface User {
  _id: string;
  __v: number;
  created: Date;
  createdBy: string;
  authId: string;
  company: string;
  firstName: string;
  lastName: string;
  role: string;
  position: string;
  contact: {
    phone: string;
    address: {
      address1: string;
      address2: string;
      address3: string;
      postalCode: string;
    };
    email: string;
  };
  permissions: {
    financial: {
      job: {
        pricing: boolean;
        payments: boolean;
        costs: boolean;
      };
    };
  };
}

export const userInitialState: User = {
  _id: '',
  __v: 0,
  created: new Date(),
  createdBy: '',
  authId: '',
  company: '',
  firstName: '',
  lastName: '',
  role: '',
  position: '',
  contact: {
    phone: '',
    address: {
      address1: '',
      address2: '',
      address3: '',
      postalCode: '',
    },
    email: '',
  },
  permissions: {
    financial: {
      job: {
        pricing: true,
        payments: true,
        costs: false,
      },
    },
  },
};

export enum UserRole {
  SUPER_ADMIN = 'super-admin',
  MANAGER = 'manager',
  ADMIN = 'admin',
  USER = 'user',
  CUSTOMER = 'customer',
}

export class UserEnums {
  public static readonly UserRoles = [
    UserRole.SUPER_ADMIN,
    UserRole.MANAGER,
    UserRole.ADMIN,
    UserRole.USER,
  ];

  public static readonly UserRoleOptions: DropdownOption[] = [
    {
      key: UserRole.SUPER_ADMIN,
      text: 'Super Admin',
      value: UserRole.SUPER_ADMIN,
    },
    {
      key: UserRole.MANAGER,
      text: 'Manager (e.g. Manager/Owner)',
      value: UserRole.MANAGER,
    },
    {
      key: UserRole.ADMIN,
      text: 'Admin User (e.g. Office)',
      value: UserRole.ADMIN,
    },
    {
      key: UserRole.USER,
      text: 'Onsite User (e.g. Tradesmen/Contractor/Engineer)',
      value: UserRole.USER,
    },
  ];

  public static readonly AccessRoleOptions: DropdownOption[] = [
    {
      key: UserRole.MANAGER,
      text: startCase(UserRole.MANAGER),
      value: UserRole.MANAGER,
    },
    {
      key: UserRole.ADMIN,
      text: startCase(UserRole.ADMIN),
      value: UserRole.ADMIN,
    },
    {
      key: UserRole.USER,
      text: startCase(UserRole.USER),
      value: UserRole.USER,
    },
    {
      key: UserRole.CUSTOMER,
      text: startCase(UserRole.CUSTOMER),
      value: UserRole.CUSTOMER,
    },
  ];
}
