import {faStar} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {startCase} from 'lodash';
import moment from 'moment';
import React from 'react';
import {Divider, Input} from 'semantic-ui-react';
import {EventType} from '../const/crud';
import {Company} from '../models/Company';
import {
  Job,
  JobStage,
  jobStageMap,
  JobStatus,
  JobType,
  Stage,
} from '../models/Job';
import {DataHelper} from '../utils/dataHelper';
import MediaHelper from '../utils/mediaHelper';

interface SignatureViewProps {
  signature: () => Job['stages'][JobStage.POS]['signature'];
  stage: JobStage;
  stageObj: Stage;
  loggedInCompany: Company;
  jobType: JobType;
}

export enum Rating {
  EXCELLENT = 'excellent',
  VERY_GOOD = 'very good',
  GOOD = 'good',
  OKAY = 'okay',
  POOR = 'poor',
  NO_RATING = 'no rating',
}

export const ratingMap = (rating: number): Rating => {
  switch (rating) {
    case 1:
      return Rating.POOR;
    case 2:
      return Rating.OKAY;
    case 3:
      return Rating.GOOD;
    case 4:
      return Rating.VERY_GOOD;
    case 5:
      return Rating.EXCELLENT;
    default:
      return Rating.NO_RATING;
  }
};

export const buildStarRating = (rating: number) => {
  const stars: any = [];
  for (let i = 1; i < 6; i++) {
    if (i <= rating) {
      stars.push(
        <FontAwesomeIcon
          key={`star-rating-${i}`}
          className={'yellow'}
          icon={faStar}
        />,
      );
    } else {
      stars.push(
        <FontAwesomeIcon
          key={`star-rating-${i}`}
          className={'grey'}
          icon={faStar}
        />,
      );
    }
  }
  return <div>{stars}</div>;
};

const SignatureView: React.FunctionComponent<SignatureViewProps> = (
  props: SignatureViewProps,
) => {
  const signatures = props.signature();
  if (!signatures || !signatures.length) {
    return null;
  }

  const booleanToStringMap = (boolean: boolean) => {
    if (boolean) {
      return 'yes';
    } else {
      return 'no';
    }
  };

  const output = signatures.map((signature, index: number) => {
    const startedEvent = props.stageObj[props.stage].events.find(
      (e) =>
        e.type === EventType.STATUS_UPDATE && e.value === JobStatus.IN_PROGRESS,
    );

    return (
      <div className={'SignatureViewWrapper'} key={`signature-view-${index}`}>
        <div className={'SignatureInputRow'}>
          <div>
            <h3>Start Date</h3>
            <Input
              icon={'lock'}
              value={moment(startedEvent?.created || signature.created).format(
                'DD/MM/YYYY HH:mm',
              )}
            />
          </div>
          <div>
            <h3>Completed Date</h3>
            <Input
              icon={'lock'}
              value={moment(signature.created).format('DD/MM/YYYY HH:mm')}
            />
          </div>
        </div>

        <div className={'SignatureInputRow'}>
          <div>
            <h3>Completed By</h3>
            <Input icon={'lock'} value={signature.createdBy} />
          </div>
          <div>
            <h3>Completion Status</h3>
            <Input icon={'lock'} value={startCase(signature.status)} />
          </div>
        </div>

        {typeof signature.customerPresent !== 'undefined' && <Divider />}

        {typeof signature.customerPresent !== 'undefined' && (
          <>
            <div className={'SignatureInputRow'}>
              <div>
                <h3>Is Customer Present</h3>
                <Input
                  icon={'lock'}
                  value={startCase(
                    booleanToStringMap(signature.customerPresent),
                  )}
                />
              </div>

              {typeof signature.signatureObtained !== 'undefined' && (
                <div>
                  <h3>
                    Customer Agrees {startCase(jobStageMap(props.stage))} Is
                    Complete
                  </h3>
                  <Input
                    icon={'lock'}
                    value={startCase(
                      booleanToStringMap(signature.signatureObtained),
                    )}
                  />
                </div>
              )}

              {signature.rating && (
                <div>
                  <h3>Customer Satisfaction Rating</h3>
                  <div className={'StarRatingContainer'}>
                    {buildStarRating(signature.rating || 0)}
                    <p>{startCase(ratingMap(signature.rating || 0))}</p>
                  </div>
                </div>
              )}
            </div>

            {signature.comments && (
              <div className={'SignatureInputRow'}>
                <h3>Comments</h3>
                <Input icon={'lock'} value={startCase(signature.comments)} />
              </div>
            )}
          </>
        )}

        {(signature.name ||
          signature.signatureDeclinedReason ||
          signature.media) && <Divider />}

        {signature.name && (
          <div className={'SignatureInputRow'}>
            <div>
              <h3>Full Name Of Signatory</h3>
              <Input
                icon={'lock'}
                value={
                  signature.name ? startCase(signature.name.toString()) : '-'
                }
              />
            </div>
          </div>
        )}

        {signature.signatureDeclinedReason && (
          <div className={'SignatureInputRow'}>
            <div>
              <h3>Provided Reason</h3>
              <Input icon={'lock'} value={signature.signatureDeclinedReason} />
            </div>
          </div>
        )}

        {signature.media && (
          <div className={'SignatureInputRow'}>
            <div>
              <h3>
                {props.jobType === JobType.COMMERCIAL
                  ? 'Onsite User'
                  : 'Customer'}{' '}
                Signature
              </h3>
              {DataHelper.declarationMap(props.stage, false, props.jobType)}
              <div
                className={'SignatureImage'}
                style={{
                  backgroundImage: `url(
                  ${MediaHelper.getMediaUrl(signature.media.mediaId)})`,
                }}
              />
            </div>
          </div>
        )}
      </div>
    );
  });

  return (
    <div className={'SignatureViewContainer'}>
      <Divider horizontal>
        <h3>
          {startCase(
            jobStageMap(props.stage, props.loggedInCompany.stageTitles),
          )}{' '}
          Completion
        </h3>
      </Divider>
      {output}
    </div>
  );
};

export default SignatureView;
