import {cloneDeep, get, toLower, toUpper} from 'lodash';
import moment from 'moment';
import React, {ReactElement} from 'react';
import DatePicker from 'react-datepicker';
import Dropzone from 'react-dropzone';
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from 'react-google-places-autocomplete';
import VideoThumbnail from 'react-video-thumbnail';
import {Dropdown, Form, Input, TextArea} from 'semantic-ui-react';
import config from '../../config/config';
import ChecklistTemplate from '../ChecklistTemplate';
import CostGroup from '../CostGroup';
import DataAddressLookup from './DataAddressLookup';
import {DataFormType, Field} from './DataConst';
import {buildSelectOptions} from './DataForm';

interface FormFieldsProps {
  modalState: {
    data: any;
    setter: any;
  };
  fields: Field[];
  action;
}

const FormFields: React.FunctionComponent<FormFieldsProps> = (
  props: FormFieldsProps,
) => {
  const updateModalState = (data, field) => {
    const clonedData = cloneDeep(props.modalState.data);
    clonedData[field] = data;

    props.modalState.setter(clonedData);
  };

  const handleGoogleAddressSelect = async (data) => {
    const clonedData = cloneDeep(props.modalState.data);

    const [place] = await geocodeByPlaceId(data.value.place_id);
    const postcode = place.address_components.find((v) =>
      v.types.includes('postal_code'),
    )?.long_name;
    const town = place.address_components.find((v) =>
      v.types.includes('postal_town'),
    )?.long_name;
    const number = place.address_components.find((v) =>
      v.types.includes('street_number'),
    )?.long_name;
    const name = place.address_components.find((v) =>
      v.types.includes('premise'),
    )?.long_name;
    const street = place.address_components.find((v) =>
      v.types.includes('route'),
    )?.long_name;

    clonedData.contact = {
      ...clonedData.contact,
      address: {
        address1: name || (number ? `${number} ${street}` : street) || '',
        address2: name ? street : '',
        address3: town || '',
        postalCode: postcode || '',
      },
    };
    clonedData['contact.address.address1'] =
      name || (number ? `${number} ${street}` : street) || '';
    clonedData['contact.address.address2'] = name ? street : '';
    clonedData['contact.address.address3'] = town || '';
    clonedData['contact.address.postalCode'] = postcode || '';

    props.modalState.setter(clonedData);
  };

  const inputs: ReactElement[] = [];

  for (const field of props.fields) {
    if (field.showIf) {
      const ifField =
        props.modalState.data && get(props.modalState.data, field.showIf.field);

      if (ifField !== field.showIf.value) {
        continue;
      }
    }

    switch (field.type) {
      case DataFormType.TEXT:
      case DataFormType.TEXT_LOWER:
      case DataFormType.TEXT_UPPER:
        inputs.push(
          <Form.Field
            key={`text-${field.title}-${props.action &&
              props.action.action.dataId}`}
            className={field.className}
          >
            {field.title && (
              <div className={'FormFieldTitleContainer'}>
                <h3>{field.title}</h3>
                {field.optional &&
                field.optional === 'hidden' ? null : field.optional ? (
                  <span>*Optional</span>
                ) : (
                  <span className={'RedText'}>*Required</span>
                )}
              </div>
            )}
            {field.description && <p>{field.description}</p>}
            <Input
              style={{marginBottom: 20}}
              placeholder={`Enter text`}
              value={
                (props.modalState.data &&
                  get(props.modalState.data, field.dataField)) ||
                ''
              }
              onChange={(e, data) => {
                let formatted = data.value;
                if (field.type === DataFormType.TEXT_LOWER) {
                  formatted = toLower(data.value);
                } else if (field.type === DataFormType.TEXT_UPPER) {
                  formatted = toUpper(data.value);
                }
                updateModalState(formatted, field.dataField);
              }}
            />
          </Form.Field>,
        );
        break;

      case DataFormType.TEXT_READ_ONLY:
        inputs.push(
          <Form.Field
            key={`text-${field.title}-${props.action &&
              props.action.action.dataId}`}
            className={field.className}
          >
            {field.title && (
              <div className={'FormFieldTitleContainer'}>
                <h3>{field.title}</h3>
                {field.optional &&
                field.optional === 'hidden' ? null : field.optional ? (
                  <span>*Optional</span>
                ) : (
                  <span className={'RedText'}>*Required</span>
                )}
              </div>
            )}
            {field.description && <p>{field.description}</p>}
            <Input
              style={{marginBottom: 20}}
              placeholder={`Enter text`}
              value={
                (props.modalState.data &&
                  get(props.modalState.data, field.dataField)) ||
                ''
              }
            />
          </Form.Field>,
        );
        break;

      case DataFormType.NUMBER:
        inputs.push(
          <Form.Field
            key={`number-${field.title}-${props.action &&
              props.action.action.dataId}`}
            className={field.className}
          >
            {field.title && (
              <div className={'FormFieldTitleContainer'}>
                <h3>{field.title}</h3>
                {field.optional &&
                field.optional === 'hidden' ? null : field.optional ? (
                  <span>*Optional</span>
                ) : (
                  <span className={'RedText'}>*Required</span>
                )}
              </div>
            )}
            {field.description && <p>{field.description}</p>}
            <Input
              type={'number'}
              style={{marginBottom: 20}}
              placeholder={`Enter number`}
              value={
                (props.modalState.data &&
                  get(props.modalState.data, field.dataField)) ||
                ''
              }
              onChange={(e, data) => {
                updateModalState(data.value || 0, field.dataField);
              }}
            />
          </Form.Field>,
        );
        break;

      case DataFormType.CURRENCY:
        const amount = parseFloat(
          props.modalState.data && get(props.modalState.data, field.dataField),
        );

        inputs.push(
          <Form.Field
            key={`currency-${props.action && props.action.action.dataId}`}
          >
            {field.title && (
              <div className={'FormFieldTitleContainer'}>
                <h3>{field.title}</h3>
                {field.optional &&
                field.optional === 'hidden' ? null : field.optional ? (
                  <span>*Optional</span>
                ) : (
                  <span className={'RedText'}>*Required</span>
                )}
              </div>
            )}
            {field.description && <p>{field.description}</p>}
            <Input
              style={{marginBottom: 20}}
              autoComplete={'off'}
              type={'number'}
              label={'£'}
              placeholder={field.placeholder || `Enter ${field.title}`}
              value={amount || ''}
              onChange={(e, data) =>
                updateModalState(data.value, field.dataField)
              }
              error={field.error}
            />
          </Form.Field>,
        );
        break;

      case DataFormType.TEXT_AREA:
        inputs.push(
          <Form.Field
            key={`text-area-${props.action && props.action.action.dataId}`}
          >
            {field.title && (
              <div className={'FormFieldTitleContainer'}>
                <h3>{field.title}</h3>
                {field.optional &&
                field.optional === 'hidden' ? null : field.optional ? (
                  <span>*Optional</span>
                ) : (
                  <span className={'RedText'}>*Required</span>
                )}
              </div>
            )}
            {field.description && <p>{field.description}</p>}

            <TextArea
              value={
                (props.modalState.data &&
                  get(props.modalState.data, field.dataField)) ||
                ''
              }
              style={{marginBottom: 20}}
              placeholder={`Enter text`}
              onChange={(e, data) =>
                updateModalState(data.value, field.dataField)
              }
            />
          </Form.Field>,
        );
        break;

      case DataFormType.MEDIA_UPLOAD:
        inputs.push(
          <Form.Field
            key={`media-${props.action && props.action.action.dataId}`}
          >
            <Dropzone
              onDropAccepted={(files) =>
                updateModalState(files, field.dataField)
              }
              accept={
                'image/jpeg, image/gif, image/png, image/bmp, video/mp4, video/mpeg, video/x-msvideo, video/quicktime'
              }
            >
              {({getRootProps, getInputProps}) => (
                <div className={'FileDrop'} {...getRootProps()}>
                  <input {...getInputProps()} />
                  <p>Drag and drop files here, or click to select</p>
                  <p style={{fontSize: '0.8em'}}>
                    Max file size: 10mb. Accepted file types: .jpeg, .gif, .png,
                    .bmp, .mp4, .mpeg, .mov
                  </p>
                </div>
              )}
            </Dropzone>

            {props.modalState.data.media &&
              props.modalState.data.media.length > 0 && (
                <div className={'PromptModalFiles'}>
                  <div>
                    <h3>Photo/Video to be uploaded</h3>
                    {props.modalState.data.media &&
                      props.modalState.data.media.map((i, index) => {
                        const mediaType = i.type.split('/')[0];
                        if (mediaType === 'video') {
                          return (
                            <div
                              key={`file-${index}`}
                              className={'PromptModalFilesThumb'}
                            >
                              <VideoThumbnail
                                videoUrl={URL.createObjectURL(i)}
                                width={160}
                                height={105}
                                snapshotAtTime={0}
                              />
                            </div>
                          );
                        } else {
                          return (
                            <div
                              key={`file-${index}`}
                              className={'PromptModalFilesThumb'}
                              style={{
                                backgroundImage: `url(${URL.createObjectURL(
                                  i,
                                )})`,
                              }}
                            />
                          );
                        }
                      })}
                  </div>
                </div>
              )}
          </Form.Field>,
        );
        break;

      case DataFormType.ATTACHMENT_UPLOAD:
        inputs.push(
          <Form.Field
            key={`attachment-${props.action && props.action.action.dataId}`}
          >
            <Dropzone
              onDropAccepted={(files) =>
                updateModalState(files, field.dataField)
              }
              accept={
                'text/plain, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              }
            >
              {({getRootProps, getInputProps}) => (
                <div className={'FileDrop'} {...getRootProps()}>
                  <input {...getInputProps()} />
                  <p>Drag and drop files here, or click to select</p>
                  <p style={{fontSize: '0.8em'}}>
                    Max file size: 10mb. Accepted file types: .txt, .pdf, .doc,
                    .docx, .xls, .xlsx
                  </p>
                </div>
              )}
            </Dropzone>

            <div className={'PromptModalFiles'}>
              {props.modalState?.data?.attachment?.length > 0 && (
                <h3>Files to be uploaded</h3>
              )}

              {props.modalState?.data?.attachment?.length > 0 &&
                props.modalState.data.attachment.map((i, index) => {
                  return (
                    <div key={`file-${index}`}>
                      <p>{i.name}</p>
                    </div>
                  );
                })}
            </div>
          </Form.Field>,
        );
        break;

      case DataFormType.SELECT:
        inputs.push(
          <Form.Field
            key={`select-${props.action && props.action.action.dataId}-${
              field.title
            }`}
          >
            {field.title && (
              <div className={'FormFieldTitleContainer'}>
                <h3>{field.title}</h3>
                {field.optional &&
                field.optional === 'hidden' ? null : field.optional ? (
                  <span>*Optional</span>
                ) : (
                  <span className={'RedText'}>*Required</span>
                )}
              </div>
            )}

            {field.description && <p>{field.description}</p>}
            <Dropdown
              style={{marginBottom: 20}}
              value={
                props.modalState.data &&
                get(props.modalState.data, field.dataField)
              }
              className={field.error ? 'error' : ''}
              clearable
              placeholder={`Select ${field.title}`}
              fluid
              selection
              options={field.dataSet}
              onChange={(e, data) =>
                updateModalState(data.value, field.dataField)
              }
            />
          </Form.Field>,
        );
        break;

      case DataFormType.SELECT_MULTIPLE:
        inputs.push(
          <Form.Field
            key={`select-multiple-${props.action &&
              props.action.action.dataId}`}
          >
            {field.title && (
              <div className={'FormFieldTitleContainer'}>
                <h3>{field.title}</h3>
                {field.optional &&
                field.optional === 'hidden' ? null : field.optional ? (
                  <span>*Optional</span>
                ) : (
                  <span className={'RedText'}>*Required</span>
                )}
              </div>
            )}

            {field.description && <p>{field.description}</p>}
            <Dropdown
              autoComplete={'off'}
              style={{marginBottom: 20}}
              value={
                (props.modalState.data &&
                  get(props.modalState.data, field.dataField)) ||
                []
              }
              className={field.error ? 'error' : ''}
              clearable
              placeholder={`Add ${field.title}`}
              noResultsMessage={'Type to add options'}
              fluid
              search
              selection
              multiple
              allowAdditions={true}
              onAddItem={async (e, data) => field.handleAddition(data)}
              options={
                (
                  props.modalState.data &&
                  get(props.modalState.data, field.dataField)
                )?.map((f) => ({value: f, text: f, key: f})) || []
              }
              onChange={(e, data) =>
                updateModalState(data.value, field.dataField)
              }
            />
          </Form.Field>,
        );
        break;

      case DataFormType.SELECT_BOOLEAN:
        inputs.push(
          <Form.Field
            key={`select-boolean-${props.action && props.action.action.dataId}`}
          >
            {field.title && (
              <div className={'FormFieldTitleContainer'}>
                <h3>{field.title}</h3>
                {field.optional &&
                field.optional === 'hidden' ? null : field.optional ? (
                  <span>*Optional</span>
                ) : (
                  <span className={'RedText'}>*Required</span>
                )}
              </div>
            )}

            {field.description && <p>{field.description}</p>}
            <Dropdown
              style={{marginBottom: 20}}
              value={
                props.modalState.data &&
                get(props.modalState.data, field.dataField)?.toString()
              }
              className={field.error ? 'error' : ''}
              clearable
              placeholder={`Select ${field.title}`}
              fluid
              selection
              options={field.dataSet}
              onChange={(e, data) =>
                updateModalState(data.value, field.dataField)
              }
            />
          </Form.Field>,
        );
        break;
      case DataFormType.CHECKLIST_TEMPLATE:
        inputs.push(
          <ChecklistTemplate
            key={`checklist-template-${field.dataField}`}
            data={props.modalState.data}
            setTasks={(tasks) => {
              updateModalState(tasks, field.dataField);
            }}
            loggedInUser={field.dataSet.loggedInUser}
          />,
        );
        break;

      case DataFormType.COST_GROUP:
        inputs.push(
          <CostGroup
            key={`cost-groups-${field.dataField}`}
            data={props.modalState.data}
            setCostGroups={(costs) => {
              updateModalState(costs, field.dataField);
            }}
            loggedInUser={field.dataSet.loggedInUser}
            loggedInCompany={field.dataSet.loggedInCompany}
          />,
        );
        break;

      case DataFormType.ADDRESS_LOOKUP:
        inputs.push(
          <Form.Field
            key={`text-${field.title}-${props.action &&
              props.action.action.dataId}`}
            className={field.className}
          >
            {field.title && (
              <div className={'FormFieldTitleContainer'}>
                <h3>{field.title}</h3>
                {field.optional &&
                field.optional === 'hidden' ? null : field.optional ? (
                  <span>*Optional</span>
                ) : (
                  <span className={'RedText'}>*Required</span>
                )}
              </div>
            )}
            {field.description && <p>{field.description}</p>}

            <DataAddressLookup
              field={field}
              value={get(props.modalState.data, field.dataField) || ''}
              contactData={props.modalState.data}
              onChange={(data) => props.modalState.setter(data)}
            />
          </Form.Field>,
        );
        break;

      case DataFormType.GOOGLE_ADDRESS:
        inputs.push(
          <Form.Field
            key={`text-${field.title}-${props.action &&
              props.action.action.dataId}`}
            className={field.className}
          >
            {field.title && (
              <div className={'FormFieldTitleContainer'}>
                <h3>{field.title}</h3>
                {field.optional &&
                field.optional === 'hidden' ? null : field.optional ? (
                  <span>*Optional</span>
                ) : (
                  <span className={'RedText'}>*Required</span>
                )}
              </div>
            )}
            {field.description && <p>{field.description}</p>}

            <GooglePlacesAutocomplete
              apiKey={config.googleApiKey}
              apiOptions={{language: 'en', region: 'gb'}}
              autocompletionRequest={{
                componentRestrictions: {
                  country: 'gb',
                },
              }}
              selectProps={{
                onChange: (add) => {
                  handleGoogleAddressSelect(add);
                },
              }}
            />
          </Form.Field>,
        );
        break;

      case DataFormType.DATE_TIME:
        inputs.push(
          <Form.Field
            key={`text-${field.title}-${props.action &&
              props.action.action.dataId}`}
            className={field.className}
          >
            {field.title && (
              <div className={'FormFieldTitleContainer'}>
                <h3>{field.title}</h3>
                {field.optional &&
                field.optional === 'hidden' ? null : field.optional ? (
                  <span>*Optional</span>
                ) : (
                  <span className={'RedText'}>*Required</span>
                )}
              </div>
            )}
            {field.description && <p>{field.description}</p>}

            <DatePicker
              dateFormat={'dd/MM/yyyy hh:mm'}
              selected={
                get(props.modalState.data, field.dataField)
                  ? moment(get(props.modalState.data, field.dataField)).toDate()
                  : ''
              }
              showTimeSelect={true}
              timeIntervals={5}
              onChange={(date) =>
                updateModalState(moment(date).toISOString(), field.dataField)
              }
            />
          </Form.Field>,
        );
        break;

      case DataFormType.SEARCH_SELECT_API:
        inputs.push(
          <Form.Field
            key={`text-${field.title}-${props.action &&
              props.action.action.dataId}`}
            className={field.className}
          >
            {field.title && (
              <div className={'FormFieldTitleContainer'}>
                <h3>{field.title}</h3>
                {field.optional &&
                field.optional === 'hidden' ? null : field.optional ? (
                  <span>*Optional</span>
                ) : (
                  <span className={'RedText'}>*Required</span>
                )}
              </div>
            )}
            {field.description && <p>{field.description}</p>}
            <Dropdown
              autoComplete={'off'}
              className={`positionIcon ${field.error ? 'error' : ''} ${
                field.lookup ? 'DropdownLookup' : ''
              }`}
              icon={field.icon || 'search'}
              clearable
              placeholder={field.placeholder || `Type to search ${field.title}`}
              noResultsMessage={'Type to start searching.'}
              fluid
              search
              onSearchChange={(options, searchObj) =>
                props.action.action.data.handleSearch(
                  options,
                  searchObj,
                  field.dataType,
                )
              }
              selectOnBlur={false}
              selection
              value={get(props.modalState.data, field.dataField) || ''}
              onChange={(e, data) =>
                updateModalState(data.value, field.dataField)
              }
              options={buildSelectOptions(field.dataSet)}
            />
          </Form.Field>,
        );
        break;
    }
  }

  return <Form>{inputs}</Form>;
};

export default FormFields;
