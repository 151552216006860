import React, {FC, useState} from 'react';
import {DataType} from '../../../const/crud';
import {Company} from '../../../models/Company';
import {CRUDType} from '../../Data/DataConst';

interface QuickbooksAuthProps {
  company: Company;
  crudAction;
}

const QuickbooksAuth: FC<QuickbooksAuthProps> = ({company, crudAction}) => {
  const [authUrl, setAuthUrl] = useState<string>();

  const status = !!company.integrations?.quickbooks?.access_token;

  return (
    <div className={'IntegrationWrapper'}>
      <h1>Quickbooks Integration</h1>
      <p>
        Status: <span className={`StatusIcon ${status ? 'green' : 'red'}`} />
        <strong>{status ? 'Connected' : 'Not Connected'}</strong>
      </p>

      {status && (
        <button
          className={'ui icon button ui primary redButton'}
          onClick={async () => {
            delete company.integrations.quickbooks;

            await crudAction(CRUDType.UPDATE, DataType.COMPANY, company, true);
          }}
        >
          Disconnect Quickbooks
        </button>
      )}

      {!status && (
        <>
          <h2>To Connect Quickbooks</h2>

          <div className={'Steps'}>
            <div className={'Step'}>
              <p style={{margin: `0 10px 0 0`}}>
                <strong>Step 1</strong>
              </p>
              <button
                className={'ui icon button ui primary'}
                disabled={!!authUrl}
                onClick={async () => {
                  const authUrlResponse = await crudAction(
                    CRUDType.AUTH_QUICKBOOKS,
                    DataType.COMPANY,
                    company._id,
                    false,
                  );

                  setAuthUrl(authUrlResponse.data);
                }}
              >
                Start Authorisation
              </button>
            </div>

            <div className={'Step'}>
              <p style={{margin: `0 10px 0 0`}}>
                <strong>Step 2</strong>
              </p>
              <a
                className={`ui icon button ui primary ${
                  authUrl ? '' : 'disabled'
                }`}
                href={authUrl}
              >
                Connect Your Quickbooks Account
              </a>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default QuickbooksAuth;
