export enum DataType {
  COMPANY = 'company',
  CUSTOMER = 'customer',
  CUSTOM_FIELDS = 'custom-fields',
  JOB = 'job',
  LOGGED_IN_USER = 'logged-in-user',
  MEDIA = 'media',
  ATTACHMENT = 'attachment',
  NOTE = 'note',
  USER = 'user',
  USER_COUNT = 'user-count',
  INDUSTRY = 'industry',
  LOCATION = 'location',
  CONTACT = 'contact',
  CHECKLIST = 'checklist',
  CHECKLIST_TEMPLATE = 'checklist-template',
  PAYMENT = 'payment',
  COST_GROUPS = 'cost-groups',
  COST = 'cost',
  JOB_CODES = 'job-codes',
  THEME = 'theme',
  LEAD_SOURCES = 'lead-sources',
  STAFF_AVAILABILITY = 'staff-availability',
}

export enum DataSubType {
  SELECTED = 'selected',
  COLLECTION = 'collection',
}

export enum EventType {
  STATUS_UPDATE = 'status-update',
  ASSIGNMENT = 'assignment',
}
