import {CRUDType} from '../components/Data/DataConst';
import config from '../config/config';
import {DataType} from '../const/crud';
import {Company} from '../models/Company';
import {User, UserRole} from '../models/User';
import AxiosHelper from './axiosHelper';

export default class CRUDHelper extends AxiosHelper {
  private data: any;

  constructor(
    token,
    private loggedInUser: User,
    private loggedInCompany: Company,
    private setLoading: any,
  ) {
    super(token);
  }

  public process(data: any, createdBy?: string) {
    this.data = data;

    if (typeof this.data === 'object') {
      if (this.data._id === '') {
        delete this.data._id;
      }
      this.data.createdBy = this.data.createdBy || createdBy;
      this.data.created = this.data.created || new Date().toISOString();
    }

    return this;
  }

  public async run(
    action: CRUDType,
    dataType: DataType,
    url?: string,
    filters?: any,
  ) {
    this.setLoading(true);
    let result;
    switch (action) {
      case CRUDType.SEARCH:
        result = await this.search(dataType, this.data, url);
        this.setLoading(false);
        return result;
      case CRUDType.CREATE:
        result = await this.create(dataType, this.data, url);
        this.setLoading(false);
        return result;
      case CRUDType.READ:
        result = await this.read(dataType, url, filters);
        this.setLoading(false);
        return result;
      case CRUDType.READ_ONE:
        result = await this.readOne(dataType, this.data, url);
        this.setLoading(false);
        return result;
      case CRUDType.UPDATE:
        result = await this.update(dataType, this.data, url);
        this.setLoading(false);
        return result;
      case CRUDType.DELETE:
        result = await this.remove(dataType, this.data, url);
        this.setLoading(false);
        return result;
      case CRUDType.UPLOAD_MEDIA:
        result = await this.uploadMedia(dataType, this.data, url);
        this.setLoading(false);
        return result;
      case CRUDType.GET_MEDIA:
        result = await this.getMedia(dataType, this.data, url);
        this.setLoading(false);
        return result;
      case CRUDType.DELETE_MEDIA:
        result = await this.deleteMedia(dataType, this.data, url);
        this.setLoading(false);
        return result;
      case CRUDType.IMPORT_FILE:
        result = await this.importFile(dataType, this.data, url);
        this.setLoading(false);
        return result;
      case CRUDType.AUTH_XERO:
        result = await this.authenticateXero(this.data);
        this.setLoading(false);
        return result;
      case CRUDType.AUTH_QUICKBOOKS:
        result = await this.authenticateQuickbooks(this.data);
        this.setLoading(false);
        return result;
    }
    this.setLoading(false);
  }

  private async search(dataType: DataType, query: string, url?: string) {
    let adminSearch;
    if (this.loggedInUser.role === UserRole.SUPER_ADMIN) {
      adminSearch = `&adminUser=${this.loggedInUser._id}&`;
    }

    return await this.get(
      url ||
        `${config.api}/${dataType}/search/?company=${
          this.loggedInUser.company
        }${adminSearch ? adminSearch : ''}&search=${query}`,
    );
  }

  private async create(dataType: DataType, data: any, url?: string) {
    if (dataType === DataType.CUSTOMER) {
      try {
        let xeroIntegration = this.loggedInCompany.integrations?.xero
          ?.access_token;
        let quickbooksIntegration = this.loggedInCompany.integrations
          ?.quickbooks?.access_token;

        if (xeroIntegration && !data.xeroContactId) {
          const contact: any = {
            name:
              data.companyName ||
              `${
                data.contact[0].firstName ? data.contact[0].firstName + ' ' : ''
              }${data.contact[0].lastName}`,
            firstName: data.contact[0].firstName,
            lastName: data.contact[0].lastName,
            addresses: [
              {
                addressType: 'STREET',
                addressLine1: data.contact[0].address.address1,
                city: data.contact[0].address.address3,
                postalCode: data.contact[0].address.postalCode,
              },
            ],
            phones: [
              {
                phoneType: 'DEFAULT',
                phoneNumber: data.contact[0].phone,
              },
            ],
          };

          if (data.contact[0].address.address2) {
            contact.addresses[0].AddressLine2 =
              data.contact[0].address.address2;
          }

          if (data.contact[0].email) {
            contact.emailAddress = data.contact[0].email;
          }

          // Create
          const xeroContact = await this.post(
            `${config.api}/integration/xero/create-contact/?company=${this.loggedInUser.company}`,
            contact,
          );

          if (xeroContact) {
            const contactId = xeroContact?.data?.body?.contacts[0].contactID;

            if (contactId) {
              data.xeroContactId = contactId;
            }
          }
        }

        if (quickbooksIntegration && !data.quickbooksContactId) {
          const contact: any = {
            CompanyName: data.companyName,

            GivenName: data.contact[0].firstName,
            FamilyName: data.contact[0].lastName,

            BillAddr: {
              Line1: data.contact[0].address.address1,
              City: data.contact[0].address.address3,
              PostalCode: data.contact[0].address.postalCode,
            },

            PrimaryPhone: {
              FreeFormNumber: data.contact[0].phone,
            },
            PrimaryEmailAddr: {
              Address: data.contact[0].email,
            },
          };

          // Create
          const qbContact = await this.post(
            `${config.api}/integration/quickbooks/create-contact/?company=${this.loggedInUser.company}`,
            contact,
          );

          if (qbContact) {
            const contactId = qbContact?.data?.Id;

            if (contactId) {
              data.quickbooksContactId = contactId;
            }
          }
        }
      } catch (err) {
        console.log(err);
      }
    }

    return await this.post(
      url || `${config.api}/${dataType}/?company=${this.loggedInUser.company}`,
      data,
    );
  }

  private async read(dataType: DataType, url?: string, filters?: any) {
    filters = {
      company: this.loggedInUser.company,
      ...filters,
    };

    if (this.loggedInUser.role === UserRole.SUPER_ADMIN) {
      filters.adminUser = this.loggedInUser._id;
    }

    return await this.get(url || `${config.api}/${dataType}/`, filters);
  }

  private async readOne(dataType: DataType, id: string, url?: string) {
    let adminUser;
    if (this.loggedInUser.role === UserRole.SUPER_ADMIN) {
      adminUser = `&adminUser=${this.loggedInUser._id}`;
    }

    return await this.get(
      url ||
        `${config.api}/${dataType}/${id}?company=${this.loggedInUser.company}${
          adminUser ? adminUser : ''
        }`,
    );
  }

  private async update(dataType: DataType, data: any, url?: string) {
    return await this.put(
      url || `${config.api}/${dataType}/?company=${this.loggedInUser.company}`,
      data,
    );
  }

  private async remove(dataType: DataType, id: any, url?: string) {
    await this.delete(
      url ||
        `${config.api}/${dataType}/${id}?company=${this.loggedInUser.company}`,
    );
  }

  private async uploadMedia(dataType: DataType, data: any, url?: string) {
    return await this.uploadFile(url || `${config.api}/${dataType}/`, data);
  }

  private async getMedia(dataType: DataType, id: any, url?: string) {
    return await this.get(url || `${config.api}/${dataType}/${id}`, [], false);
  }

  private async deleteMedia(dataType: DataType, id: any, url?: string) {
    return await this.delete(url || `${config.api}/${dataType}/${id}`);
  }

  private async importFile(dataType: DataType, data: any, url?: string) {
    return await this.uploadFile(
      url || `${config.api}/import/${dataType}/`,
      data,
    );
  }

  private async authenticateXero(company) {
    return await this.get(
      `${config.api}/integration/xero/auth?company=${company}`,
    );
  }

  private async authenticateQuickbooks(company) {
    return await this.get(
      `${config.api}/integration/quickbooks/auth?company=${company}`,
    );
  }
}
