import axios from 'axios';
import ObjectId from 'bson-objectid';
import React, {FC, useEffect, useState} from 'react';
import {Button, Dropdown} from 'semantic-ui-react';
import config from '../../../config/config';
import {Company} from '../../../models/Company';
import {CustomerTitle} from '../../../models/Customer';
import {User} from '../../../models/User';
import {useAuth0} from '../../../react-auth0-spa';
import {DataActionType} from '../../Data/DataConst';

interface QuickbooksProps {
  loggedInUser: User;
  company: Company;
  value: string;
  onChange: any;
  handleDataAction: any;
}

const QuickbooksCustomerSearch: FC<QuickbooksProps> = ({
  loggedInUser,
  company,
  value,
  onChange,
  handleDataAction,
}) => {
  const {getTokenSilently} = useAuth0();

  const [token, setToken] = useState<string>();
  const [searchOptions, setSearchOptions] = useState([]);
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    (async () => {
      if (!token) {
        let tok = await getTokenSilently();
        setToken(tok);
      }
    })();
  }, [token, setToken, getTokenSilently]);

  const buildSelectOptions = (customers) => {
    const formatted = customers.map((i, index) => {
      return {
        key: `quickbooks-cus-${i.name}-${index}`,
        value: i.Id,
        text: `${i.CompanyName ? `${i.CompanyName} - ` : ''}${i.GivenName} ${
          i.FamilyName
        }`,
      };
    });

    setSearchOptions(formatted);
  };

  useEffect(() => {
    if (
      token &&
      value?.length > 0 &&
      (searchResults.length === 0 || !searchResults.find((i) => i.Id === value))
    ) {
      (async () => {
        // Get contact from Quickbooks
        if (token) {
          const url = `${config.api}/integration/quickbooks/search-customers?company=${company._id}&contactId=${value}`;

          const customers = await axios.get(url, {
            headers: {Authorization: 'Bearer ' + token},
          });

          setSearchResults(customers?.data?.data || []);

          buildSelectOptions(customers?.data?.data || []);
        }
      })();
    }
  }, [company._id, searchResults, token, value]);

  const handleSearch = async (options, searchObj) => {
    const query = searchObj.searchQuery;
    if (token && query?.length > 2) {
      const url = `${config.api}/integration/quickbooks/search-customers?company=${company._id}&search=${query}`;

      const customers = await axios.get(url, {
        headers: {Authorization: 'Bearer ' + token},
      });

      if (!customers?.data?.data) {
        return;
      }

      const results = customers.data.data.reduce((acc, cus) => {
        const exists = searchResults.find((r) => (r.contactID || r.Id) === (cus.contactID || cus.Id));

        if (exists) {
          return acc;
        }

        return [...acc, cus]
      }, []);

      setSearchResults(results);

      buildSelectOptions(results);
    }
  };

  const canBeImported = () => {
    const customer = searchResults.find((i) => i.Id === value);

    if (!customer || !customer?.BillAddr) {
      return false;
    }

    let hasCompleteAddresses = false;
    const phone = customer.PrimaryPhone;

    if (
      customer.BillAddr.Line1 &&
      customer.BillAddr.City &&
      customer.BillAddr.PostalCode
    ) {
      hasCompleteAddresses = true;
    }

    if (!hasCompleteAddresses || !phone?.FreeFormNumber) {
      return false;
    }

    return true;
  };

  const autofillCustomer = () => {
    const customer = searchResults.find((i) => i.Id === value);

    if (!customer || !customer?.BillAddr) {
      return;
    }

    let hasCompleteAddresses = false;
    const phone = customer.PrimaryPhone;

    if (
      customer.BillAddr.Line1 &&
      customer.BillAddr.City &&
      customer.BillAddr.PostalCode
    ) {
      hasCompleteAddresses = true;
    }

    if (!hasCompleteAddresses || !phone?.FreeFormNumber) {
      return false;
    }

    // Company
    const companyName = customer.CompanyName;

    // Name
    const firstName = customer.GivenName;
    const lastName = customer.FamilyName;

    // Contact
    const add = customer.BillAddr;

    return {
      companyName,
      customer: {
        _id: new ObjectId().toHexString(),
        created: new Date(),
        createdBy: loggedInUser._id,
        title: CustomerTitle.NA,
        firstName: firstName,
        lastName: lastName,
        phone: phone?.FreeFormNumber,
        address: {
          address1: add.Line1,
          address3: add.City,
          postalCode: add.PostalCode,
        },
        email: customer.PrimaryEmailAddr?.Address,
      },
    };
  };

  return (
    <div>
      <Dropdown
        autoComplete={'off'}
        icon={'search'}
        clearable
        placeholder={`Type to search Quickbooks customers via their surname`}
        noResultsMessage={
          'Type to search Quickbooks customers via their surname'
        }
        fluid
        search
        onSearchChange={(options, searchObj) =>
          handleSearch(options, searchObj)
        }
        selectOnBlur={false}
        selection
        value={value}
        onChange={(e, data) => {
          onChange(data.value);
        }}
        options={searchOptions}
      />

      {value && (
        <Button
          disabled={!canBeImported()}
          style={{marginTop: 20}}
          primary
          onClick={() => {
            const contact = autofillCustomer();

            if (contact) {
              handleDataAction(DataActionType.IMPORT_QUICKBOOKS_CUSTOMER, '', {
                contact: contact.customer,
                companyName: contact.companyName,
              });
            }
          }}
        >
          Autofill Customer From Quickbooks
        </Button>
      )}
    </div>
  );
};

export default QuickbooksCustomerSearch;
