import axios from 'axios';
import moment from 'moment';
import config from '../config/config';
import {User} from '../models/User';

const jobHeaders = [
  'jobType',
  'vat',
  'jobId',
  'type',
  'description',
  'customer - installation - companyName',
  'customer - installation - firstName',
  'customer - installation - lastName',
  'customer - installation - email',
  'customer - installation - address 1',
  'customer - installation - address 2',
  'customer - installation - town',
  'customer - installation - postcode',
  'customer - installation - phone number',
  'customer - billing - companyName',
  'customer - billing - firstName',
  'customer - billing - lastName',
  'customer - billing - email',
  'customer - billing - address 1',
  'customer - billing - address 2',
  'customer - billing - town',
  'customer - billing - postcode',
  'customer - billing - phone number',
  'price',
  'POS - started',
  'POS - ended',
  'POS - assigned to',
  'PRE - started',
  'PRE - ended',
  'PRE - assigned to',
  'Admin - started',
  'Admin - ended',
  'Admin - assigned to',
  'POST - started',
  'POST - ended',
  'POST - assigned to',
  'Service - started',
  'Service - ended',
  'Service - assigned to',
];

const JobExportData = async (
  filters: any,
  token: string,
  loggedInUser: User,
) => {
  const result = await axios.get(
    `${config.api}/job/processed?company=${loggedInUser.company}`,
    {
      headers: {Authorization: 'Bearer ' + token},
      params: filters,
    },
  );

  const fileName = `jobs-export.csv`;

  const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
  const csv = [
    jobHeaders.join(','), // header row first
    ...result.data.data.map((row) => {
      return jobHeaders
        .map((fieldName) => {
          switch (fieldName) {
            case 'customer - installation - companyName':
              return `${row.contact.companyName || ''}`;

            case 'customer - installation - firstName':
              return `${row.contact.installation.firstName}`;

            case 'customer - installation - lastName':
              return `${row.contact.installation.lastName}`;

            case 'customer - installation - email':
              return `${row.contact.installation.email}`;

            case 'customer - installation - address 1':
              return `"${row.contact.installation.address?.address1 || ''}"`;

            case 'customer - installation - address 2':
              return `"${row.contact.installation.address?.address2 || ''}"`;

            case 'customer - installation - town':
              return `"${row.contact.installation.address?.address3 || ''}"`;

            case 'customer - installation - postcode':
              return `"${row.contact.installation.address?.postalCode || ''}"`;

            case 'customer - installation - phone number':
              return row.contact.installation.phone || '';

            case 'customer - billing - companyName':
              return `${row.contact.companyName || ''}`;

            case 'customer - billing - firstName':
              return `${row.contact.billing.firstName}`;

            case 'customer - billing - lastName':
              return `${row.contact.billing.lastName}`;

            case 'customer - billing - email':
              return `${row.contact.billing.email}`;

            case 'customer - billing - address 1':
              return `"${row.contact.billing.address?.address1 || ''}"`;

            case 'customer - billing - address 2':
              return `"${row.contact.billing.address?.address2 || ''}"`;

            case 'customer - billing - town':
              return `"${row.contact.billing.address?.address3 || ''}"`;

            case 'customer - billing - postcode':
              return `"${row.contact.billing.address?.postalCode || ''}"`;

            case 'customer - billing - phone number':
              return row.contact.billing.phone || '';

            case 'price':
              return row.price.value;

            // POS
            case 'POS - started':
              return row.stages.pos.start
                ? moment(row.stages.pos.start).format('DD/MM/YYYY HH:mm')
                : '';

            case 'POS - ended':
              return row.stages.pos.due
                ? moment(row.stages.pos.due).format('DD/MM/YYYY HH:mm')
                : '';

            case 'POS - assigned to':
              return row.stages.pos.assignedTo;

            // PRE
            case 'PRE - started':
              return row.stages.pre.start
                ? moment(row.stages.pre.start).format('DD/MM/YYYY HH:mm')
                : '';

            case 'PRE - ended':
              return row.stages.pre.due
                ? moment(row.stages.pre.due).format('DD/MM/YYYY HH:mm')
                : '';

            case 'PRE - assigned to':
              return row.stages.pre.assignedTo;

            // Admin
            case 'Admin - started':
              return row.stages.admin.start
                ? moment(row.stages.admin.start).format('DD/MM/YYYY HH:mm')
                : '';

            case 'Admin - ended':
              return row.stages.admin.due
                ? moment(row.stages.admin.due).format('DD/MM/YYYY HH:mm')
                : '';

            case 'Admin - assigned to':
              return row.stages.admin.assignedTo;

            // POST
            case 'POST - started':
              return row.stages.post.start
                ? moment(row.stages.post.start).format('DD/MM/YYYY HH:mm')
                : '';

            case 'POST - ended':
              return row.stages.post.due
                ? moment(row.stages.post.due).format('DD/MM/YYYY HH:mm')
                : '';

            case 'POST - assigned to':
              return row.stages.post.assignedTo;

            // Service
            case 'Service - started':
              return row.stages.service.start
                ? moment(row.stages.service.start).format('DD/MM/YYYY HH:mm')
                : '';

            case 'Service - ended':
              return row.stages.service.due
                ? moment(row.stages.service.due).format('DD/MM/YYYY HH:mm')
                : '';

            case 'Service - assigned to':
              return row.stages.service.assignedTo;
          }

          return JSON.stringify(row[fieldName], replacer);
        })
        .join(',');
    }),
  ].join('\r\n');

  // Create link and download
  const link = document.createElement('a');
  link.setAttribute(
    'href',
    'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(csv),
  );
  link.setAttribute('download', fileName);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default JobExportData;
