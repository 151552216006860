import {cloneDeep} from 'lodash';
import socketIOClient from 'socket.io-client';
import {CRUDType, DropdownOption} from '../components/Data/DataConst';
import config from '../config/config';
import {DataSubType, DataType} from '../const/crud';
import {Company} from '../models/Company';
import {Customer} from '../models/Customer';
import {Job} from '../models/Job';
import {User} from '../models/User';
import {DataHelper} from './dataHelper';

interface Event {
  model: DataType;
  eventType: CRUDType;
  data: any;
  company: string;
}

interface SocketSetters {
  [DataType.COMPANY]?: {
    collection: any;
    selected?: any;
    options?: any;
    total?: any;
  };
  [DataType.USER]?: {
    collection: any;
    selected?: any;
    options?: any;
    total?: any;
    count?: any;
  };
  [DataType.CUSTOMER]?: {
    collection: any;
    selected?: any;
    options?: any;
    total?: any;
  };
  [DataType.JOB]?: {
    collection: any;
    selected?: any;
    options?: any;
    total?: any;
  };
}

export interface SocketState {
  [DataType.COMPANY]?: {
    collection: Company[];
    selected?: Company;
    options?: DropdownOption[];
    total?: number;
  };
  [DataType.USER]?: {
    collection: User[];
    selected?: User;
    options?: DropdownOption[];
    total?: number;
    count?: {
      current: number;
      max: number;
    };
  };
  [DataType.CUSTOMER]?: {
    collection: Customer[];
    selected?: Customer;
    options?: DropdownOption[];
    total?: number;
  };
  [DataType.JOB]?: {
    collection: Job[];
    selected?: Job;
    options?: DropdownOption[];
    total?: number;
  };
}

let stateSetters: SocketSetters = {};

let state: SocketState = {
  [DataType.COMPANY]: {
    collection: [],
  },
  [DataType.USER]: {
    collection: [],
  },
  [DataType.CUSTOMER]: {
    collection: [],
  },
  [DataType.JOB]: {
    collection: [],
  },
};

let systemState: any = {
  liveUpdates: {
    status: false,
  },
};

export default class SocketHelper {
  public connect(token, company) {
    let socket = socketIOClient(config.socket, {
      forceNew: true,
      reconnection: false,
    });
    let connected = false;

    const checkConnected = () => {
      if (!socket.connected) {
        setTimeout(() => {
          socket.connect();
          checkConnected();
        }, 3000);
      }
    };
    checkConnected();

    socket.on('connect', () => {
      if (!connected) {
        socket.emit('authentication', {
          token,
          company,
        });
      }
    });

    socket.on('authenticated', () => {
      socket.on('successfullyAuthenticated', () => {
        console.info('Socket connected');
        if (systemState.liveUpdates.setStatus) {
          systemState.liveUpdates.setStatus(true);
        }
        connected = true;
      });

      socket.on('disconnect', () => {
        console.info('Socket disconnected');
        if (systemState.liveUpdates.setStatus) {
          systemState.liveUpdates.setStatus(false);
        }
        setTimeout(() => {
          connected = false;
          this.connect(token, company);
        }, 2500);
      });

      socket.on('event', (event) => {
        this.processEvent(event);
      });
    });
  }

  public getState() {
    return state;
  }

  public addSetters(setters: SocketSetters) {
    stateSetters = setters;
  }
  public addState(newState) {
    state = newState;
  }
  public updateState(dataType, dataSubType, data) {
    if (state && state[dataType]) {
      state[dataType][dataSubType] = data;
    }
  }

  public addLiveUpdateSetter(setter) {
    systemState.liveUpdates.setStatus = setter;
  }

  public processEvent(event: Event) {
    switch (event.eventType) {
      case CRUDType.CREATE:
        if (!stateSetters[event.model]) {
          break;
        }

        const clonedForCreation = cloneDeep(
          state[event.model][DataSubType.COLLECTION],
        );

        clonedForCreation.unshift(event.data);

        stateSetters[event.model][DataSubType.COLLECTION](clonedForCreation);
        this.updateState(
          event.model,
          DataSubType.COLLECTION,
          clonedForCreation,
        );

        // Build options
        if (stateSetters[event.model].options) {
          SocketHelper.buildSelectOptions(event.model, clonedForCreation);
        }

        // Set call for total
        if (stateSetters[event.model].total) {
          stateSetters[event.model].total();
        }

        break;
      case CRUDType.DELETE:
        if (!stateSetters[event.model]) {
          break;
        }

        let clonedForDeletion = cloneDeep(
          state[event.model][DataSubType.COLLECTION],
        );

        clonedForDeletion = clonedForDeletion.filter(
          (item) => item._id !== event.data._id,
        );

        stateSetters[event.model][DataSubType.COLLECTION](clonedForDeletion);
        this.updateState(
          event.model,
          DataSubType.COLLECTION,
          clonedForDeletion,
        );

        // Build options
        if (stateSetters[event.model].options) {
          SocketHelper.buildSelectOptions(event.model, clonedForDeletion);
        }

        // Set call for total
        if (stateSetters[event.model].total) {
          stateSetters[event.model].total();
        }

        break;
      case CRUDType.UPDATE:
        if (!stateSetters[event.model]) {
          break;
        }

        let clonedForUpdate = cloneDeep(
          state[event.model][DataSubType.COLLECTION],
        );
        clonedForUpdate = clonedForUpdate.map((item) => {
          if (item._id === event.data._id) {
            item = event.data;
          }
          return item;
        });

        stateSetters[event.model][DataSubType.COLLECTION](clonedForUpdate);
        this.updateState(event.model, DataSubType.COLLECTION, clonedForUpdate);

        // Set selected
        if (
          stateSetters[event.model][DataSubType.SELECTED] &&
          state[event.model][DataSubType.SELECTED]._id === event.data._id
        ) {
          stateSetters[event.model][DataSubType.SELECTED](event.data);
        }

        // Build options
        if (stateSetters[event.model].options) {
          SocketHelper.buildSelectOptions(event.model, clonedForUpdate);
        }
        break;
      case CRUDType.COUNT:
        if (!stateSetters[event.model]) {
          break;
        }

        stateSetters[event.model].count(event.data);
        break;
    }
  }

  private static buildSelectOptions(dataType: DataType, dataSet: any[]) {
    let options: DropdownOption[];
    const stateSetter = stateSetters[dataType];
    switch (dataType) {
      case DataType.COMPANY:
        if (stateSetter.options) {
          options = DataHelper.buildCompanySelectOptions(dataSet);
          stateSetter.options(options);
        }
        break;
      case DataType.USER:
        if (stateSetter.options) {
          options = DataHelper.buildUserSelectOptions(dataSet);
          stateSetter.options(options);
        }
        break;
      case DataType.CUSTOMER:
        if (stateSetter.options) {
          options = DataHelper.buildUserSelectOptions(dataSet);
          stateSetter.options(options);
        }
        break;
    }
  }
}
