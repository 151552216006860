import axios from 'axios';
import ObjectId from 'bson-objectid';
import React, {FC, useEffect, useState} from 'react';
import {Button, Dropdown} from 'semantic-ui-react';
import config from '../../../config/config';
import {Company} from '../../../models/Company';
import {CustomerTitle} from '../../../models/Customer';
import {User} from '../../../models/User';
import {useAuth0} from '../../../react-auth0-spa';
import {DataActionType} from '../../Data/DataConst';

interface XeroProps {
  loggedInUser: User;
  company: Company;
  value: string;
  onChange: any;
  handleDataAction: any;
}

const XeroCustomerSearch: FC<XeroProps> = ({
  loggedInUser,
  company,
  value,
  onChange,
  handleDataAction,
}) => {
  const {getTokenSilently} = useAuth0();

  const [token, setToken] = useState<string>();
  const [searchOptions, setSearchOptions] = useState([]);
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    (async () => {
      if (!token) {
        let tok = await getTokenSilently();
        setToken(tok);
      }
    })();
  }, [token, setToken, getTokenSilently]);

  const buildSelectOptions = (customers) => {
    const formatted = customers.map((i, index) => {
      return {
        key: `xero-cus-${i.name}-${index}`,
        value: i.contactID,
        text: i.name,
      };
    });

    setSearchOptions(formatted);
  };

  useEffect(() => {
    if (
      token &&
      value?.length > 0 &&
      (searchResults.length === 0 ||
        !searchResults.find((i) => i.contactID === value))
    ) {
      (async () => {
        // Get contact from Xero
        if (token) {
          const url = `${config.api}/integration/xero/search-customers?company=${company._id}&contactId=${value}`;

          const customers = await axios.get(url, {
            headers: {Authorization: 'Bearer ' + token},
          });

          setSearchResults(customers?.data?.data || []);

          buildSelectOptions(customers?.data?.data || []);
        }
      })();
    }
  }, [company._id, searchResults, token, value]);

  const handleSearch = async (options, searchObj) => {
    const query = searchObj.searchQuery;
    if (token && query?.length > 2) {
      const url = `${config.api}/integration/xero/search-customers?company=${company._id}&search=${query}`;

      const customers = await axios.get(url, {
        headers: {Authorization: 'Bearer ' + token},
      });

      let results = searchResults || [];

      for (const cus of customers?.data?.data) {
        const found = results.find((i) => i.contactID === cus.contactID);

        if (!found) {
          results.push(cus);
        }
      }

      setSearchResults(results);

      buildSelectOptions(results);
    }
  };

  const canBeImported = () => {
    const customer = searchResults.find((i) => i.contactID === value);

    if (!customer || !customer?.addresses) {
      return false;
    }

    let hasCompleteAddresses = false;
    const phone = customer.phones.find((i) => i.phoneType === 'DEFAULT');

    for (const address of customer.addresses) {
      if (address.addressLine1 && address.city && address.postalCode) {
        hasCompleteAddresses = true;
      }
    }

    if (!hasCompleteAddresses || !phone?.phoneNumber) {
      return false;
    }

    return true;
  };

  const autofillCustomer = () => {
    const customer = searchResults.find((i) => i.contactID === value);

    if (!customer || !customer?.addresses) {
      return;
    }

    let hasCompleteAddresses = false;
    const phone = customer.phones.find((i) => i.phoneType === 'DEFAULT');
    let completedAddress;
    for (const address of customer.addresses) {
      if (address.addressLine1 && address.city && address.postalCode) {
        hasCompleteAddresses = true;
        completedAddress = address;
      }
    }

    if (!hasCompleteAddresses || !phone?.phoneNumber) {
      return;
    }

    // Name
    const nameSplit = customer.name.split(' ');
    let firstName;
    let lastName;

    if (nameSplit.length > 1) {
      firstName = nameSplit[0];
      lastName = nameSplit[1];
    } else {
      lastName = nameSplit[0];
    }

    // Contact
    return {
      _id: new ObjectId().toHexString(),
      created: new Date(),
      createdBy: loggedInUser._id,
      title: CustomerTitle.NA,
      firstName: firstName,
      lastName: lastName,
      phone: phone?.phoneNumber,
      address: {
        address1: completedAddress.addressLine1,
        address2: completedAddress.addressLine2,
        address3: completedAddress.city,
        postalCode: completedAddress.postalCode,
      },
      email: customer.emailAddress,
    };
  };

  return (
    <div>
      <Dropdown
        autoComplete={'off'}
        icon={'search'}
        clearable
        placeholder={`Type to search Xero customers`}
        noResultsMessage={'Type to search Xero customers.'}
        fluid
        search
        onSearchChange={(options, searchObj) =>
          handleSearch(options, searchObj)
        }
        selectOnBlur={false}
        selection
        value={value}
        onChange={(e, data) => {
          onChange(data.value);
        }}
        options={searchOptions}
      />

      {value && (
        <Button
          disabled={!canBeImported()}
          style={{marginTop: 20}}
          primary
          onClick={() => {
            const contact = autofillCustomer();

            handleDataAction(DataActionType.IMPORT_XERO_CUSTOMER, '', {
              contact,
            });
          }}
        >
          Autofill Customer From Xero
        </Button>
      )}
    </div>
  );
};

export default XeroCustomerSearch;
