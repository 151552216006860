import {faBuilding} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ObjectId from 'bson-objectid';
import {cloneDeep} from 'lodash';
import React, {useCallback, useEffect, useState} from 'react';
import {
  CRUDType,
  DataFormType,
  DataTableType,
} from '../components/Data/DataConst';
import DataFilter from '../components/Data/DataFilter';
import DataTable, {SortOrder} from '../components/Data/DataTable';
import CompanyModal from '../components/Modal/CompanyModal';
import Welcome from '../components/Welcome';
import config from '../config/config';
import {DataType} from '../const/crud';
import {Company, CompanyEnums, companyInitialState} from '../models/Company';
import {UserRole} from '../models/User';
import ActionHelper from '../utils/actionHelper';
import {DataHelper, ValueTemplate} from '../utils/dataHelper';
import {SocketState} from '../utils/socketHelper';
import {PageProps} from './Page';

export interface CompanyPageProps extends PageProps {}

const Companies: React.FunctionComponent<CompanyPageProps> = (
  props: CompanyPageProps,
) => {
  // Filter
  const [hideFilter, setHideFilter] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [activeDataType, setActiveDataType] = useState(DataType.COMPANY);

  const [filters, setFilters] = useState({});
  const [searchedValue, setSearchedValue] = useState();
  const [searchOptions, setSearchOptions] = useState({});
  const [page, setPage] = useState(1);
  const [sortOrder, setSortOrder] = useState(SortOrder.NEWEST_FIRST);

  const [companies, setCompanies] = useState<Company[]>();
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [loadedCompanies, setLoadedCompanies] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(companyInitialState);
  const [companyTotal, setCompanyTotal] = useState(0);
  const [loadingTotal, setLoadingTotal] = useState(false);
  const [loadedTotal, setLoadedTotal] = useState(false);

  const [loadingIndustries, setLoadingIndustries] = useState(false);
  const [loadedIndustries, setLoadedIndustries] = useState(false);
  const [industryOptions, setIndustryOptions] = useState<any>();

  const [users, setUsers] = useState();
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [loadedUsers, setLoadedUsers] = useState(false);
  const [userOptions, setUserOptions] = useState<any>();

  const loadTotals = () => {
    setLoadedTotal(false);
    setLoadingTotal(false);
  };

  useEffect(() => {
    if (loadedCompanies && loadedUsers && companies && users) {
      props.helpers.socket.addSetters({
        [DataType.COMPANY]: {
          collection: setCompanies,
          selected: setSelectedCompany,
          total: loadTotals,
        },
        [DataType.USER]: {
          collection: setUsers,
          options: setUserOptions,
        },
      });

      const socketState: SocketState = {};

      socketState[DataType.COMPANY] = {
        collection: companies,
        selected: selectedCompany,
      };
      socketState[DataType.USER] = {
        collection: users,
      };

      props.helpers.socket.addState(socketState);
    }
  }, [
    props.helpers.socket,
    loadedCompanies,
    loadedUsers,
    companies,
    users,
    selectedCompany,
  ]);

  // Get Companies
  useEffect(() => {
    // Get companies
    if (!loadedCompanies && !loadingCompanies && props.loggedInUser.company) {
      setLoadingCompanies(true);
      const getCompanies = async () => {
        if (props.loggedInUser.role === UserRole.SUPER_ADMIN) {
          setCompanies(
            (
              await props.helpers.crud.run(
                CRUDType.READ,
                DataType.COMPANY,
                undefined,
                {
                  sort: {
                    created: sortOrder === SortOrder.NEWEST_FIRST ? -1 : 1,
                  },
                  page,
                  pageSize: 10,
                  ...filters,
                },
              )
            ).data,
          );
        } else {
          setCompanies([props.loggedInCompany]);
        }

        setLoadedCompanies(true);
        setLoadingCompanies(false);

        setLoadedUsers(false);
        setLoadingUsers(false);
      };
      getCompanies();
    }
  }, [
    page,
    props.loggedInUser,
    props.loggedInCompany,
    props.helpers.crud,
    filters,
    companies,
    loadingCompanies,
    loadedCompanies,
    sortOrder,
  ]);

  // Get Total
  useEffect(() => {
    if (!loadingTotal && !loadedTotal && companies) {
      setLoadingTotal(true);
      const getTotal = async () => {
        if (props.loggedInUser.role === UserRole.SUPER_ADMIN) {
          const totalUrl = `${config.api}/${DataType.COMPANY}/total`;
          setCompanyTotal(
            (
              await props.helpers.crud.run(
                CRUDType.READ,
                DataType.COMPANY,
                totalUrl,
                {
                  page,
                  pageSize: 10,
                  ...filters,
                },
              )
            ).data.total,
          );
        } else {
          setCompanyTotal(1);
        }

        setLoadedTotal(true);
        setLoadingTotal(true);
      };
      getTotal();
    }
  }, [
    props.helpers.crud,
    props.loggedInUser.role,
    filters,
    page,
    loadingTotal,
    loadedTotal,
    companies,
  ]);

  // Get Users
  useEffect(() => {
    if (companies && !loadedUsers && !loadingUsers) {
      setLoadingUsers(true);
      const getUsers = async () => {
        const userIds: any = [props.loggedInUser._id];

        companies.forEach((company: Company) => {
          userIds.push(new ObjectId(company.contacts.primary));
          if (company.contacts.secondary) {
            userIds.push(new ObjectId(company.contacts.secondary));
          }
        });

        // Get contact users
        const result = (
          await props.helpers.crud.run(
            CRUDType.READ,
            DataType.USER,
            undefined,
            {
              page: 1,
              pageSize: 30,
              _id: {$in: userIds},
            },
          )
        ).data;

        // Get users for staff availability
        const staffResult = (
          await props.helpers.crud.run(
            CRUDType.READ,
            DataType.USER,
            undefined,
            {
              page: 1,
              pageSize: 30,
            },
          )
        ).data;

        const mergeResults = () => {
          const merged = result;

          staffResult.forEach((opt) => {
            const found = merged.find((i) => i._id.toString() === opt._id.toString());
            if (!found) {
              merged.push(opt);
            }
          });
          return merged;
        };

        const merged = mergeResults();

        setUsers(merged);

        // Create Options
        setUserOptions(
          DataHelper.buildUserSelectOptions(merged, [
            {
              title: 'Position',
              data: 'position',
            },
          ]),
        );

        setLoadedUsers(true);
        setLoadingUsers(false);
      };
      getUsers();
    }
  }, [
    props.loggedInUser._id,
    props.helpers.crud,
    companies,
    loadedUsers,
    loadingUsers,
  ]);

  // Get Industries
  useEffect(() => {
    if (
      props.loggedInUser.role === UserRole.SUPER_ADMIN &&
      !loadedIndustries &&
      !loadingIndustries
    ) {
      setLoadingIndustries(true);
      const getIndustries = async () => {
        const result = (
          await props.helpers.crud.run(
            CRUDType.READ,
            DataType.INDUSTRY,
            undefined,
            {page: 1, pageSize: 20},
          )
        ).data;

        // Create Options
        setIndustryOptions(DataHelper.buildIndustrySelectOptions(result));

        setLoadedIndustries(true);
        setLoadingIndustries(false);
      };
      getIndustries();
    }
  }, [
    props.helpers.crud,
    props.loggedInUser.role,
    loadedIndustries,
    loadingIndustries,
  ]);

  const actionHelper = new ActionHelper(
    props.authToken,
    props.helpers.modal,
    props.setDataForm,
    props.loggedInUser,
    props.loggedInCompany,
  );

  const handleAction = async (action: CRUDType, id: string) => {
    await actionHelper.handleAction(
      DataType.COMPANY,
      (state) => setSelectedCompany(state),
      action,
      id,
      null,
    );
  };

  const handleSearch = useCallback(
    async (options: any[], searchObj: any, dataType: DataType) => {
      if (
        searchObj &&
        searchObj.searchQuery !== searchedValue &&
        searchObj.searchQuery.length > 2
      ) {
        const result = await props.helpers.crud
          .process(searchObj.searchQuery)
          .run(CRUDType.SEARCH, dataType, undefined);

        setSearchedValue(searchObj.searchQuery);

        let opts;
        switch (dataType) {
          case DataType.COMPANY:
            opts = DataHelper.buildCompanySelectOptions(result.data);
            break;
          case DataType.USER:
            opts = DataHelper.buildUserSelectOptions(result.data, [
              {
                title: 'Position',
                data: 'position',
              },
            ]);
            break;
          case DataType.CUSTOMER:
            opts = DataHelper.buildUserSelectOptions(
              result.data,
              [
                {title: 'Company', data: 'companyName'},
                {title: 'Account Number', data: 'account', toUpper: true},
                {title: 'Postcode(s)', data: 'postalCode', toUpper: true},
              ],
              true,
            );
            break;
        }

        const searchOpts = cloneDeep(searchOptions);
        searchOpts[dataType] = opts;

        setSearchOptions(searchOpts);
      }
    },
    [props.helpers.crud, searchedValue, searchOptions],
  );

  let filterSections: any = [
    {
      title: '',
      fields: [
        {
          title: 'Name',
          dataField: 'name',
          type: DataFormType.TEXT,
        },
      ],
    },
  ];

  if (props.loggedInUser.role === UserRole.SUPER_ADMIN) {
    filterSections[0].fields = filterSections[0].fields.concat([
      {
        title: 'Industry',
        dataField: 'industry',
        dataSet: industryOptions,
        type: DataFormType.SELECT,
      },
      {
        title: 'Subscription Status',
        dataField: 'status',
        dataSet: CompanyEnums.CompanyStatusOptions,
        type: DataFormType.SELECT,
      },
      {
        title: 'Primary Contact',
        dataField: 'contacts.primary',
        dataSet: searchOptions[DataType.USER] || userOptions,
        dataType: DataType.USER,
        type: DataFormType.SEARCH_SELECT_API,
      },
    ]);
  }

  return (
    <>
      {!hideFilter && (
        <aside>
          <div className={'box'}>
            <DataFilter
              sections={filterSections}
              data={filters}
              setData={setFilters}
              dataType={DataType.COMPANY}
              primaryAction={() => {
                setPage(1);
                setLoadedCompanies(false);
                setLoadingCompanies(false);
                loadTotals();
              }}
              searchAction={handleSearch}
            />
          </div>
        </aside>
      )}

      <main style={{gridColumn: hideFilter ? '1/-1' : ''}}>
        {props.isHome && <Welcome loggedInUser={props.loggedInUser} />}

        <div className={`box ${props.isHome && 'HomeTableHeight'}`}>
          <DataTable
            hideFilter={hideFilter}
            setHideFilter={setHideFilter}
            title="Companies"
            titleIcon={<FontAwesomeIcon icon={faBuilding} />}
            description={
              props.loggedInUser.role === UserRole.SUPER_ADMIN
                ? 'You can add, update and delete companies using the buttons below.'
                : 'You can modify your company details and checklist templates by selecting your company below.'
            }
            className={'StyledDataTable'}
            data={companies || []}
            dataType={DataType.COMPANY}
            primaryAction={
              props.loggedInUser.role === UserRole.SUPER_ADMIN
                ? CRUDType.CREATE
                : undefined
            }
            handleAction={(action: CRUDType, id: string) =>
              handleAction(action, id)
            }
            clickAction={CRUDType.UPDATE}
            apiSortOrder={sortOrder}
            paginationAction={(page, reset?, activeSortOrder?) => {
              if (activeSortOrder) {
                setSortOrder(activeSortOrder);
              }
              setPage(page);
              setLoadedCompanies(false);
              setLoadingCompanies(false);
            }}
            collectionTotal={companyTotal}
            cols={[
              {
                title: 'Status',
                dataField: 'status',
                type: DataTableType.STRING,
                mobileDisplay: true,
                className: (value) => DataHelper.statusColourClass(value),
              },
              {
                title: 'Name',
                dataField: 'name',
                type: DataTableType.STRING,
                mobileDisplay: true,
              },
              {
                title: 'Primary Contact',
                dataField: 'contacts.primary',
                dataProcess: {
                  action: DataHelper.getValueFromData,
                  data: users,
                  field: ValueTemplate.NAME,
                },
                type: DataTableType.STRING,
                mobileDisplay: false,
              },
              {
                title: 'Secondary Contact',
                dataField: 'contacts.secondary',
                dataProcess: {
                  action: DataHelper.getValueFromData,
                  data: users,
                  field: ValueTemplate.NAME,
                },
                type: DataTableType.STRING,
                mobileDisplay: false,
              },
              {
                title: 'Subscription ID',
                dataField: 'subscription.id',
                type: DataTableType.STRING,
                mobileDisplay: true,
              },
              {
                title: 'Max Users',
                dataField: 'subscription.userCount',
                type: DataTableType.PLAIN,
                mobileDisplay: true,
              },
            ]}
          />
        </div>

        {props.showDataForm.status && (
          <CompanyModal
            loggedInUser={props.loggedInUser}
            loggedInCompany={props.loggedInCompany}
            crudAction={props.crudAction}
            showDataForm={props.showDataForm}
            actionHelper={actionHelper}
            setDataForm={props.setDataForm}
            resetDataType={() => {
              setActiveDataType(DataType.COMPANY);
            }}
            selected={selectedCompany}
            setSelected={setSelectedCompany}
            users={users}
            userOptions={userOptions}
            industries={industryOptions}
            searchAction={handleSearch}
            searchOptions={searchOptions}
            setSearchOptions={setSearchOptions}
            setModalData={props.helpers.modal.setState(selectedCompany)}
          />
        )}
      </main>
    </>
  );
};

export default Companies;
