import {cloneDeep} from 'lodash';
import moment from 'moment';
import React from 'react';
import {Button, Icon} from 'semantic-ui-react';
import {DataFilterProps, DataFormType} from './DataConst';
import DataForm from './DataForm';

const DataFilter: React.FunctionComponent<DataFilterProps> = (
  props: DataFilterProps,
) => {
  const handleAction = (clearFilter: boolean = false) => {
    if (clearFilter) {
      props.setData({});
    }

    props.primaryAction();
  };

  const onChange = (value, filter) => {
    const filters = cloneDeep(props.data) || {};

    if (filter.type === DataFormType.DATE) {
      value = moment(value, 'DD-MM-YYYY').toISOString();
    }

    if (filter.type === DataFormType.DATE_RANGE) {
      const split = value.split(' - ');

      if (!split[0] && !split[1]) {
        value = '';
      } else {
        value =
          ((split[0] &&
            split[0].length &&
            moment(split[0], 'DD-MM-YYYY')
              .startOf('day')
              .toISOString()) ||
            '') +
          ' - ' +
          ((split[1] &&
            split[1].length &&
            moment(split[1], 'DD-MM-YYYY')
              .endOf('day')
              .toISOString()) ||
            '');
      }
    }

    if (!value.length) {
      delete filters[filter.dataField];
    } else {
      filters[filter.dataField] = value;
    }

    props.setData(filters);

    if (!Object.keys(filters).length) {
      handleAction(true);
    }
  };

  return (
    <div className="DataFilterContainer">
      <div className="DataFilterHeaderContainer">
        <h1>Filters</h1>
        <Button
          disabled={!props.data || !Object.keys(props.data).length}
          onClick={() => {
            handleAction(true);
          }}
        >
          Clear Filters
        </Button>
      </div>
      <DataForm
        sections={props.sections}
        data={props.data}
        dataType={props.dataType}
        onChange={onChange}
        onSearch={props.searchAction}
      />
      {props.primaryAction && (
        <div className="ButtonContainer">
          <Button
            disabled={!props.data || !Object.keys(props.data).length}
            onClick={() => handleAction()}
            icon
            labelPosition="left"
          >
            <Icon name={'filter'} />
            Filter
          </Button>
        </div>
      )}
    </div>
  );
};

export default DataFilter;
