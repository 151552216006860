import * as React from 'react';
import UserAvatar from 'react-user-avatar';
import config from '../auth_config.json';
import {useAuth0} from '../react-auth0-spa';
import MediaHelper from '../utils/mediaHelper';

export interface LoggedInUserProps {
  loggedInUser: any;
  loggedInCompany: any;
}

const LoggedInUser: React.FunctionComponent<LoggedInUserProps> = (
  props: LoggedInUserProps,
) => {
  const {isAuthenticated, loginWithRedirect, logout} = useAuth0();

  const fullName: string = `${props.loggedInUser.firstName} ${props.loggedInUser.lastName}`;
  const logo: string = props.loggedInCompany.theme.find(
    (item) => item.type === 'logo',
  )?.mediaId;

  return (
    <div className="LoggedInUser">
      {logo && (
        <div
          className={'Logo'}
          style={{
            backgroundImage: `url(
                  ${MediaHelper.getMediaUrl(logo)})`,
          }}
        />
      )}

      {!logo && <UserAvatar size="48" name={fullName} />}

      <div className="LoggedInName">
        <span>{fullName}</span>
        {!isAuthenticated && (
          <button className="LoginAction" onClick={() => loginWithRedirect({})}>
            Log in
          </button>
        )}
        {isAuthenticated && (
          <button
            className="LoginAction"
            onClick={() => logout({returnTo: config.returnTo})}
          >
            Log out
          </button>
        )}
      </div>
    </div>
  );
};

export default LoggedInUser;
