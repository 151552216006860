import {faMinus, faPlus} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {get, startCase} from 'lodash';
import React, {useEffect, useState} from 'react';
import {DataType} from '../const/crud';
import {User} from '../models/User';
import {CRUDType} from './Data/DataConst';

interface FileImportProps {
  dataType: DataType;
  loggedInUser: User;
  crudAction: any;
  setOpenState: any;
  openState: boolean;
}

const FileImport: React.FunctionComponent<FileImportProps> = (
  props: FileImportProps,
) => {
  const [companies, setCompanies] = useState();
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [file, setFile] = useState<any>();
  const [fileKey, setFileKey] = useState(0);
  const [importCompany, setImportCompany] = useState('');
  const [downloadData, setDownloadData] = useState();

  const handleChange = (event, file = false) => {
    if (file) {
      setFile(event.target.files[0]);
    } else {
      setImportCompany(event.target.value);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const result = await props.crudAction(
      CRUDType.IMPORT_FILE,
      props.dataType,
      {
        body: {
          created: new Date(),
          createdBy: props.loggedInUser._id,
          company: importCompany,
        },
        file,
      },
      true,
    );
    if (result) {
      setFile(null);
      setFileKey(fileKey + 1);
      setImportCompany('');

      setDownloadData(result.data);
    }
  };

  const downloadImportedData = () => {
    // Convert JSON to CSV
    let fields;

    if (props.dataType === DataType.CUSTOMER) {
      fields = [
        '_id',
        'companyName',
        'account',
        'contact.0._id',
        'contact.0.firstName',
        'contact.0.lastName',
      ];
    } else if (props.dataType === DataType.JOB) {
      fields = ['_id', 'jobId', 'type', 'description', 'customer', 'jobType', 'vat', 'jobPrefix'];
    }
    const replacer = (key, value) => (value === null ? '' : value);
    const header = fields;

    let csv = (downloadData || []).map((row) =>
      header
        .map((fieldName) => JSON.stringify(get(row, fieldName), replacer))
        .join(','),
    );
    csv.unshift(header.join(','));
    const csvString = csv.join('\r\n');
    const csvContent = 'data:text/csv;charset=utf-8,' + csvString;

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'imported-data.csv');
    document.body.appendChild(link);

    link.click();
  };

  useEffect(() => {
    if (!companies && !loadingCompanies) {
      setLoadingCompanies(true);
      const getCompanies = async () => {
        const result = await props.crudAction(
          CRUDType.READ,
          DataType.COMPANY,
          undefined,
          false,
        );
        setCompanies(result.data);
      };
      getCompanies();
    }
  }, [companies, loadingCompanies, props]);

  const companyOptions =
    (companies || []).map((comp: any, i) => {
      return (
        <option value={comp._id} key={`opt-${i}-${comp._id}`}>
          {comp.name}
        </option>
      );
    });

  return (
    <div className={'box import-box'}>
      <div className={'import-title'}>
        <h1>Import {startCase(props.dataType)}s</h1>
        <button
          className={'ui button'}
          onClick={() => props.setOpenState(!props.openState)}
        >
          <FontAwesomeIcon icon={props.openState ? faMinus : faPlus} />
        </button>
      </div>
      {props.openState && (
        <div>
          <p>
            To upload {props.dataType}s via a CSV file,{' '}
            <a
              href={
                props.dataType === DataType.CUSTOMER
                  ? 'https://onsite-7-assets.s3.eu-west-2.amazonaws.com/Onsite7%2B-%2BCustomer%2BUpload%2BTemplate.csv'
                  : 'https://onsite-7-assets.s3.eu-west-2.amazonaws.com/Onsite7%2B-%2BJob%2BUpload%2BTemplate.csv'
              }
            >
              download the template here
            </a>
            , and then upload it by clicking the button below.
          </p>
          {!downloadData && (
            <form
              className={'ui form'}
              onSubmit={(event) => handleSubmit(event)}
            >
              <div>
                <h3>Select company</h3>
                <select
                  id={'company'}
                  onChange={handleChange}
                  value={importCompany}
                >
                  <option value="" disabled>
                    Select company
                  </option>
                  {companyOptions}
                </select>
              </div>

              <div>
                <h3>Select file</h3>
                <input
                  key={fileKey}
                  className={'ui'}
                  id={'file-import'}
                  type={'file'}
                  accept={'file/csv'}
                  onChange={(event) => handleChange(event, true)}
                />
              </div>

              <div>
                <h3>Upload</h3>
                <input
                  disabled={!importCompany || !file}
                  type={'submit'}
                  value={'Upload File'}
                  className={'ui button primary'}
                />
              </div>
            </form>
          )}

          {downloadData && (
            <div style={{marginTop: 20}}>
              <h3>Download Imported Data</h3>

              <input
                type={'submit'}
                value={'Download Data'}
                className={'ui button primary'}
                onClick={downloadImportedData}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FileImport;
