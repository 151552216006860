import {UserRole} from './User';

export interface MediaLink {
  created: Date | string;
  createdBy: string;
  mediaId: string;
  mediaType: MediaType;
  title?: string;
  url?: string;
  location?: string;
  access?: UserRole;
  type?: string;
  displayInReports?: boolean;
}

export enum MediaType {
  IMAGE = 'image',
  VIDEO = 'video',
  FILE = 'file',
}

export interface Media {
  _id: string;
  created: Date;
  createdBy: string;
  s3: {
    _id?: string;
    bucket: string;
    key: string;
  };
}
