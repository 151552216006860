import React, {useState} from 'react';
import {Button, Dropdown, Form} from 'semantic-ui-react';
import {DataType} from '../const/crud';
import {Company} from '../models/Company';
import {Job} from '../models/Job';
import {User} from '../models/User';
import {DataHelper, ValueTemplate} from '../utils/dataHelper';
import {DataActionType, DataTableType, SortDirection} from './Data/DataConst';
import DataTable from './Data/DataTable';

interface CostProps {
  data: Job;
  handleDataAction: any;
  users: User[];
  loggedInCompany: Company;
  loggedInUser: User;
  state: any;
}

const Cost: React.FunctionComponent<CostProps> = (props: CostProps) => {
  const [importGroup, setImportGroup] = useState<any>();

  if (!props.data) {
    return null;
  }

  const importOptions = props.loggedInCompany?.costGroups?.map((i) => {
    return {
      key: i._id,
      text: i.name,
      value: i._id,
    };
  });

  return (
    <div className={'Checklist'}>
      <div className={'AddTask'}>
        <Form.Field>
          <h3>Import Cost Group</h3>
          <p>
            Select a cost group to import from your pre-defined templates. These
            templates can be edited in your 'Companies' page.
          </p>
          <Dropdown
            placeholder={`Select Cost Group`}
            fluid
            selection
            options={importOptions}
            value={importGroup}
            onChange={(e, data) => setImportGroup(data.value)}
          />
        </Form.Field>
        <Button
          disabled={!importGroup}
          primary
          onClick={() => {
            const costs = props.loggedInCompany.costGroups.find(
              (i) => i._id === importGroup,
            )?.costs;

            props.handleDataAction(
              DataActionType.IMPORT_COST_GROUP,
              importGroup,
              {
                selected: props.state,
                costs,
              },
            );
          }}
        >
          Import Costs
        </Button>
      </div>

      <DataTable
        title="Costs"
        description={'Add a list of costs for this job.'}
        data={props.data.costs}
        dataType={DataType.COST}
        primaryAction={DataActionType.CREATE_COST}
        handleAction={props.handleDataAction}
        pageSize={5}
        sortField={['created']}
        sortDirection={[SortDirection.DESC]}
        actions={[
          {
            title: 'Edit',
            icon: 'pencil',
            className: 'spaceBelow',
            action: DataActionType.EDIT_COST,
          },
          {
            title: 'Delete',
            icon: 'delete',
            className: 'redButton',
            action: DataActionType.DELETE_COST,
          },
        ]}
        cols={[
          {
            title: 'Cost',
            dataField: 'name',
            type: DataTableType.PLAIN,
            mobileDisplay: true,
            width: 6,
          },
          {
            title: 'Net Amount',
            dataField: 'amount',
            type: DataTableType.CURRENCY,
            mobileDisplay: true,
            width: 3,
          },
          {
            title: 'VAT',
            dataField: 'amount',
            type: DataTableType.PLAIN,
            dataProcess: {
              action: DataHelper.calcVAT,
              data: props.data,
            },
            mobileDisplay: true,
            width: 3,
          },
          {
            title: 'Total Amount',
            dataField: 'amount',
            type: DataTableType.PLAIN,
            dataProcess: {
              action: DataHelper.showVAT,
              data: props.data,
            },
            mobileDisplay: true,
            width: 3,
          },
          {
            title: 'Stage',
            dataField: 'stage',
            dataProcess: {
              action: DataHelper.getStageTitle,
              data: props.loggedInCompany.stageTitles,
            },
            type: DataTableType.STRING,
            mobileDisplay: true,
            width: 3,
          },
          {
            title: 'Created Date',
            dataField: 'created',
            type: DataTableType.DATE_TIME,
            mobileDisplay: true,
            width: 3,
          },
          {
            title: 'Created By',
            dataField: 'createdBy',
            dataProcess: {
              action: DataHelper.getValueFromData,
              data: props.users,
              field: ValueTemplate.NAME,
            },
            type: DataTableType.STRING,
            mobileDisplay: false,
            width: 3,
          },
        ]}
      />
    </div>
  );
};

export default Cost;
