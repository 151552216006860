import {
  faAddressBook,
  faBuilding,
  faLock,
  faTasks,
  faUserLock,
  faUsersCog,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import {DataType} from '../../const/crud';
import CustomModalProps from '../../const/modal';
import {Customer} from '../../models/Customer';
import {Job} from '../../models/Job';
import {UserEnums, userInitialState, UserRole} from '../../models/User';
import ActionHelper from '../../utils/actionHelper';
import {DataHelper, ValueTemplate} from '../../utils/dataHelper';
import {
  CRUDType,
  DataFormType,
  DataTableType,
  SortDirection,
  ValidationType,
} from '../Data/DataConst';
import DataModal from '../Data/DataModal';
import DataTable from '../Data/DataTable';

interface UserModalProps extends CustomModalProps {
  actionHelper: ActionHelper;
  selectedUserJobs: Job[];
  setSelectedUserJobs: any;
  selectedUserJobsTotal: number;
  setSelectedUserJobsPage: any;
  getSelectedUserJobs: any;
  customers: Customer[];
  viewJob: any;
}

const UserModal: React.FunctionComponent<UserModalProps> = (
  props: UserModalProps,
) => {
  const resetDataForm = () => {
    // Reset search options
    props.setSearchOptions({});

    // Set company
    userInitialState.company = props.loggedInUser.company;

    // Reset jobs
    props.setSelectedUserJobs([]);

    props.setSelected(userInitialState);
    props.setDataForm(false);
  };

  return (
    <DataModal
      showDataForm={props.showDataForm}
      onClose={() => {
        resetDataForm();
        props.resetDataType();
      }}
      searchAction={props.searchAction}
      crudAction={async (
        action: CRUDType,
        dataType: DataType,
        data: any,
        completeAction: boolean = true,
        clearFilter: boolean = false,
      ) => {
        if (action === CRUDType.DELETE) {
          await props.actionHelper.handleAction(
            dataType,
            null,
            action,
            data._id,
          );
        } else {
          const result = await props.crudAction(
            data._id?.length ? CRUDType.UPDATE : action,
            dataType,
            data,
            completeAction,
            clearFilter,
          );

          if (result?.data) {
            props.setSelected(result.data);
          }
        }
        props.resetDataType();
        resetDataForm();
      }}
      title={`${props.selected.firstName} ${props.selected.lastName}`}
      data={{
        selected: props.selected,
        selectedUserJobs: props.selectedUserJobs,
        selectedUserJobsCustomers: props.customers,
        selectedUserJobsUsers: props.users,
      }}
      dataType={DataType.USER}
      sections={[
        {
          title: 'Password Reset',
          description: `Click the button below to send the user a 'Password Reset' email.`,
          titleIcon: <FontAwesomeIcon icon={faLock} />,
          hideSection:
            props.loggedInUser.role !== UserRole.SUPER_ADMIN ||
            !props.selected._id,
          fields: [
            {
              title: '',
              dataField: 'company',
              type: DataFormType.PASSWORD_RESET,
              optional: true,
            },
          ],
        },
        {
          title: 'Company Details',
          titleIcon: <FontAwesomeIcon icon={faBuilding} />,
          hideSection: props.loggedInUser.role !== UserRole.SUPER_ADMIN,
          fields: [
            {
              title: 'Company',
              dataField: 'company',
              dataSet:
                props.searchOptions[DataType.COMPANY] &&
                props.searchOptions[DataType.COMPANY].length
                  ? props.searchOptions[DataType.COMPANY]
                  : props.companyOptions,
              dataType: DataType.COMPANY,
              type: DataFormType.SEARCH_SELECT_API,
              validation: [ValidationType.REQUIRED],
            },
          ],
        },
        {
          title: 'User Details',
          titleIcon: <FontAwesomeIcon icon={faUsersCog} />,
          fields: [
            {
              className: 'halfWidth',
              title: 'First Name',
              dataField: 'firstName',
              type: DataFormType.TEXT,
              validation: [ValidationType.REQUIRED],
            },
            {
              className: 'halfWidth',
              title: 'Last Name',
              dataField: 'lastName',
              type: DataFormType.TEXT,
              validation: [ValidationType.REQUIRED],
            },
            {
              title: 'Position In Business',
              dataField: 'position',
              type: DataFormType.TEXT,
              optional: true,
            },
            {
              title: 'Select Your User Role',
              description: 'Admin users can also be used as Onsite users.',
              dataField: 'role',
              dataSet: UserEnums.UserRoleOptions.filter((role) => {
                const currentRole = props.loggedInUser.role;

                if (currentRole !== UserRole.SUPER_ADMIN) {
                  if (role.value === UserRole.SUPER_ADMIN) {
                    return false;
                  }

                  if (currentRole !== UserRole.MANAGER) {
                    if (role.value === UserRole.MANAGER) {
                      return false;
                    }
                  }
                }

                return true;
              }),
              type: DataFormType.SELECT,
              validation: [ValidationType.REQUIRED],
            },
          ],
        },
        {
          title: 'Contact Details',
          titleIcon: <FontAwesomeIcon icon={faAddressBook} />,
          fields: [
            {
              className: 'halfWidth',
              title: 'Phone',
              dataField: 'contact.phone',
              type: DataFormType.TEXT,
              validation: [ValidationType.REQUIRED],
            },
            {
              className: 'halfWidth',
              title: 'Email',
              dataField: 'contact.email',
              type: DataFormType.TEXT_LOWER,
              validation: [ValidationType.REQUIRED],
            },
            {
              className: 'halfWidth',
              title: 'Address 1',
              dataField: 'contact.address.address1',
              type: DataFormType.TEXT,
              validation: [ValidationType.REQUIRED],
            },
            {
              className: 'halfWidth',
              title: 'Address 2',
              dataField: 'contact.address.address2',
              type: DataFormType.TEXT,
              optional: true,
            },
            {
              className: 'halfWidth',
              title: 'Town/City',
              dataField: 'contact.address.address3',
              type: DataFormType.TEXT,
              validation: [ValidationType.REQUIRED],
            },
            {
              className: 'halfWidth',
              title: 'Postcode',
              dataField: 'contact.address.postalCode',
              type: DataFormType.TEXT_UPPER,
              validation: [ValidationType.REQUIRED],
            },
          ],
        },
        {
          fields: [
            {
              dataField: '',
              type: DataFormType.TABLE,
              content: (
                <DataTable
                  title="Jobs"
                  titleIcon={<FontAwesomeIcon icon={faTasks} />}
                  className={'StyledDataTable'}
                  data={props.selectedUserJobs}
                  dataType={DataType.JOB}
                  clickAction={CRUDType.UPDATE}
                  handleAction={props.viewJob}
                  paginationAction={(page) => {
                    props.setSelectedUserJobsPage(page);
                    props.getSelectedUserJobs(props.selected._id, page);
                  }}
                  pageSize={5}
                  collectionTotal={props.selectedUserJobsTotal}
                  sortField={['created']}
                  sortDirection={[SortDirection.DESC]}
                  cols={[
                    {
                      title: 'Job ID',
                      dataField: 'jobId',
                      type: DataTableType.STRING,
                      mobileDisplay: true,
                    },
                    {
                      title: 'Customer',
                      dataField: 'customer',
                      dataProcess: {
                        action: DataHelper.getValueFromData,
                        data: props.customers,
                        field: ValueTemplate.CONTACT,
                      },
                      type: DataTableType.STRING,
                      mobileDisplay: false,
                    },
                    {
                      title: 'Company',
                      dataField: 'customer',
                      dataProcess: {
                        action: DataHelper.getValueFromData,
                        data: props.customers,
                        field: 'companyName',
                      },
                      type: DataTableType.STRING,
                      mobileDisplay: false,
                    },
                    {
                      title: 'Sales Status',
                      dataField: 'stages.pos.status',
                      type: DataTableType.STRING,
                      mobileDisplay: true,
                      className: (value) => DataHelper.statusColourClass(value),
                    },
                    {
                      title: 'Survey Status',
                      dataField: 'stages.pre.status',
                      type: DataTableType.STRING,
                      mobileDisplay: true,
                      className: (value) => DataHelper.statusColourClass(value),
                    },
                    {
                      title: 'Installation Status',
                      dataField: 'stages.post.status',
                      type: DataTableType.STRING,
                      mobileDisplay: true,
                      className: (value) => DataHelper.statusColourClass(value),
                    },
                    {
                      title: 'Service Status',
                      dataField: 'stages.service.status',
                      type: DataTableType.STRING,
                      mobileDisplay: true,
                      className: (value) => DataHelper.statusColourClass(value),
                    },
                  ]}
                />
              ),
            },
          ],
        },
        {
          title: 'Permissions',
          description: 'Control what sensitive information this user can view.',
          titleIcon: <FontAwesomeIcon icon={faUserLock} />,
          hideSection: ![UserRole.SUPER_ADMIN, UserRole.MANAGER].includes(
            props.loggedInUser.role as UserRole,
          ),
          fields: [
            {
              title: 'Job Pricing',
              description: 'Can this user view job pricing?',
              dataField: 'permissions.financial.job.pricing',
              dataSet: [
                {value: true, text: 'Yes'},
                {value: false, text: 'No'},
              ],
              type: DataFormType.SELECT,
              optional: 'hidden',
            },
            {
              title: 'Job Payments',
              description: 'Can this user view job payments?',
              dataField: 'permissions.financial.job.payments',
              dataSet: [
                {value: true, text: 'Yes'},
                {value: false, text: 'No'},
              ],
              type: DataFormType.SELECT,
              optional: 'hidden',
            },
            {
              title: 'Job Costs',
              description: 'Can this user view job costs?',
              dataField: 'permissions.financial.job.costs',
              dataSet: [
                {value: true, text: 'Yes'},
                {value: false, text: 'No'},
              ],
              type: DataFormType.SELECT,
              optional: 'hidden',
            },
          ],
        },
      ]}
    />
  );
};

export default UserModal;
