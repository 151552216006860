import {faUsers} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ObjectId from 'bson-objectid';
import {cloneDeep} from 'lodash';
import React, {useCallback, useEffect, useState} from 'react';
import {
  CRUDType,
  DataFormType,
  DataTableType,
} from '../components/Data/DataConst';
import DataFilter from '../components/Data/DataFilter';
import DataTable, {SortOrder} from '../components/Data/DataTable';
import FileImport from '../components/FileImport';
import CustomerModal from '../components/Modal/CustomerModal';
import JobModal from '../components/Modal/JobModal';
import Welcome from '../components/Welcome';
import config from '../config/config';
import {DataType} from '../const/crud';
import {Customer, customerInitialState} from '../models/Customer';
import {jobInitialState} from '../models/Job';
import {UserRole} from '../models/User';
import ActionHelper from '../utils/actionHelper';
import {DataHelper, ValueTemplate} from '../utils/dataHelper';
import {SocketState} from '../utils/socketHelper';
import {PageProps} from './Page';

export interface CustomerPageProps extends PageProps {}

const Customers: React.FunctionComponent<CustomerPageProps> = (
  props: CustomerPageProps,
) => {
  // Filter
  const [hideFilter, setHideFilter] = useState<boolean>(false);

  const [selectedUserJobs, setSelectedUserJobs] = useState();
  const [activeDataType, setActiveDataType] = useState(DataType.CUSTOMER);

  const [filters, setFilters] = useState({});
  const [searchedValue, setSearchedValue] = useState<any>();
  const [searchOptions, setSearchOptions] = useState({});
  const [page, setPage] = useState(1);
  const [importOpen, setImportOpen] = useState(false);
  const [sortOrder, setSortOrder] = useState(SortOrder.NEWEST_FIRST);

  const [companies, setCompanies] = useState<any>();
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [loadedCompanies, setLoadedCompanies] = useState(false);
  const [companyOptions, setCompanyOptions] = useState<any>();

  const [customers, setCustomers] = useState<any>();
  const [loadingCustomers, setLoadingCustomers] = useState(false);
  const [loadedCustomers, setLoadedCustomers] = useState(false);
  const [customerTotal, setCustomerTotal] = useState(0);
  const [loadingTotal, setLoadingTotal] = useState(false);
  const [loadedTotal, setLoadedTotal] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(
    customerInitialState,
  );
  const [customerOptions, setCustomerOptions] = useState<any>();

  const [users, setUsers] = useState<any>();
  const [userOptions, setUserOptions] = useState<any>();

  const [selectedJob, setSelectedJob] = useState(jobInitialState);

  const loadTotals = () => {
    setLoadedTotal(false);
    setLoadingTotal(false);
  };

  useEffect(() => {
    if (loadedCustomers && customers) {
      const setters = {
        [DataType.CUSTOMER]: {
          collection: setCustomers,
          selected: setSelectedCustomer,
          total: loadTotals,
        },
        [DataType.JOB]: {
          collection: setSelectedUserJobs,
          selected: setSelectedJob,
        },
      };

      if ([props.loggedInUser.role === UserRole.SUPER_ADMIN]) {
        setters[DataType.COMPANY] = {
          collection: setCompanies,
        };
      }

      props.helpers.socket.addSetters(setters);

      const socketState: SocketState = {};
      socketState[DataType.CUSTOMER] = {
        selected: selectedCustomer,
        collection: customers,
      };
      socketState[DataType.JOB] = {
        selected: selectedJob,
        collection: selectedUserJobs,
      };

      if ([props.loggedInUser.role === UserRole.SUPER_ADMIN]) {
        socketState[DataType.COMPANY] = {
          collection: companies,
        };
      }

      props.helpers.socket.addState(socketState);
    }
  }, [
    props.helpers.socket,
    props.loggedInUser.role,
    loadedCustomers,
    loadedCompanies,
    customers,
    selectedCustomer,
    companies,
    selectedUserJobs,
    selectedJob,
  ]);

  // Get Customers
  useEffect(() => {
    if (!loadedCustomers && !loadingCustomers && props.loggedInUser.company) {
      setLoadingCustomers(true);
      const getCustomers = async () => {
        const customersResult = (
          await props.helpers.crud.run(
            CRUDType.READ,
            DataType.CUSTOMER,
            undefined,
            {
              sort: {created: sortOrder === SortOrder.NEWEST_FIRST ? -1 : 1},
              page,
              pageSize: 10,
              ...filters,
            },
          )
        ).data;

        setCustomers(customersResult);

        // Create Options
        setCustomerOptions(
          DataHelper.buildUserSelectOptions(
            customersResult,
            [
              {title: 'Company', data: 'companyName'},
              {title: 'Account Number', data: 'account', toUpper: true},
              {title: 'Postcode(s)', data: 'postalCode', toUpper: true},
            ],
            true,
          ),
        );

        setLoadedCustomers(true);
        setLoadingCustomers(false);
      };
      getCustomers();
    }
  }, [
    page,
    props.loggedInUser,
    props.helpers.crud,
    filters,
    customers,
    loadingCustomers,
    loadedCustomers,
    sortOrder,
  ]);

  // Get Total
  useEffect(() => {
    if (!loadingTotal && !loadedTotal && customers) {
      setLoadingTotal(true);
      const getTotal = async () => {
        const totalUrl = `${config.api}/${DataType.CUSTOMER}/total`;
        const count = (
          await props.helpers.crud.run(
            CRUDType.READ,
            DataType.CUSTOMER,
            totalUrl,
            {
              page,
              pageSize: 10,
              ...filters,
            },
          )
        ).data.total;
        setCustomerTotal(count);

        setLoadedTotal(true);
        setLoadingTotal(true);
      };
      getTotal();
    }
  }, [props.helpers.crud, filters, page, loadingTotal, loadedTotal, customers]);

  // Get Companies if super_admin
  useEffect(() => {
    if (
      props.loggedInUser &&
      props.loggedInUser.role === UserRole.SUPER_ADMIN &&
      customers &&
      !loadedCompanies &&
      !loadingCompanies
    ) {
      setLoadingCompanies(true);
      const getCompanies = async () => {
        const companyIds: any = [];

        customers.forEach((customer: Customer) => {
          companyIds.push(new ObjectId(customer.company));
        });

        const result = (
          await props.helpers.crud.run(
            CRUDType.READ,
            DataType.COMPANY,
            undefined,
            {page: 1, pageSize: 30, _id: {$in: companyIds}},
          )
        ).data;

        setCompanies(result);

        // Create Options
        setCompanyOptions(DataHelper.buildCompanySelectOptions(result));

        setLoadedCompanies(true);
        setLoadingCompanies(false);
      };
      getCompanies();
    }
  }, [
    props.helpers.crud,
    props.loggedInUser,
    customers,
    loadedCompanies,
    loadingCompanies,
  ]);

  // Set company
  customerInitialState.company = props.loggedInUser.company;

  const actionHelper = new ActionHelper(
    props.authToken,
    props.helpers.modal,
    props.setDataForm,
    props.loggedInUser,
    props.loggedInCompany,
  );

  const handleAction = async (action: CRUDType, documentId: string) => {
    if (action === CRUDType.UPDATE) {
      // Get selected user's jobs
      let adminUser;
      if (props.loggedInUser.role === UserRole.SUPER_ADMIN) {
        adminUser = `&adminUser=${props.loggedInUser._id}`;
      }

      const jobs = await actionHelper.handleAction(
        DataType.JOB,
        (jobs) => setSelectedUserJobs(jobs),
        CRUDType.READ,
        documentId,

        (jobs) => setSelectedUserJobs(jobs),
        `${config.api}/${DataType.JOB}/customer/${documentId}?company=${
          props.loggedInUser.company
        }${adminUser ? adminUser : ''}`,
      );

      // Get relevant users
      let userIds: string[] = [props.loggedInUser._id];
      customers.forEach((customer) => {
        if (customer.notes) {
          customer.notes.forEach((note) => userIds.push(note.createdBy));
        }
      });

      jobs.forEach((job) => {
        Object.keys(job.stages).forEach((stage) => {
          if (job.stages[stage].assignedTo) {
            userIds = userIds.concat(job.stages[stage].assignedTo);
          }
        });
      });

      const uniqueUserIds = userIds.reduce((acc, id) => {
        if (acc.find((userId) => userId === id)) {
          return acc;
        }

        return [...acc, id];
      }, []);

      const usersResult = (
        await props.helpers.crud.run(CRUDType.READ, DataType.USER, undefined, {
          page: 1,
          pageSize: 50,
          _id: {$in: uniqueUserIds},
        })
      ).data;

      setUsers(usersResult);

      // Build Options
      setUserOptions(DataHelper.buildUserSelectOptions(usersResult));

      // Get company if super admin and doesn't have it
      if (props.loggedInUser.role === UserRole.SUPER_ADMIN) {
        const selectedUser = customers.find((u) => u._id === documentId);

        let selectedCompany;
        if (selectedUser) {
          selectedCompany = companies.find(
            (c) => c._id === selectedUser.company,
          );

          if (!selectedCompany) {
            const url = `${config.api}/${DataType.COMPANY}/${selectedUser.company}?company=${selectedUser.company}`;
            const comp = await props.helpers.crud.run(
              CRUDType.READ_ONE,
              DataType.COMPANY,
              url,
            );
            const cloneComps = cloneDeep(companies);
            cloneComps.push(comp.data);
            setCompanies(cloneComps);

            // Build Options
            setCompanyOptions(DataHelper.buildCompanySelectOptions(cloneComps));
          }
        }
      }
    }

    await actionHelper.handleAction(
      DataType.CUSTOMER,
      (state) => setSelectedCustomer(state),
      action,
      documentId,
    );
  };

  const viewJob = async (action: CRUDType, documentId: string) => {
    setActiveDataType(DataType.JOB);

    await actionHelper.handleAction(
      DataType.JOB,
      (state) => setSelectedJob(state),
      CRUDType.UPDATE,
      documentId,
    );
  };

  const createCustomerJob = async (customerId: string) => {
    setActiveDataType(DataType.JOB);

    await actionHelper.handleAction(
      DataType.JOB,
      (state) => setSelectedJob(state),
      CRUDType.CREATE,
      undefined,
      {prefill: {customer: customerId}},
    );
  };

  const handleSearch = useCallback(
    async (options: any[], searchObj: any, dataType: DataType) => {
      if (
        searchObj &&
        searchObj.searchQuery !== searchedValue &&
        searchObj.searchQuery.length > 2
      ) {
        const result = await props.helpers.crud
          .process(searchObj.searchQuery)
          .run(CRUDType.SEARCH, dataType, undefined);

        setSearchedValue(searchObj.searchQuery);

        let opts;
        switch (dataType) {
          case DataType.COMPANY:
            opts = DataHelper.buildCompanySelectOptions(result.data);
            break;
          case DataType.USER:
            opts = DataHelper.buildUserSelectOptions(result.data, [
              {
                title: 'Position',
                data: 'position',
              },
            ]);
            break;
          case DataType.CUSTOMER:
            opts = DataHelper.buildUserSelectOptions(
              result.data,
              [
                {title: 'Company', data: 'companyName'},
                {title: 'Account Number', data: 'account', toUpper: true},
                {title: 'Postcode(s)', data: 'postalCode', toUpper: true},
              ],
              true,
            );
            break;
        }

        const searchOpts = cloneDeep(searchOptions);
        searchOpts[dataType] = opts;
        setSearchOptions(searchOpts);
      }
    },
    [props.helpers.crud, searchedValue, searchOptions],
  );

  const filterSections = [
    {
      title: '',
      fields: [
        {
          title: 'Company Name',
          dataField: 'companyName',
          type: DataFormType.TEXT,
        },
        {
          title: 'Account Number',
          dataField: 'account',
          type: DataFormType.TEXT,
        },
        {
          title: 'Customer Name',
          dataField: '_id',
          dataSet: searchOptions[DataType.CUSTOMER] || customerOptions,
          dataType: DataType.CUSTOMER,
          type: DataFormType.SEARCH_SELECT_API,
          placeholder: 'Type to search',
        },
        {
          title: 'Phone',
          dataField: 'contact.phone',
          type: DataFormType.TEXT,
        },
        {
          title: 'Email',
          dataField: 'contact.email',
          type: DataFormType.TEXT_LOWER,
        },
        {
          title: 'Postcode',
          dataField: 'contact.address.postalCode',
          type: DataFormType.TEXT_UPPER,
        },
      ],
    },
  ];

  return (
    <>
      {!hideFilter && (
        <aside>
          <div className={'box'}>
            <DataFilter
              sections={filterSections}
              data={filters}
              setData={setFilters}
              dataType={DataType.CUSTOMER}
              primaryAction={() => {
                setPage(1);
                setLoadedCustomers(false);
                setLoadingCustomers(false);
                loadTotals();
              }}
              searchAction={handleSearch}
            />
          </div>
        </aside>
      )}

      <main style={{gridColumn: hideFilter ? '1/-1' : ''}}>
        {props.loggedInUser.role === UserRole.SUPER_ADMIN && (
          <FileImport
            dataType={DataType.CUSTOMER}
            loggedInUser={props.loggedInUser}
            crudAction={props.crudAction}
            setOpenState={setImportOpen}
            openState={importOpen}
          />
        )}

        {props.isHome && <Welcome loggedInUser={props.loggedInUser} />}

        <div
          className={`box ${props.isHome && 'HomeTableHeight'} ${
            props.loggedInUser.role === UserRole.SUPER_ADMIN
              ? importOpen
                ? 'UploadTableHeightOpen'
                : 'UploadTableHeightClosed'
              : ''
          }`}
        >
          <DataTable
            hideFilter={hideFilter}
            setHideFilter={setHideFilter}
            title="Customers"
            titleIcon={<FontAwesomeIcon icon={faUsers} />}
            description={
              <div>
                <p>You can add, update and delete customers in this section.</p>
                <p>
                  Once added, your customer file can always be found here, along
                  with a list of any jobs you have done previously, or are
                  currently in progress of doing for them.
                </p>
              </div>
            }
            className={'StyledDataTable'}
            data={customers || []}
            dataType={DataType.CUSTOMER}
            primaryAction={CRUDType.CREATE}
            clickAction={CRUDType.UPDATE}
            handleAction={handleAction}
            apiSortOrder={sortOrder}
            paginationAction={(page, reset?, activeSortOrder?) => {
              if (activeSortOrder) {
                setSortOrder(activeSortOrder);
              }

              setPage(page);
              setLoadedCustomers(false);
              setLoadingCustomers(false);
            }}
            collectionTotal={customerTotal}
            cols={[
              {
                title: 'Account Number',
                dataField: 'account',
                type: DataTableType.PLAIN,
                mobileDisplay: true,
              },
              {
                title: 'Company',
                dataField: 'companyName',
                type: DataTableType.EMAIL,
                mobileDisplay: true,
              },
              {
                title: 'Name',
                dataField: '_id',
                dataProcess: {
                  action: DataHelper.getValueFromData,
                  data: customers,
                  field: ValueTemplate.CONTACT,
                },
                type: DataTableType.EMAIL,
                mobileDisplay: true,
              },
              {
                title: 'Phone',
                dataField: 'contact.0.phone',
                type: DataTableType.STRING,
                mobileDisplay: true,
              },
              {
                title: 'Town',
                dataField: 'contact.0.address.address3',
                type: DataTableType.STRING,
                mobileDisplay: false,
              },
              {
                title: 'Postcode',
                dataField: 'contact.0.address.postalCode',
                type: DataTableType.POSTAL_CODE,
                mobileDisplay: true,
              },
            ]}
          />
        </div>

        {props.showDataForm.status && activeDataType === DataType.CUSTOMER && (
          <CustomerModal
            loggedInUser={props.loggedInUser}
            loggedInCompany={props.loggedInCompany}
            crudAction={props.crudAction}
            selectedUserJobs={selectedUserJobs}
            showDataForm={props.showDataForm}
            viewJob={viewJob}
            createCustomerJob={createCustomerJob}
            resetDataType={() => setActiveDataType(DataType.CUSTOMER)}
            actionHelper={actionHelper}
            setDataForm={props.setDataForm}
            selected={selectedCustomer}
            setSelected={setSelectedCustomer}
            companyOptions={companyOptions}
            users={users}
            customers={customers}
            searchAction={handleSearch}
            searchOptions={searchOptions}
            setSearchOptions={setSearchOptions}
            setModalData={props.helpers.modal.setState(selectedCustomer)}
            setSelectedUserJobs={setSelectedUserJobs}
          />
        )}

        {props.showDataForm.status && activeDataType === DataType.JOB && (
          <JobModal
            loggedInUser={props.loggedInUser}
            loggedInCompany={props.loggedInCompany}
            crudAction={props.crudAction}
            showDataForm={props.showDataForm}
            actionHelper={actionHelper}
            resetDataType={() => setActiveDataType(DataType.CUSTOMER)}
            setDataForm={props.setDataForm}
            callback={async () =>
              await actionHelper.handleAction(
                DataType.JOB,
                (jobs) => setSelectedUserJobs(jobs),
                CRUDType.READ,
                selectedCustomer._id,
                (jobs) => setSelectedUserJobs(jobs),
                `${config.api}/${DataType.JOB}/customer/${selectedCustomer._id}?company=${props.loggedInUser.company}`,
              )
            }
            selected={selectedJob}
            setSelected={setSelectedJob}
            companyOptions={companyOptions}
            users={users}
            userOptions={userOptions}
            customers={customers}
            customerOptions={customerOptions}
            companies={companies}
            searchAction={handleSearch}
            searchOptions={searchOptions}
            setSearchOptions={setSearchOptions}
            setModalData={props.helpers.modal.setState(selectedJob)}
            setPromptModal={(modalType, status, title, body, action) =>
              props.helpers.modal.showModal(
                modalType,
                status,
                title,
                body,
                action,
              )
            }
          />
        )}
      </main>
    </>
  );
};

export default Customers;
