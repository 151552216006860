import {
  faCalendarDay,
  faClock,
  faUser,
  faList,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, {PropsWithChildren} from 'react';

interface DataAvailabilityPickerProps extends PropsWithChildren<{}> {
  className: string;
  availability: {
    userId: string;
    userName?: string;
    startDate: Date;
    endDate: Date;
    description: string;
  }[];
}

const DataAvailabilityPicker: React.FunctionComponent<DataAvailabilityPickerProps> = (
  props,
) => {
  return (
    <div className={'DataAvailabilityPickerWrapper'}>
      <div>
        <div className={props.className}>{props.children}</div>
      </div>

      {props.availability?.length > 0 && (
        <>
          <div className={'DataAvailabilityWrapper'}>
            <div className={'DataAvailabilityHeader'}>
              <p>Staff Availability</p>
            </div>

            <div className={'DataAvailabilityBody'}>
              <div>
                {props.availability.map((log, index) => (
                  <div key={`staff-${index}`}>
                    <div>
                      <FontAwesomeIcon icon={faCalendarDay} size={'1x'} />
                      <p className={'bold'}>
                        {moment(log.startDate).format('DD/MM/yyyy')} -{' '}
                        {moment(log.endDate).format('DD/MM/yyyy')}
                      </p>
                    </div>

                    <div>
                      <FontAwesomeIcon icon={faClock} size={'1x'} />
                      <p className={'bold'}>
                        {moment(log.startDate).format('hh:mm')} -{' '}
                        {moment(log.endDate).format('hh:mm')}
                      </p>
                    </div>

                    <div>
                      <FontAwesomeIcon icon={faUser} size={'1x'} />
                      <p className={'bold'}>{log.userName}</p>
                    </div>

                    <div>
                      <FontAwesomeIcon icon={faList} size={'1x'} />
                      <p>{log.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DataAvailabilityPicker;
