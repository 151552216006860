import axios from 'axios';
import {cloneDeep} from 'lodash';
import React, {FC, useState} from 'react';
import {Dropdown} from 'semantic-ui-react';
import {DropdownOption} from './DataConst';

const VAL_BREAK = '*****';
const API_KEY = 'rp-73ustQUSx5MzcCwwowQ37788';
const POSTCODE_RE = /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/;

interface DataAddressLookupProps {
  field;
  value: string;
  contactData;
  onChange;
}

const DataAddressLookup: FC<DataAddressLookupProps> = ({
  field,
  value,
  contactData,
  onChange,
}) => {
  const [selectedAddress, setSelectedAddress] = useState<string>('');
  const [searchOptions, setSearchOptions] = useState<DropdownOption[]>([]);

  const fetchPostcode = async (id: string) => {
    const url = `https://api.getAddress.io/get/${id}?api-key=${API_KEY}`;

    const result = await axios.get(url);

    return result?.data?.postcode || '';
  };

  const handleAddressLookupSearch = async (search: string) => {
    const isPostcode = POSTCODE_RE.test(search);
    const url = `https://api.getAddress.io/autocomplete/${search}?api-key=${API_KEY}&top=20&all=true`;

    const results = await axios.get(url);

    if (!results?.data?.suggestions) {
      setSearchOptions([]);
    }

    setSearchOptions(
      results?.data?.suggestions?.map((r) => {
        let val = r.address;
        if (isPostcode) {
          val = `${r.address}, ${search.toUpperCase()}`;
        }
        return {
          value: `${r.id}${VAL_BREAK}${val}`,
          key: val,
          text: val,
        };
      }),
    );
  };

  const handleAddressSelection = async (value: string) => {
    const splitAddress = value.split(', ');
    const addressId = splitAddress[0].split(VAL_BREAK)[0];
    const address1 = splitAddress[0].split(VAL_BREAK)[1];
    const address2 = splitAddress[1];
    const address3 = splitAddress[2];
    let postCode = splitAddress[splitAddress.length - 1];

    if (!POSTCODE_RE.test(postCode)) {
      postCode = await fetchPostcode(addressId);
    }

    const clonedData = cloneDeep(contactData);

    clonedData.contact = {
      ...clonedData.contact,
      address: {
        address1: address1 || '',
        address2: address2 || '',
        address3: address3 || '',
        postalCode: postCode || '',
      },
    };
    clonedData['contact.address.address1'] = address1 || '';
    clonedData['contact.address.address2'] = address2 || '';
    clonedData['contact.address.address3'] = address3 || '';
    clonedData['contact.address.postalCode'] = postCode || '';

    setSelectedAddress(value);
    onChange(clonedData);
  };

  return (
    <Dropdown
      autoComplete={'off'}
      className={`positionIcon ${field.error ? 'error' : ''} ${
        field.lookup ? 'DropdownLookup' : ''
      }`}
      icon={field.icon || 'search'}
      placeholder={field.placeholder || `Type to search ${field.title}`}
      noResultsMessage={'Type to start searching.'}
      fluid
      search
      onSearchChange={(options, searchObj) =>
        handleAddressLookupSearch(searchObj.searchQuery)
      }
      selectOnBlur={false}
      selection
      value={selectedAddress || ''}
      onChange={async (e, data) => handleAddressSelection(data.value as string)}
      options={searchOptions}
    />
  );
};

export default DataAddressLookup;
