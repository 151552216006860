import {faUsersCog} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ObjectId from 'bson-objectid';
import {cloneDeep} from 'lodash';
import React, {useCallback, useEffect, useState} from 'react';
import {CRUDType, DataFormType, DataTableType} from '../components/Data/DataConst';
import DataFilter from '../components/Data/DataFilter';
import DataTable, {SortOrder} from '../components/Data/DataTable';
import JobModal from '../components/Modal/JobModal';
import UserModal from '../components/Modal/UserModal';
import Welcome from '../components/Welcome';
import config from '../config/config';
import {DataType} from '../const/crud';
import {JobEnums, jobInitialState} from '../models/Job';
import {User, UserEnums, userInitialState, UserRole} from '../models/User';
import ActionHelper from '../utils/actionHelper';
import {DataHelper} from '../utils/dataHelper';
import {SocketState} from '../utils/socketHelper';
import {PageProps} from './Page';

export interface UserPageProps extends PageProps {}

const Users: React.FunctionComponent<UserPageProps> = (
  props: UserPageProps,
) => {
  // Filter
  const [hideFilter, setHideFilter] = useState<boolean>(false);

  const [selectedUserJobs, setSelectedUserJobs] = useState<any>();
  const [selectedUserJobsPage, setSelectedUserJobsPage] = useState<number>(1);
  const [selectedUserJobsTotal, setSelectedUserJobsTotal] = useState<number>(0);
  const [activeDataType, setActiveDataType] = useState(DataType.USER);

  const [filters, setFilters] = useState<any>({});
  const [searchedValue, setSearchedValue] = useState<any>();
  const [searchOptions, setSearchOptions] = useState({});
  const [page, setPage] = useState(1);
  const [sortOrder, setSortOrder] = useState(SortOrder.NEWEST_FIRST);

  const [companies, setCompanies] = useState<any>();
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [loadedCompanies, setLoadedCompanies] = useState(false);
  const [companyOptions, setCompanyOptions] = useState<any>();

  const [users, setUsers] = useState<any>();
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [loadedUsers, setLoadedUsers] = useState(false);
  const [selectedUser, setSelectedUser] = useState(userInitialState);
  const [userTotal, setUserTotal] = useState(0);
  const [loadingTotal, setLoadingTotal] = useState(false);
  const [loadedTotal, setLoadedTotal] = useState(false);
  const [userCount, setUserCount] = useState({current: 0, max: 0});
  const [userOptions, setUserOptions] = useState<any>();

  const [selectedJob, setSelectedJob] = useState(jobInitialState);
  const [customers, setCustomers] = useState<any>();
  const [customerOptions, setCustomerOptions] = useState<any>();

  const loadTotals = () => {
    setLoadedTotal(false);
    setLoadingTotal(false);
  };

  useEffect(() => {
    if (loadedUsers && users && userCount) {
      const setters = {
        [DataType.USER]: {
          collection: setUsers,
          count: setUserCount,
          total: loadTotals,
        },
        [DataType.JOB]: {
          collection: setSelectedUserJobs,
          selected: setSelectedJob,
        },
      };

      if ([props.loggedInUser.role === UserRole.SUPER_ADMIN]) {
        setters[DataType.COMPANY] = {
          collection: setCompanies,
        };
      }

      props.helpers.socket.addSetters(setters);

      const socketState: SocketState = {};
      socketState[DataType.USER] = {
        collection: users,
        count: userCount,
      };
      socketState[DataType.JOB] = {
        selected: selectedJob,
        collection: selectedUserJobs,
      };

      if ([props.loggedInUser.role === UserRole.SUPER_ADMIN]) {
        socketState[DataType.COMPANY] = {
          collection: companies,
        };
      }

      props.helpers.socket.addState(socketState);
    }
  }, [
    props.helpers.socket,
    props.loggedInUser,
    loadedUsers,
    loadedCompanies,
    companies,
    users,
    userCount,
    selectedJob,
    selectedUserJobs,
  ]);

  // Get users
  useEffect(() => {
    if (!loadedUsers && !loadingUsers && props.loggedInUser.company) {
      setLoadingUsers(true);
      const getUsers = async () => {
        const usersResult = (
          await props.helpers.crud.run(
            CRUDType.READ,
            DataType.USER,
            undefined,
            {
              sort: {created: sortOrder === SortOrder.NEWEST_FIRST ? -1 : 1},
              page,
              pageSize: 10,
              ...filters,
            },
          )
        ).data;

        setUsers(usersResult);

        // Create Options
        setUserOptions(DataHelper.buildUserSelectOptions(usersResult));

        // Get user count
        const url = `${config.api}/${DataType.COMPANY}/user-count/${props.loggedInUser.company}`;
        setUserCount(
          (
            await props.helpers.crud.run(
              CRUDType.READ,
              DataType.USER_COUNT,
              url,
            )
          ).data,
        );

        setLoadedUsers(true);
        setLoadingUsers(false);

        // Get new totals
        setLoadedTotal(false);
        setLoadingTotal(false);
      };
      getUsers();
    }
  }, [
    props.helpers.crud,
    props.loggedInUser.company,
    page,
    filters,
    loadedUsers,
    loadingUsers,
    sortOrder,
  ]);

  // Get Total
  useEffect(() => {
    if (!loadingTotal && !loadedTotal && users) {
      setLoadingTotal(true);
      const getTotal = async () => {
        const totalUrl = `${config.api}/${DataType.USER}/total`;
        setUserTotal(
          (
            await props.helpers.crud.run(
              CRUDType.READ,
              DataType.USER,
              totalUrl,
              {
                page,
                pageSize: 10,
                ...filters,
              },
            )
          ).data.total,
        );

        setLoadedTotal(true);
        setLoadingTotal(true);
      };
      getTotal();
    }
  }, [props.helpers.crud, filters, page, loadingTotal, loadedTotal, users]);

  // Get companies if super_admin
  useEffect(() => {
    if (
      props.loggedInUser &&
      props.loggedInUser.role === UserRole.SUPER_ADMIN &&
      users &&
      !loadedCompanies &&
      !loadingCompanies
    ) {
      setLoadingCompanies(true);
      const getCompanies = async () => {
        const companyIds: any = [];

        users.forEach((user: User) => {
          companyIds.push(new ObjectId(user.company));
        });

        const result = (
          await props.helpers.crud.run(
            CRUDType.READ,
            DataType.COMPANY,
            undefined,
            {page: 1, pageSize: 30, _id: {$in: companyIds}},
          )
        ).data;

        setCompanies(result);

        // Create Options
        setCompanyOptions(DataHelper.buildCompanySelectOptions(result));

        setLoadedCompanies(true);
        setLoadingCompanies(false);
      };
      getCompanies();
    }
  }, [
    page,
    props.loggedInUser,
    props.helpers.crud,
    filters,
    users,
    loadingCompanies,
    loadedCompanies,
    loadingUsers,
    loadedUsers,
  ]);

  // Set company
  userInitialState.company = props.loggedInUser.company;

  const actionHelper = new ActionHelper(
    props.authToken,
    props.helpers.modal,
    props.setDataForm,
    props.loggedInUser,
    props.loggedInCompany,
  );

  const getSelectedUserJobs = async (documentId, page?) => {
    const getPage = page || selectedUserJobsPage;

    // Get selected user's jobs
    let adminUser;
    if (props.loggedInUser.role === UserRole.SUPER_ADMIN) {
      adminUser = `&adminUser=${props.loggedInUser._id}`;
    }

    const jobs = await actionHelper.handleAction(
      DataType.JOB,
      (jobs) => setSelectedUserJobs(jobs),
      CRUDType.READ,
      documentId,
      null,
      `${config.api}/${DataType.JOB}/assigned/${documentId}?company=${
        props.loggedInUser.company
      }${adminUser ? adminUser : ''}&page=${getPage}&pageSize=5`,
    );

    // Get relevant customers and users
    if (jobs) {
      const customerIds: string[] = [];
      let userIds: string[] = [props.loggedInUser._id].concat(
        users.map((user) => user._id),
      );

      // Get total jobs
      const totalUrl = `${config.api}/${DataType.JOB}/total`;
      let filters = {$or: []};
      for (const stage of JobEnums.JobStages) {
        const filter = {[`stages.${stage}.assignedTo`]: documentId};
        filters['$or'].push(filter);
      }

      setSelectedUserJobsTotal(
        (
          await props.helpers.crud.run(CRUDType.READ, DataType.JOB, totalUrl, {
            filters,
          })
        ).data.total,
      );

      jobs.forEach((job) => {
        customerIds.push(job.customer);

        Object.keys(job.stages).forEach((stage) => {
          if (job.stages[stage].assignedTo) {
            userIds = userIds.concat(job.stages[stage].assignedTo);
          }
        });
      });
      const customersResult = (
        await props.helpers.crud.run(
          CRUDType.READ,
          DataType.CUSTOMER,
          undefined,
          {page: 1, pageSize: 50, _id: {$in: customerIds}},
        )
      ).data;

      setCustomers(customersResult);

      // Build CustomerOptions
      setCustomerOptions(
        DataHelper.buildUserSelectOptions(
          customersResult,
          [
            {title: 'Company', data: 'companyName'},
            {title: 'Account Number', data: 'account', toUpper: true},
            {title: 'Postcode(s)', data: 'postalCode', toUpper: true},
          ],
          true,
        ),
      );

      const usersResult = (
        await props.helpers.crud.run(CRUDType.READ, DataType.USER, undefined, {
          page: 1,
          pageSize: 50,
          _id: {$in: userIds},
        })
      ).data;

      setUsers(usersResult);

      // Build Options
      setUserOptions(DataHelper.buildUserSelectOptions(usersResult));
    }

    // Get company if super admin and doesn't have it
    if (props.loggedInUser.role === UserRole.SUPER_ADMIN) {
      const selectedUser = users.find((u) => u._id === documentId);

      let selectedCompany;
      if (selectedUser) {
        selectedCompany = companies.find((c) => c._id === selectedUser.company);

        if (!selectedCompany) {
          const url = `${config.api}/${DataType.COMPANY}/${selectedUser.company}?company=${selectedUser.company}`;
          const comp = await props.helpers.crud.run(
            CRUDType.READ_ONE,
            DataType.COMPANY,
            url,
          );
          const cloneComps = cloneDeep(companies);
          cloneComps.push(comp.data);
          setCompanies(cloneComps);

          // Build Options
          setCompanyOptions(DataHelper.buildCompanySelectOptions(cloneComps));
        }
      }
    }
  };

  const handleAction = async (action: CRUDType, documentId: string) => {
    if (action === CRUDType.UPDATE) {
      try {
        await getSelectedUserJobs(documentId);
      } catch (err) {}
    }

    await actionHelper.handleAction(
      DataType.USER,
      (state) => setSelectedUser(state),
      action,
      documentId,
    );
  };

  const viewJob = async (action: CRUDType, documentId: string) => {
    setActiveDataType(DataType.JOB);

    await actionHelper.handleAction(
      DataType.JOB,
      (state) => setSelectedJob(state),
      CRUDType.UPDATE,
      documentId,
    );
  };

  const handleSearch = useCallback(
    async (options: any[], searchObj: any, dataType: DataType) => {
      if (
        searchObj &&
        searchObj.searchQuery !== searchedValue &&
        searchObj.searchQuery.length > 2
      ) {
        const result = await props.helpers.crud
          .process(searchObj.searchQuery)
          .run(CRUDType.SEARCH, dataType, undefined);

        setSearchedValue(searchObj.searchQuery);

        let opts;
        switch (dataType) {
          case DataType.COMPANY:
            opts = DataHelper.buildCompanySelectOptions(result.data);
            break;
          case DataType.USER:
            opts = DataHelper.buildUserSelectOptions(result.data, [
              {
                title: 'Position',
                data: 'position',
              },
            ]);
            break;
          case DataType.CUSTOMER:
            opts = DataHelper.buildUserSelectOptions(
              result.data,
              [
                {title: 'Company', data: 'companyName'},
                {title: 'Account Number', data: 'account', toUpper: true},
                {title: 'Postcode(s)', data: 'postalCode', toUpper: true},
              ],
              true,
            );
            break;
        }

        const searchOpts = cloneDeep(searchOptions);
        searchOpts[dataType] = opts;
        setSearchOptions(searchOpts);
      }
    },
    [props.helpers.crud, searchedValue, searchOptions],
  );

  const filterSections = [
    {
      title: '',
      fields: [
        {
          title: 'First Name',
          dataField: 'firstName',
          type: DataFormType.TEXT,
        },
        {
          title: 'Last Name',
          dataField: 'lastName',
          type: DataFormType.TEXT,
        },
        {
          title: 'Email',
          dataField: 'contact.email',
          type: DataFormType.TEXT_LOWER,
        },
        {
          title: 'System Role',
          dataField: 'role',
          dataSet: UserEnums.UserRoleOptions,
          type: DataFormType.SELECT,
        },
      ],
    },
  ];

  return (
    <>
      {!hideFilter && (
        <aside>
          <div className={'box'}>
            <DataFilter
              sections={filterSections}
              data={filters}
              setData={setFilters}
              dataType={DataType.USER}
              primaryAction={() => {
                setPage(1);
                setLoadedUsers(false);
                setLoadingUsers(false);
                loadTotals();
              }}
              searchAction={handleSearch}
            />
          </div>
        </aside>
      )}

      <main style={{gridColumn: hideFilter ? '1/-1' : ''}}>
        {props.isHome && <Welcome loggedInUser={props.loggedInUser} />}

        <div className={`box ${props.isHome && 'HomeTableHeight'}`}>
          <DataTable
            hideFilter={hideFilter}
            setHideFilter={setHideFilter}
            title={
              props.loggedInUser.role !== UserRole.SUPER_ADMIN && userCount ? (
                <h1>
                  Users{' '}
                  <span>
                    (Purchased {userCount.max} - Allocated {userCount.current} -
                    Available {userCount.max - userCount.current})
                  </span>
                </h1>
              ) : (
                'Users'
              )
            }
            titleIcon={<FontAwesomeIcon icon={faUsersCog} />}
            headline={
              props.loggedInUser.role !== UserRole.SUPER_ADMIN &&
              userCount &&
              userCount.current >= userCount.max && (
                <p>
                  You have allocated all of your Users.{' '}
                  <a
                    target={'_blank'}
                    rel={'noreferrer'}
                    href={'https://onsite7.co.uk/my-account/'}
                  >
                    Click here
                  </a>{' '}
                  to upgrade your subscription.
                </p>
              )
            }
            description={
              <div>
                <p>
                  A 'User' is either an 'Admin User' (Someone who has access to
                  this online console, such as an office manager or business
                  owner) or an 'Onsite User' (Someone who uses the App onsite,
                  such as a tradesman, contractor or team member.)
                </p>
                <p>Both types of User can be added, updated or deleted here.</p>
              </div>
            }
            className={'StyledDataTable'}
            data={users || []}
            dataType={DataType.USER}
            primaryAction={CRUDType.CREATE}
            disabledPrimaryAction={
              props.loggedInUser.role !== UserRole.SUPER_ADMIN &&
              userCount &&
              userCount.current >= userCount.max
            }
            clickAction={CRUDType.UPDATE}
            handleAction={(action: CRUDType, id: string) =>
              handleAction(action, id)
            }
            apiSortOrder={sortOrder}
            paginationAction={(page, reset?, activeSortOrder?) => {
              if (activeSortOrder) {
                setSortOrder(activeSortOrder);
              }
              setPage(page);
              setLoadedUsers(false);
              setLoadingUsers(false);
            }}
            collectionTotal={userTotal}
            cols={[
              {
                title: 'System Role',
                dataField: 'role',
                dataProcess: {
                  action: (id, options, data, field) => {
                    const val = DataHelper.getDataFromOptions(
                      id,
                      options,
                      data,
                      field,
                    );
                    if (val === UserRole.USER) {
                      return 'Onsite User';
                    }
                    return val;
                  },
                  options: UserEnums.UserRoleOptions,
                },
                type: DataTableType.STRING,
                mobileDisplay: true,
                className: (value) => DataHelper.statusColourClass(value),
              },
              {
                title: 'First Name',
                dataField: 'firstName',
                type: DataTableType.STRING,
                mobileDisplay: true,
              },
              {
                title: 'Last Name',
                dataField: 'lastName',
                type: DataTableType.STRING,
                mobileDisplay: true,
              },
              {
                title: 'Position',
                dataField: 'position',
                type: DataTableType.STRING,
                mobileDisplay: false,
              },
              {
                title: 'Email',
                dataField: 'contact.email',
                type: DataTableType.EMAIL,
                mobileDisplay: true,
              },
              {
                title: 'Phone',
                dataField: 'contact.phone',
                type: DataTableType.STRING,
                mobileDisplay: true,
              },
            ]}
          />
        </div>

        {props.showDataForm.status && activeDataType === DataType.USER && (
          <UserModal
            loggedInUser={props.loggedInUser}
            loggedInCompany={props.loggedInCompany}
            crudAction={props.crudAction}
            selectedUserJobs={selectedUserJobs}
            showDataForm={props.showDataForm}
            viewJob={viewJob}
            resetDataType={() => setActiveDataType(DataType.USER)}
            actionHelper={actionHelper}
            setDataForm={props.setDataForm}
            selected={selectedUser}
            setSelected={setSelectedUser}
            companyOptions={companyOptions}
            customers={customers}
            companies={companies}
            searchAction={handleSearch}
            searchOptions={searchOptions}
            setSearchOptions={setSearchOptions}
            setSelectedUserJobs={setSelectedUserJobs}
            selectedUserJobsTotal={selectedUserJobsTotal}
            setSelectedUserJobsPage={setSelectedUserJobsPage}
            getSelectedUserJobs={getSelectedUserJobs}
          />
        )}

        {props.showDataForm.status && activeDataType === DataType.JOB && (
          <JobModal
            loggedInUser={props.loggedInUser}
            loggedInCompany={props.loggedInCompany}
            crudAction={props.crudAction}
            showDataForm={props.showDataForm}
            actionHelper={actionHelper}
            resetDataType={() => setActiveDataType(DataType.USER)}
            setDataForm={props.setDataForm}
            callback={async () =>
              await actionHelper.handleAction(
                DataType.JOB,
                (jobs) => setSelectedUserJobs(jobs),
                CRUDType.READ,
                selectedUser._id,
                (jobs) => setSelectedUserJobs(jobs),
                `${config.api}/${DataType.JOB}/assigned/${selectedUser._id}?company=${props.loggedInUser.company}`,
              )
            }
            selected={selectedJob}
            setSelected={setSelectedJob}
            companyOptions={companyOptions}
            users={users}
            userOptions={userOptions}
            customers={customers}
            customerOptions={customerOptions}
            companies={companies}
            searchAction={handleSearch}
            searchOptions={searchOptions}
            setSearchOptions={setSearchOptions}
            setModalData={props.helpers.modal.setState(selectedJob)}
            setPromptModal={(modalType, status, title, body, action) =>
              props.helpers.modal.showModal(
                modalType,
                status,
                title,
                body,
                action,
              )
            }
          />
        )}
      </main>
    </>
  );
};

export default Users;
