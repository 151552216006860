import {faCheck} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {startCase} from 'lodash';
import React, {useState} from 'react';
import {Button, Divider, Dropdown, Form} from 'semantic-ui-react';
import {DataType} from '../const/crud';
import {Company} from '../models/Company';
import {JobStage, jobStageMap} from '../models/Job';
import {User} from '../models/User';
import {DataHelper, ValueTemplate} from '../utils/dataHelper';
import {DataActionType, DataTableType, SortDirection} from './Data/DataConst';
import DataTable from './Data/DataTable';

interface ChecklistProps {
  stage: JobStage;
  stageData: any;
  handleDataAction: any;
  users: User[];
  loggedInCompany: Company;
  loggedInUser: User;
  state: any;
}

const Checklist: React.FunctionComponent<ChecklistProps> = (
  props: ChecklistProps,
) => {
  const [importGroup, setImportGroup] = useState<any>();

  const importOptions = props.loggedInCompany?.checklists?.map((i) => {
    return {
      key: i._id,
      text: i.name,
      value: i._id,
      description: i.checklistType,
    };
  });

  return (
    <div className={'Checklist'}>
      <Divider horizontal>
        <h3>
          {startCase(
            jobStageMap(props.stage, props.loggedInCompany.stageTitles),
          )}{' '}
          Checklist
        </h3>
      </Divider>

      <div className={'AddTask'}>
        <Form.Field>
          <h3>Import Checklist</h3>
          <p>
            Select a checklist to import from your pre-defined templates. These
            templates can be edited in your 'Companies' page.
          </p>
          <Dropdown
            placeholder={`Select Checklist`}
            fluid
            selection
            options={importOptions}
            value={importGroup}
            onChange={(e, data) => setImportGroup(data.value)}
          />
        </Form.Field>
        <Button
          disabled={!importGroup}
          primary
          onClick={() => {
            const tasks = props.loggedInCompany.checklists.find(
              (i) => i._id === importGroup,
            )?.tasks;

            props.handleDataAction(
              DataActionType.IMPORT_CHECKLIST_TEMPLATE,
              importGroup,
              {
                stage: props.stage,
                selected: props.state,
                tasks,
              },
            );
          }}
        >
          Import Tasks
        </Button>
      </div>

      <DataTable
        title="Checklist"
        description={'Add a checklist of items that are required for this job.'}
        titleIcon={<FontAwesomeIcon icon={faCheck} />}
        data={props.stageData[props.stage].checklist}
        dataType={DataType.CHECKLIST}
        primaryAction={DataActionType.CREATE_CHECKLIST}
        handleAction={props.handleDataAction}
        addParams={{
          stage: props.stage,
        }}
        pageSize={5}
        sortField={['created']}
        sortDirection={[SortDirection.ASC]}
        actions={[
          {
            title: 'Edit',
            icon: 'pencil',
            className: 'spaceBelow',
            action: DataActionType.EDIT_CHECKLIST,
            data: {stage: props.stage},
          },
          {
            title: 'Delete',
            icon: 'delete',
            className: 'redButton',
            action: DataActionType.DELETE_CHECKLIST,
            data: {stage: props.stage},
          },
        ]}
        cols={[
          {
            title: 'Task',
            dataField: 'text',
            type: DataTableType.PLAIN,
            mobileDisplay: true,
            width: 6,
          },
          {
            title: 'Status',
            dataField: 'status',
            type: DataTableType.STATUS_CHECK,
            mobileDisplay: true,
            width: 3,
          },
          {
            title: 'Completed By',
            dataField: 'completedBy',
            dataProcess: {
              action: DataHelper.getValueFromData,
              data: props.users,
              field: ValueTemplate.NAME,
            },
            type: DataTableType.STRING,
            mobileDisplay: false,
            width: 3,
          },
          {
            title: 'Date Completed',
            dataField: 'completed',
            type: DataTableType.DATE_TIME,
            mobileDisplay: true,
            width: 3,
          },
        ]}
      />
    </div>
  );
};

export default Checklist;
