import ObjectId from 'bson-objectid';
import {cloneDeep} from 'lodash';
import React, {useState} from 'react';
import {Button, Dropdown, Form, Icon, Input, Table} from 'semantic-ui-react';
import {Company} from '../models/Company';
import {JobEnums, jobStageMap} from '../models/Job';
import {User} from '../models/User';

interface CostGroupProps {
  data: any;
  setCostGroups: any;
  loggedInUser: User;
  loggedInCompany: Company;
}

const CostGroup: React.FunctionComponent<CostGroupProps> = (
  props: CostGroupProps,
) => {
  const [cost, setCost] = useState<string>('');
  const [costAmount, setCostAmount] = useState<number>(0);
  const [costStage, setCostStage] = useState<string>('');
  const [costType, setCostType] = useState<string>('');
  const [costDescription, setCostDescription] = useState<string>('');
  const [costCompany, setCostCompany] = useState<string>('');
  const [costInvoice, setCostInvoice] = useState<string>('');

  const deleteCost = (id) => {
    const cloned = props.data['costGroup.costs'].filter((i) => i._id !== id);
    props.setCostGroups(cloned);
  };

  return (
    <div className={'ChecklistTemplate'}>
      <h1>Add Cost</h1>

      <div className={'AddTask'}>
        <Form.Field>
          <div className={'FormFieldTitleContainer'}>
            <h3>Name</h3>
            <span className={'RedText'}>*Required</span>
          </div>
          <Input
            style={{marginBottom: 20}}
            placeholder={`Enter a cost name`}
            value={cost}
            onChange={(e, data) => {
              setCost(data.value);
            }}
          />
        </Form.Field>
        <Form.Field>
          <div className={'FormFieldTitleContainer'}>
            <h3>Amount (Excluding VAT)</h3>
            <span className={'RedText'}>*Required</span>
          </div>
          <Input
            type={'number'}
            label={'£'}
            style={{marginBottom: 20}}
            placeholder={`Enter a cost amount`}
            value={costAmount}
            onChange={(e, data) => {
              setCostAmount(parseFloat(data.value));
            }}
          />
        </Form.Field>
        <Form.Field>
          <div className={'FormFieldTitleContainer'}>
            <h3>Stage</h3>
            <span className={'RedText'}>*Required</span>
          </div>
          <Dropdown
            style={{marginBottom: 20}}
            value={costStage}
            clearable
            placeholder={`Select Stage`}
            fluid
            selection
            options={JobEnums.JobStageOptions(
              props.loggedInCompany.stageTitles,
            )}
            onChange={(e, data) => setCostStage(data.value as string)}
          />
        </Form.Field>
        {/*<Form.Field>*/}
        {/*  <div className={'FormFieldTitleContainer'}>*/}
        {/*    <h3>Type</h3>*/}
        {/*  </div>*/}
        {/*  <Input*/}
        {/*    style={{marginBottom: 20}}*/}
        {/*    placeholder={`Enter a cost type`}*/}
        {/*    value={costType}*/}
        {/*    onChange={(e, data) => {*/}
        {/*      setCostType(data.value);*/}
        {/*    }}*/}
        {/*  />*/}
        {/*</Form.Field>*/}
        <Form.Field>
          <div className={'FormFieldTitleContainer'}>
            <h3>Description</h3>
          </div>
          <Input
            style={{marginBottom: 20}}
            placeholder={`Enter a cost description`}
            value={costDescription}
            onChange={(e, data) => {
              setCostDescription(data.value);
            }}
          />
        </Form.Field>
        <Form.Field>
          <div className={'FormFieldTitleContainer'}>
            <h3>Supplier</h3>
          </div>
          <Input
            style={{marginBottom: 20}}
            placeholder={`Enter a cost supplier`}
            value={costCompany}
            onChange={(e, data) => {
              setCostCompany(data.value);
            }}
          />
        </Form.Field>
        <Form.Field>
          <div className={'FormFieldTitleContainer'}>
            <h3>Invoice Number</h3>
          </div>
          <Input
            style={{marginBottom: 20}}
            placeholder={`Enter a cost invoice number`}
            value={costInvoice}
            onChange={(e, data) => {
              setCostInvoice(data.value);
            }}
          />
        </Form.Field>

        <Button
          disabled={!cost?.length || typeof costAmount === 'undefined'}
          primary
          onClick={() => {
            const costObj = {
              _id: new ObjectId().toHexString(),
              created: new Date(),
              createdBy: props.loggedInUser._id,
              name: cost,
              amount: costAmount,
              stage: costStage,
              type: costType,
              description: costDescription,
              company: costCompany,
              invoiceNumber: costInvoice,
            };

            const cloned = cloneDeep(props.data['costGroup.costs'] || []);
            cloned.push(costObj);

            props.setCostGroups(cloned);

            setCost('');
            setCostAmount(0);
            setCostStage('');
            setCostType('');
            setCostDescription('');
            setCostCompany('');
            setCostInvoice('');
          }}
        >
          Add Cost
        </Button>
      </div>

      <h1>Costs</h1>

      <Table celled>
        <Table.Header>
          <Table.Row>
            {!props.data ||
            !props.data['costGroup.costs'] ||
            !props.data['costGroup.costs'].length ? (
              <Table.HeaderCell textAlign={'center'}>Cost</Table.HeaderCell>
            ) : (
              <>
                <Table.HeaderCell textAlign={'center'}>Name</Table.HeaderCell>
                <Table.HeaderCell textAlign={'center'}>Amount</Table.HeaderCell>
                <Table.HeaderCell textAlign={'center'}>Stage</Table.HeaderCell>
                <Table.HeaderCell textAlign={'center'}>Type</Table.HeaderCell>
                <Table.HeaderCell textAlign={'center'}>
                  Description
                </Table.HeaderCell>
                <Table.HeaderCell textAlign={'center'}>
                  Supplier
                </Table.HeaderCell>
                <Table.HeaderCell textAlign={'center'}>
                  Invoice Number
                </Table.HeaderCell>
                <Table.HeaderCell />
              </>
            )}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {(!props.data ||
            !props.data['costGroup.costs'] ||
            !props.data['costGroup.costs'].length) && (
            <Table.Row>
              <Table.Cell textAlign={'center'} width={16}>
                <p>No costs found</p>
              </Table.Cell>
            </Table.Row>
          )}

          {props.data &&
            props.data['costGroup.costs'] &&
            props.data['costGroup.costs'].map((i) => {
              return (
                <Table.Row key={`costGroups-cost-${i.text}-${i._id}`}>
                  <Table.Cell width={2} textAlign={'center'}>
                    <p>{i.name}</p>
                  </Table.Cell>
                  <Table.Cell width={2} textAlign={'center'}>
                    <p>{i.amount}</p>
                  </Table.Cell>
                  <Table.Cell width={2} textAlign={'center'}>
                    <p>
                      {jobStageMap(i.stage, props.loggedInCompany.stageTitles)}
                    </p>
                  </Table.Cell>
                  <Table.Cell width={2} textAlign={'center'}>
                    <p>{i.type}</p>
                  </Table.Cell>
                  <Table.Cell width={2} textAlign={'center'}>
                    <p>{i.description}</p>
                  </Table.Cell>
                  <Table.Cell width={2} textAlign={'center'}>
                    <p>{i.company}</p>
                  </Table.Cell>
                  <Table.Cell width={2} textAlign={'center'}>
                    <p>{i.invoiceNumber}</p>
                  </Table.Cell>

                  <Table.Cell width={1} textAlign={'center'}>
                    <Button
                      className={'redButton'}
                      icon={true}
                      labelPosition={'left'}
                      onClick={() => deleteCost(i._id)}
                    >
                      <Icon name="delete" />
                      Delete
                    </Button>
                  </Table.Cell>
                </Table.Row>
              );
            })}
        </Table.Body>
      </Table>
    </div>
  );
};

export default CostGroup;
