import React, {FC, useEffect, useState} from 'react';
import {Dropdown, Input} from 'semantic-ui-react';
import {Company} from '../models/Company';

interface JobIdSelectorProps {
  company: Company;
  value: string;
  onChange: any;
  job: any;
}

const JobIdSelector: FC<JobIdSelectorProps> = ({
  company,
  value,
  onChange,
  job,
}) => {
  const [codeOptions, setCodeOptions] = useState([]);
  const [selectedCode, setSelectedCode] = useState<string>(
    value.includes('-') ? value.split('-')[0] : '',
  );
  const [jobId, setJobId] = useState<string>(
    value.includes('-') ? value.split('-')[1] : value,
  );
  const [completeId, setCompleteId] = useState<string>('');

  const buildSelectOptions = (jobCodes) => {
    const formatted = jobCodes.map((i, index) => {
      return {
        key: `job-code-${i.name}-${index}`,
        value: i.name,
        text: i.name,
      };
    });

    setCodeOptions(formatted);
  };

  useEffect(() => {
    if (company) {
      (async () => {
        buildSelectOptions(company.jobCodes || []);
      })();
    }
  }, [company]);

  useEffect(() => {
    if (jobId || selectedCode) {
      setCompleteId(
        `${selectedCode?.length ? selectedCode + '-' : ''}${jobId || ''}`,
      );
    }
  }, [jobId, selectedCode]);

  useEffect(() => {
    if (completeId !== value) {
      onChange(completeId);
    }
  });

  return (
    <div
      style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
    >
      {codeOptions.length > 0 && (
        <Dropdown
          clearable
          placeholder={`Select Job ID Prefix`}
          selection
          value={selectedCode}
          onChange={(e, data) => {
            setSelectedCode(data.value as string);
            setJobId('');
          }}
          options={codeOptions}
        />
      )}
      <div style={{padding: '0 5px'}}>
        <strong>-</strong>
      </div>
      <Input
        type={selectedCode.length ? 'number' : 'text'}
        disabled={!job._id && !!selectedCode.length}
        value={jobId || ''}
        onChange={(e, data) => {
          const val = (data.value as string).replace('-', '');
          setJobId(val);
        }}
      />
    </div>
  );
};

export default JobIdSelector;
