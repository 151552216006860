import React, {useEffect} from 'react';
import {Route} from 'react-router-dom';
import Page from '../containers/Page';
import {UserRole} from '../models/User';
import {useAuth0} from '../react-auth0-spa';

const PrivateRoute = ({
  path,
  render,
  superRoute = false,
  loggedInUser,
  ...rest
}) => {
  const {loading, isAuthenticated, loginWithRedirect} = useAuth0();
  const isSuperAdmin = loggedInUser.role === UserRole.SUPER_ADMIN;

  useEffect(() => {
    if (loading || isAuthenticated) {
      return;
    }
    const fn = async () => {
      await loginWithRedirect({
        appState: {targetUrl: path},
      });
    };
    fn();
  }, [
    loading,
    isAuthenticated,
    loginWithRedirect,
    path,
    superRoute,
    isSuperAdmin,
  ]);

  if (superRoute && !isSuperAdmin) {
    path = '/forbidden';
    render = (props) => (
      <Page {...props} loggedInUser={loggedInUser} pageTitle={'Forbidden'}>
        This page is forbidden.
      </Page>
    );
  }

  return <Route path={path} render={render} {...rest} />;
};

export default PrivateRoute;
