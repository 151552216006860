import {startCase} from 'lodash';
import {DropdownOption} from '../components/Data/DataConst';

export interface Customer {
  _id: string;
  __v: number;
  created: Date;
  createdBy: string;
  company: string;
  companyName?: string;
  account?: string;
  contact: {
    _id: string;
    primaryContact?: boolean;
    created: Date;
    createdBy: string;
    title: string;
    firstName?: string;
    lastName: string;
    phone: string;
    address: {
      address1: string;
      address2?: string;
      address3?: string;
      postalCode: string;
    };
    email?: string;
  }[];
  notes: {
    _id: string;
    created: Date;
    createdBy: string;
    text: string;
  }[];
  xeroContactId?: string;
  quickbooksContactId?: string;
}

export const customerInitialState: Customer = {
  _id: '',
  __v: 0,
  created: new Date(),
  createdBy: '',
  company: '',
  companyName: '',
  account: '',
  contact: [],
  notes: [],
};

export enum CustomerTitle {
  MR = 'mr',
  MRS = 'mrs',
  MRMRS = 'mr & mrs',
  MS = 'ms',
  MISS = 'miss',
  DOCTOR = 'doctor',
  MASTER = 'master',
  SIR = 'sir',
  NA = 'n/a',
}

export class CustomerEnums {
  public static readonly CustomerPrimaryContactOptions: DropdownOption[] = [
    {
      key: 'primary-contract-true',
      text: 'Yes, mark as Primary Contact',
      value: 'true',
    },
    {
      key: 'primary-contract-false',
      text: 'No',
      value: 'false',
    },
  ];

  public static readonly CustomerTitleOptions: DropdownOption[] = [
    {
      key: CustomerTitle.MR,
      text: startCase(CustomerTitle.MR),
      value: CustomerTitle.MR,
    },
    {
      key: CustomerTitle.MRS,
      text: startCase(CustomerTitle.MRS),
      value: CustomerTitle.MRS,
    },
    {
      key: CustomerTitle.MRMRS,
      text: 'Mr & Mrs',
      value: CustomerTitle.MRMRS,
    },
    {
      key: CustomerTitle.MS,
      text: startCase(CustomerTitle.MS),
      value: CustomerTitle.MS,
    },
    {
      key: CustomerTitle.MISS,
      text: startCase(CustomerTitle.MISS),
      value: CustomerTitle.MISS,
    },
    {
      key: CustomerTitle.DOCTOR,
      text: startCase(CustomerTitle.DOCTOR),
      value: CustomerTitle.DOCTOR,
    },
    {
      key: CustomerTitle.MASTER,
      text: startCase(CustomerTitle.MASTER),
      value: CustomerTitle.MASTER,
    },
    {
      key: CustomerTitle.SIR,
      text: startCase(CustomerTitle.SIR),
      value: CustomerTitle.SIR,
    },
    {
      key: CustomerTitle.NA,
      text: 'N/A',
      value: CustomerTitle.NA,
    },
  ];
}
