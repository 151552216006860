import {
  faBuilding,
  faTasks,
  faUsers,
  faUsersCog,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import {useLocation} from 'react-router';
import {Link} from 'react-router-dom';
import logo from '../assets/logo-full.png';
import {Company} from '../models/Company';
import {User, UserRole} from '../models/User';
import LoggedInUser from './LoggedInUser';

export interface NavProps {
  loggedInUser: User;
  loggedInCompany: Company;
}

const activeTabClass = (path, screen) => {
  if (path === screen) {
    return 'activeNavButton';
  }
};

const NavBar: React.FunctionComponent<NavProps> = (props: NavProps) => {
  const location = useLocation();
  const screen = location.pathname;
  const userRole = props.loggedInUser.role;
  return (
    <aside className="NavBar">
      <Link to="/">
        <img className="Logo" src={logo} alt={'Onsite7 Logo'} />
      </Link>

      <span>
        {/*<Link to="/" className={activeTabClass('/', screen)}>*/}
        {/*<FontAwesomeIcon icon={faHouse} />*/}
        {/*</Link>*/}
        {(userRole === UserRole.SUPER_ADMIN ||
          userRole === UserRole.MANAGER ||
          userRole === UserRole.ADMIN) && (
          <>
            <Link
              to="/companies"
              className={activeTabClass('/companies', screen)}
            >
              <FontAwesomeIcon icon={faBuilding} />
              <h3>
                {userRole === UserRole.SUPER_ADMIN ? 'Companies' : 'My Company'}
              </h3>
            </Link>
          </>
        )}
        {(userRole === UserRole.SUPER_ADMIN ||
          userRole === UserRole.MANAGER ||
          userRole === UserRole.ADMIN) && (
          <>
            <Link to="/users" className={activeTabClass('/users', screen)}>
              <FontAwesomeIcon icon={faUsersCog} />
              <h3>Users</h3>
            </Link>
            <Link
              to="/customers"
              className={activeTabClass('/customers', screen)}
            >
              <FontAwesomeIcon icon={faUsers} />
              <h3>Customers</h3>
            </Link>
          </>
        )}
        <Link to="/jobs" className={activeTabClass('/jobs', screen)}>
          <FontAwesomeIcon icon={faTasks} />
          <h3>Jobs</h3>
        </Link>
      </span>

      <LoggedInUser loggedInUser={props.loggedInUser} loggedInCompany={props.loggedInCompany} />
    </aside>
  );
};

export default NavBar;
