import {Text, View} from '@react-pdf/renderer';
import {get, startCase} from 'lodash';
import moment from 'moment';
import React from 'react';
import {
  CalendarEvent,
  DropdownOption,
  DropdownOptionDescription,
} from '../components/Data/DataConst';
import {Company, CompanyStatus} from '../models/Company';
import {Customer} from '../models/Customer';
import {
  Job,
  JobEnums,
  JobStage,
  jobStageMap,
  JobStatus,
  JobType,
} from '../models/Job';
import {User, UserRole} from '../models/User';

export enum ValueTemplate {
  NAME = 'name',
  CONTACT = 'contact',
  CONTACT_SHORT = 'contact-short',
  CONTACT_ADDRESS = 'contact-address',
}

export class DataHelper {
  public static calcVAT(amount, data: Job) {
    if (!amount || !data) {
      return '£0.00';
    }

    const num = parseFloat(amount);

    if (data.vat) {
      return `£${(num * 0.2).toFixed(2)}`;
    }

    return `£0.00`;
  }

  public static showVAT(amount, data: Job) {
    if (!amount || !data) {
      return '£0.00';
    }

    const num = parseFloat(amount);

    if (data.vat) {
      const vat = num * 0.2;
      return `£${(num + vat).toFixed(2)}`;
    }

    return `£${num.toFixed(2)}`;
  }

  public static getStageTitle(stage: JobStage, stageTitles) {
    return jobStageMap(stage, stageTitles);
  }

  public static explodeArray(arr: string[]) {
    return arr && arr.length ? arr.join(', ') : null;
  }

  public static getValueFromData(
    id: string | string[],
    data: any[],
    field: string | ValueTemplate,
    dataField?: string,
  ) {
    if (!data) {
      return null;
    }

    if (typeof id === 'string') {
      id = [id];
    }

    const items: any[] = [];

    id.forEach((i) => {
      const item = data.find((item) => item._id === i);
      if (item) {
        items.push(item);
      }
    });

    if (!items || !items.length) {
      return null;
    }

    let result = '';

    items.forEach((item) => {
      switch (field) {
        case ValueTemplate.NAME:
          if (!result.length) {
            result += `${item.firstName ? item.firstName + ' ' : ''}${
              item.lastName
            }`;
          } else {
            result += ` ${item.firstName ? item.firstName + ' ' : ''}${
              item.lastName
            }`;
          }
          break;
        case ValueTemplate.CONTACT:
          let contact;
          if (dataField) {
            contact = item.contact.find((c) => c._id === dataField);
          } else {
            contact = item.contact[0];
          }
          result +=
            contact &&
            `${
              contact.firstName && contact.firstName.length
                ? contact.firstName + ' '
                : ''
            }${contact.lastName}`;
          break;
        case ValueTemplate.CONTACT_SHORT:
          let contactShort;
          if (dataField) {
            contactShort = item.contact.find((c) => c._id === dataField);
          } else {
            contactShort = item.contact[0];
          }
          result +=
            contactShort &&
            `${startCase(contactShort.title)} ${contactShort.lastName}`;
          break;
        case ValueTemplate.CONTACT_ADDRESS:
          let contactAddress;
          if (dataField) {
            contactAddress = item.contact.find((c) => c._id === dataField);
          } else {
            contactAddress = item.contact[0];
          }

          result +=
            contactAddress &&
            `${contactAddress.address.address1} ${
              contactAddress.address.address2
                ? `${contactAddress.address.address1}, `
                : ''
            }${
              contactAddress.address.address3
                ? `${contactAddress.address.address3}, `
                : ''
            }${contactAddress.address.postalCode}`;
          break;
        default:
          if (item[field]) {
            result += item[field];
          }
      }
    });
    return result;
  }

  public static getDataFromOptions(
    id: string,
    options: DropdownOption[],
    data?: any,
    field?: string,
  ): string | null {
    if (options) {
      for (const item of options) {
        if (id === item.value) {
          if (field && item.description) {
            const match = item.description.find((desc) => desc.title === field);

            return match && match.data ? match.data : null;
          }
          return item.text;
        }
      }
    }

    return id;
  }

  public static buildLocationSelectOptions(dataSet): DropdownOption[] {
    if (!dataSet) {
      return [];
    }

    let options = [
      {
        key: '-',
        text: 'No location',
        value: '-',
      },
    ];

    return dataSet
      .map((item) => {
        return {
          key: item._id,
          text: startCase(item.location),
          value: item._id,
        };
      })
      .concat(options);
  }

  public static buildCompanySelectOptions(dataSet): DropdownOption[] {
    return dataSet.map((item: Company) => {
      return {
        text: item.name,
        value: item._id,
        key: item._id,
      };
    });
  }

  public static buildUserSelectOptions(
    dataSet,
    description?: DropdownOptionDescription[],
    contact = false,
  ): DropdownOption[] {
    return dataSet.map((item) => {
      let text = '';
      let postcode = '';

      if (contact && Array.isArray(item.contact)) {
        const primary =
          item.contact.find((con) => con.primaryContact) || item.contact[0];

        const firstName = primary.firstName ? primary.firstName + ' ' : '';
        const lastName = primary.lastName;

        if (text.length > 1) {
          text += `, ${firstName}${lastName}`;
        } else {
          text += `${firstName}${lastName}`;
        }

        postcode = `${primary.address.postalCode}`;
      } else {
        const firstName = item.firstName ? item.firstName + ' ' : '';
        const lastName = item.lastName;
        text = `${firstName}${lastName}${item.archived ? ' (Deleted)' : ''}`;
      }
      return {
        text,
        description:
          description &&
          description.map((desc) => {
            return {
              title: desc.title,
              data:
                desc.data === 'postalCode' ? postcode : get(item, desc.data),
            };
          }),
        value: item._id,
        key: item._id,
      };
    });
  }

  public static buildIndustrySelectOptions(dataSet): DropdownOption[] {
    return dataSet.map((item: string) => {
      return {
        text: startCase(item),
        value: item,
        key: item,
      };
    });
  }

  public static systemStatusColourClass(value): string {
    switch (value) {
      case false:
        return 'red';
      case true:
        return 'green';
      default:
        return 'red';
    }
  }

  public static statusColourClass(value): string {
    switch (value.replace(/\s+/g, '-').toLowerCase()) {
      case JobStatus.NOT_STARTED:
      case CompanyStatus.INACTIVE:
        return 'RedCell';
      case JobStatus.IN_PROGRESS:
        return 'YellowCell';
      case JobStatus.COMPLETED_PAID:
      case JobStatus.COMPLETED:
      case JobStatus.SOLD:
      case CompanyStatus.ACTIVE:
      case UserRole.ADMIN:
        return 'GreenCell';
      case JobStatus.COMPLETED_UNPAID:
      case UserRole.USER:
      case 'onsite-user':
        return 'BlueCell';
      case JobStatus.CANCELLED:
      case JobStatus.NOT_COMPLETED:
      case JobStatus.LOST:
      case JobStatus.ON_HOLD:
      case JobStatus.QUOTED:
      case UserRole.SUPER_ADMIN:
        return 'OrangeCell';
      default:
        return 'RedCell';
    }
  }

  public static statusColourColour(value): string {
    switch (value.replace(/\s+/g, '-').toLowerCase() as JobStatus) {
      case JobStatus.NOT_STARTED:
        return 'red';
      case JobStatus.IN_PROGRESS:
        return 'yellow';
      case JobStatus.COMPLETED:
      case JobStatus.COMPLETED_PAID:
      case JobStatus.SOLD:
        return 'green';
      case JobStatus.COMPLETED_UNPAID:
        return 'blue';
      case JobStatus.CANCELLED:
      case JobStatus.NOT_COMPLETED:
      case JobStatus.LOST:
      case JobStatus.ON_HOLD:
      case JobStatus.QUOTED:
        return 'orange';
      default:
        return 'red';
    }
  }

  public static statusColourHex(value: string): string {
    switch (value.replace(/\s+/g, '-').toLowerCase() as JobStatus) {
      case JobStatus.NOT_STARTED:
        return '#e74c3c';
      case JobStatus.IN_PROGRESS:
        return '#f1c40f';
      case JobStatus.COMPLETED:
      case JobStatus.COMPLETED_PAID:
      case JobStatus.SOLD:
        return '#2ecc71';
      case JobStatus.COMPLETED_UNPAID:
        return '#3498db';
      case JobStatus.CANCELLED:
      case JobStatus.NOT_COMPLETED:
      case JobStatus.LOST:
      case JobStatus.ON_HOLD:
      case JobStatus.QUOTED:
        return '#f39c12';
      default:
        return '#e74c3c';
    }
  }

  public static buildEventsFromJobs(
    jobs: Job[],
    customers: Customer[],
    users: User[],
    loggedInCompany: Company,
  ): CalendarEvent[] {
    if (!jobs || !jobs.length) {
      return [];
    }
    const events: CalendarEvent[] = [];

    jobs.map((job) => {
      return JobEnums.JobStages.forEach((stageName) => {
        const stage = job.stages[stageName];
        if (stage?.start) {
          const start: Date = moment(stage.start).toDate();
          const end: Date = moment(stage.due || stage.start).toDate();
          const allDay: boolean = stage.startAllDay;

          const customer = DataHelper.getValueFromData(
            job.customer,
            customers,
            ValueTemplate.CONTACT_SHORT,
            get(job, 'contact.installation'),
          );

          const address = DataHelper.getValueFromData(
            job.customer,
            customers,
            ValueTemplate.CONTACT_ADDRESS,
            get(job, 'contact.installation'),
          );

          const user = DataHelper.getValueFromData(
            stage.assignedTo,
            users,
            ValueTemplate.NAME,
          );

          let title: any = {
            jobId: job.jobId,
            type: job.type,
            customer,
            address,
            user,
            sold: job.stages.pos.status === JobStatus.SOLD,
            balance: job.price.value,
          };

          events.push({
            _id: job._id,
            label: startCase(
              jobStageMap(stageName, loggedInCompany.stageTitles),
            ),
            title: title,
            start,
            end,
            allDay,
            backgroundColor: DataHelper.statusColourColour(stage.status),
          });
        }
      });
    });

    return events;
  }

  public static declarationMap(
    stage: JobStage,
    PDF: boolean = false,
    jobType: JobType,
  ) {
    if (jobType === JobType.COMMERCIAL) {
      if (PDF) {
        return (
          <View>
            <Text>By signing below you confirm:</Text>
            <Text>I agree that this stage of the job is complete.</Text>
          </View>
        );
      }

      return (
        <div>
          <p>By signing below you confirm:</p>
          <p>I agree that this stage of the job is complete.</p>
        </div>
      );
    }

    switch (stage) {
      case JobStage.PRE:
        if (PDF) {
          return (
            <View>
              <Text>By signing below you confirm:</Text>
              <Text>
                I have read the survey/measurement details and confirm these are
                correct.
              </Text>
              <Text>
                I understand that any variation to my initial contract/order may
                change the price and will need to be agreed prior to work
                commencing or the supply of products being provided.
              </Text>
              <Text>
                I understand any dates given for the supply and/or Installation
                of goods are estimates only and may be subject to movement due
                to factors outside of the companies control or at the companies
                discretion.
              </Text>
            </View>
          );
        }

        return (
          <div>
            <p>By signing below you confirm:</p>
            <p>
              I have read the survey/measurement details and confirm these are
              correct.
            </p>
            <p>
              I understand that any variation to my initial contract/order may
              change the price and will need to be agreed prior to work
              commencing or the supply of products being provided.
            </p>
            <p>
              I understand any dates given for the supply and/or Installation of
              goods are estimates only and may be subject to movement due to
              factors outside of the companies control or at the companies
              discretion.
            </p>
          </div>
        );
      case JobStage.POST:
        if (PDF) {
          return (
            <View>
              <Text>By signing below you confirm:</Text>
              <Text>
                You are happy with the works / products / services supplied to
                you in relation to your customer order/contract.
              </Text>
              <Text>
                You are happy with the timescales in which the products and
                services have been provided to you.
              </Text>
              <Text>
                You are satisfied that any variations to your initial
                contract/order have been completed / supplied and that any
                additional price quoted has been agreed
              </Text>
              <Text>
                You understand that in signing you do not waive any legal rights
                or rights given to you under any warranty.
              </Text>
            </View>
          );
        }

        return (
          <div>
            <p>By signing below you confirm:</p>
            <p>
              You are happy with the works / products / services supplied to you
              in relation to your customer order/contract.
            </p>
            <p>
              You are happy with the timescales in which the products and
              services have been provided to you.
            </p>
            <p>
              You are satisfied that any variations to your initial
              contract/order have been completed / supplied and that any
              additional price quoted has been agreed
            </p>
            <p>
              You understand that in signing you do not waive any legal rights
              or rights given to you under any warranty.
            </p>
          </div>
        );
      case JobStage.SERVICE:
        if (PDF) {
          return (
            <View>
              <Text>By signing below you confirm:</Text>
              <Text>
                You are happy with the works / products / services supplied to
                you in relation to your customer guarantee/warranty.
              </Text>
              <Text>
                You are happy with the timescales in which the products and
                services have been provided to you.
              </Text>
            </View>
          );
        }

        return (
          <div>
            <p>By signing below you confirm:</p>
            <p>
              You are happy with the works / products / services supplied to you
              in relation to your customer guarantee/warranty.
            </p>
            <p>
              You are happy with the timescales in which the products and
              services have been provided to you.
            </p>
          </div>
        );
    }
  }
}
