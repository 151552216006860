import {faClipboard, faPhotoVideo} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {startCase} from 'lodash';
import React, {useState} from 'react';
import {Divider, Dropdown} from 'semantic-ui-react';
import {DataType} from '../const/crud';
import {Company} from '../models/Company';
import {Job, JobStage, jobStageMap} from '../models/Job';
import {User} from '../models/User';
import {DataHelper, ValueTemplate} from '../utils/dataHelper';
import MediaHelper from '../utils/mediaHelper';
import {DataActionType, DataTableType, SortDirection} from './Data/DataConst';
import DataTable from './Data/DataTable';

interface LocationStagePickerProps {
  stage: JobStage;
  stageData: any;
  handleDataAction: any;
  selectedJob: Job;
  users: User[];
  loggedInCompany: Company;
}

const LocationStagePicker: React.FunctionComponent<LocationStagePickerProps> = (
  props: LocationStagePickerProps,
) => {
  const options = DataHelper.buildLocationSelectOptions(
    props.selectedJob.locations,
  );

  const [selectedLocation, setSelectedLocation] = useState(
    options.length ? options[0].value : '-',
  );

  return (
    <div className={'LocationPicker'}>
      <Divider horizontal>
        <h3>
          {startCase(
            jobStageMap(props.stage, props.loggedInCompany.stageTitles),
          )}{' '}
          Content
        </h3>
      </Divider>

      {props.stage !== JobStage.POS && (
        <>
          <h3>Select A Location</h3>
          <Dropdown
            placeholder={`Select Location`}
            fluid
            selection
            options={options}
            value={selectedLocation}
            onChange={(e, location) =>
              location.value
                ? setSelectedLocation(location.value.toString())
                : null
            }
          />
        </>
      )}

      <DataTable
        title="Photos/Videos"
        titleIcon={<FontAwesomeIcon icon={faPhotoVideo} />}
        data={props.stageData[props.stage].media.filter(
          (m) =>
            m.location ===
            (props.stage === JobStage.POS ? '-' : selectedLocation),
        )}
        dataType={DataType.MEDIA}
        primaryAction={DataActionType.CREATE_MEDIA}
        handleAction={props.handleDataAction}
        addParams={{
          stage: props.stage,
          location: props.stage === JobStage.POS ? '-' : selectedLocation,
        }}
        pageSize={5}
        sortField={['created']}
        sortDirection={[SortDirection.DESC]}
        actions={[
          {
            title: 'Edit',
            icon: 'pencil',
            className: 'spaceBelow',
            action: DataActionType.EDIT_MEDIA,
            data: {stage: props.stage},
          },
          {
            title: 'Delete',
            icon: 'delete',
            className: 'redButton',
            action: DataActionType.DELETE_MEDIA,
            data: {stage: props.stage},
          },
        ]}
        cols={[
          {
            title: 'Photos/Videos',
            dataField: 'mediaId',
            dataProcess: {
              action: MediaHelper.getMediaUrl,
              options: [],
            },
            type: DataTableType.MEDIA,
            mobileDisplay: true,
            width: 3,
          },
          {
            title: 'Name Of Photo/Video',
            dataField: 'title',
            type: DataTableType.PLAIN,
            mobileDisplay: true,
            width: 5,
          },
          {
            title: 'Display In Reports',
            dataField: 'displayInReports',
            type: DataTableType.STRING,
            mobileDisplay: true,
            width: 3,
          },
          {
            title: 'Created Date',
            dataField: 'created',
            type: DataTableType.DATE_TIME,
            mobileDisplay: true,
            width: 3,
          },
          {
            title: 'Created By',
            dataField: 'createdBy',
            dataProcess: {
              action: DataHelper.getValueFromData,
              data: props.users,
              field: ValueTemplate.NAME,
            },
            type: DataTableType.STRING,
            mobileDisplay: false,
            width: 3,
          },
        ]}
      />
      <DataTable
        title="Notes"
        titleIcon={<FontAwesomeIcon icon={faClipboard} />}
        data={props.stageData[props.stage].notes.filter(
          (n) =>
            n.location ===
            (props.stage === JobStage.POS ? '-' : selectedLocation),
        )}
        dataType={DataType.NOTE}
        primaryAction={DataActionType.CREATE_NOTE}
        handleAction={props.handleDataAction}
        addParams={{
          stage: props.stage,
          location: props.stage === JobStage.POS ? '-' : selectedLocation,
          stageData: props.stageData,
        }}
        pageSize={5}
        sortField={['created']}
        sortDirection={[SortDirection.DESC]}
        actions={[
          {
            title: 'Delete',
            icon: 'delete',
            className: 'redButton',
            action: DataActionType.DELETE_NOTE,
            data: {stage: props.stage},
          },
        ]}
        cols={[
          {
            title: 'Note',
            dataField: 'text',
            type: DataTableType.PLAIN,
            mobileDisplay: true,
            width: 9,
          },
          {
            title: 'Created Date',
            dataField: 'created',
            type: DataTableType.DATE_TIME,
            mobileDisplay: true,
            width: 3,
          },
          {
            title: 'Created By',
            dataField: 'createdBy',
            dataProcess: {
              action: DataHelper.getValueFromData,
              data: props.users,
              field: ValueTemplate.NAME,
            },
            type: DataTableType.STRING,
            mobileDisplay: false,
            width: 3,
          },
        ]}
      />
    </div>
  );
};

export default LocationStagePicker;
