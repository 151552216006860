import {
  faBuilding,
  faClipboard,
  faCogs,
  faLocationArrow,
  faTasks,
  faUsersCog,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import {ModalProps} from 'semantic-ui-react';
import {DataType} from '../../const/crud';
import {customerInitialState} from '../../models/Customer';
import {Job} from '../../models/Job';
import {UserRole} from '../../models/User';
import {DataHelper, ValueTemplate} from '../../utils/dataHelper';
import {
  CRUDType,
  DataActionType,
  DataFormType,
  DataTableType,
  SortDirection,
  ValidationType,
} from '../Data/DataConst';
import DataModal from '../Data/DataModal';
import DataTable from '../Data/DataTable';

interface CustomerModalProps extends ModalProps {
  selectedUserJobs: Job[];
  setSelectedUserJobs: any;
  viewJob: any;
  createCustomerJob: any;
}

const CustomerModal: React.FunctionComponent<CustomerModalProps> = (
  props: CustomerModalProps,
) => {
  const handleDataAction = async (
    action: DataActionType,
    dataId: string,
    data?: any,
  ) => {
    await props.actionHelper.handleDataAction(
      DataType.CUSTOMER,
      (state) => props.setSelected(state),
      action,
      props.selected._id,
      dataId,
      action === DataActionType.EDIT_CONTACT ? props.selected : data || null,
    );
  };

  const resetDataForm = () => {
    // Reset search options
    props.setSearchOptions({});

    // Set company
    customerInitialState.company = props.loggedInUser.company;

    // Reset jobs
    props.setSelectedUserJobs([]);

    props.setSelected(customerInitialState);
    props.setDataForm(false);
  };

  return (
    <DataModal
      showDataForm={props.showDataForm}
      onClose={() => {
        resetDataForm();
        props.resetDataType();
      }}
      searchAction={props.searchAction}
      crudAction={async (
        action: CRUDType,
        dataType: DataType,
        data: any,
        completeAction: boolean = true,
        clearFilter: boolean = false,
      ) => {
        if (action === CRUDType.DELETE) {
          props.actionHelper.handleAction(dataType, null, action, data._id);
        } else {
          const result = await props.crudAction(
            data._id?.length ? CRUDType.UPDATE : action,
            dataType,
            data,
            completeAction,
            clearFilter,
          );
          props.setSelected(result.data);
        }

        props.resetDataType();
        resetDataForm();
      }}
      title={`${
        props.selected.firstName ? props.selected.firstName + ' ' : ''
      }${props.selected.lastName}`}
      data={{
        selected: props.selected,
        selectedUserJobs: props.selectedUserJobs,
        loggedInCompany: props.loggedInCompany,
        loggedInUser: props.loggedInUser,
      }}
      dataType={DataType.CUSTOMER}
      sections={[
        {
          title: 'Company Details',
          titleIcon: <FontAwesomeIcon icon={faBuilding} />,
          hideSection: props.loggedInUser.role !== UserRole.SUPER_ADMIN,
          fields: [
            {
              title: 'Company',
              dataField: 'company',
              dataSet:
                props.searchOptions[DataType.COMPANY] &&
                props.searchOptions[DataType.COMPANY].length
                  ? props.searchOptions[DataType.COMPANY]
                  : props.companyOptions,
              dataType: DataType.COMPANY,
              type: DataFormType.SEARCH_SELECT_API,
              validation: [ValidationType.REQUIRED],
            },
          ],
        },
        {
          title: 'Xero Integration',
          titleIcon: <FontAwesomeIcon icon={faCogs} />,
          hideSection: !props.loggedInCompany.integrations?.xero?.access_token,
          fields: [
            {
              title: 'Search Xero Customers',
              dataField: 'xeroContactId',
              dataType: DataType.COMPANY,
              type: DataFormType.XERO_CUSTOMER_SEARCH,
              optional: true,
              dataSet: {
                handleDataAction: handleDataAction,
              },
            },
          ],
        },
        {
          title: 'Quickbooks Integration',
          titleIcon: <FontAwesomeIcon icon={faCogs} />,
          hideSection: !props.loggedInCompany.integrations?.quickbooks
            ?.access_token,
          fields: [
            {
              title: 'Search Quickbooks Customers',
              dataField: 'quickbooksContactId',
              dataType: DataType.COMPANY,
              type: DataFormType.QUICKBOOKS_CUSTOMER_SEARCH,
              optional: true,
              dataSet: {
                handleDataAction: handleDataAction,
              },
            },
          ],
        },
        {
          title: 'Customer Details',
          titleIcon: <FontAwesomeIcon icon={faUsersCog} />,
          fields: [
            {
              className: 'halfWidth',
              title: 'Company Name',
              dataField: 'companyName',
              type: DataFormType.TEXT,
              optional: true,
            },
            {
              className: 'halfWidth',
              title: 'Customer Account Number',
              dataField: 'account',
              type: DataFormType.TEXT,
              optional: true,
            },
          ],
        },
        {
          fields: [
            {
              validation: [ValidationType.REQUIRED],
              dataField: 'contact',
              type: DataFormType.TABLE,
              content: (
                <DataTable
                  title="Customer Contact & Address"
                  titleIcon={<FontAwesomeIcon icon={faLocationArrow} />}
                  description={
                    <p>
                      Add customer contact details here, such as billing address
                      & installation addresses.{' '}
                      <strong>At least one contact must be added.</strong>
                    </p>
                  }
                  data={props.selected.contact}
                  dataType={DataType.CONTACT}
                  primaryAction={DataActionType.CREATE_CONTACT}
                  handleAction={handleDataAction}
                  pageSize={5}
                  sortField={['created']}
                  sortDirection={[SortDirection.DESC]}
                  actions={[
                    {
                      title: 'Edit',
                      icon: 'pencil',
                      className: 'spaceBelow',
                      action: DataActionType.EDIT_CONTACT,
                    },
                    {
                      title: 'Delete',
                      icon: 'delete',
                      className: 'redButton',
                      action: DataActionType.DELETE_CONTACT,
                    },
                  ]}
                  cols={[
                    {
                      title: 'Primary',
                      dataField: 'primaryContact',
                      type: DataTableType.STRING,
                      mobileDisplay: true,
                      width: 1,
                    },
                    {
                      title: 'First Name',
                      dataField: 'firstName',
                      type: DataTableType.STRING,
                      mobileDisplay: true,
                      width: 3,
                    },
                    {
                      title: 'Last Name',
                      dataField: 'lastName',
                      type: DataTableType.STRING,
                      mobileDisplay: true,
                      width: 3,
                    },
                    {
                      title: 'Address 1',
                      dataField: 'address.address1',
                      type: DataTableType.PLAIN,
                      mobileDisplay: true,
                      width: 3,
                    },
                    {
                      title: 'Town/City',
                      dataField: 'address.address3',
                      type: DataTableType.PLAIN,
                      mobileDisplay: true,
                      width: 3,
                    },
                    {
                      title: 'Postcode',
                      dataField: 'address.postalCode',
                      type: DataTableType.POSTAL_CODE,
                      mobileDisplay: true,
                      width: 3,
                    },
                  ]}
                />
              ),
            },
          ],
        },
        {
          fields: [
            {
              dataField: 'notes',
              type: DataFormType.TABLE,
              content: (
                <DataTable
                  title="Notes"
                  titleIcon={<FontAwesomeIcon icon={faClipboard} />}
                  description={
                    'Add any important customer notes, site ' +
                    'specific information or other details here.'
                  }
                  data={props.selected.notes}
                  dataType={DataType.NOTE}
                  primaryAction={DataActionType.CREATE_NOTE}
                  handleAction={handleDataAction}
                  pageSize={5}
                  sortField={['created']}
                  sortDirection={[SortDirection.DESC]}
                  actions={[
                    {
                      title: 'Delete',
                      icon: 'delete',
                      className: 'redButton',
                      action: DataActionType.DELETE_NOTE,
                    },
                  ]}
                  cols={[
                    {
                      title: 'Note',
                      dataField: 'text',
                      type: DataTableType.PLAIN,
                      mobileDisplay: true,
                      width: 9,
                    },
                    {
                      title: 'Created Date',
                      dataField: 'created',
                      type: DataTableType.DATE_TIME,
                      mobileDisplay: true,
                      width: 3,
                    },
                    {
                      title: 'Created By',
                      dataField: 'createdBy',
                      dataProcess: {
                        action: DataHelper.getValueFromData,
                        data: props.users,
                        field: ValueTemplate.NAME,
                      },
                      type: DataTableType.STRING,
                      mobileDisplay: false,
                      width: 3,
                    },
                  ]}
                />
              ),
            },
          ],
        },
        {
          fields: [
            {
              dataField: '',
              type: DataFormType.TABLE,
              content: (
                <DataTable
                  title="Jobs"
                  titleIcon={<FontAwesomeIcon icon={faTasks} />}
                  className={'StyledDataTable'}
                  data={props.selectedUserJobs}
                  dataType={DataType.JOB}
                  clickAction={CRUDType.UPDATE}
                  primaryAction={
                    props.selected._id
                      ? DataActionType.CREATE_CUSTOMER_JOB
                      : undefined
                  }
                  handleAction={(action, documentId) =>
                    action === CRUDType.UPDATE
                      ? props.viewJob(CRUDType.UPDATE, documentId)
                      : props.createCustomerJob(props.selected._id)
                  }
                  pageSize={5}
                  sortField={['created']}
                  sortDirection={[SortDirection.DESC]}
                  cols={[
                    {
                      title: 'Job ID',
                      dataField: 'jobId',
                      type: DataTableType.JOB_PREFIX_ID,
                      mobileDisplay: true,
                    },
                    {
                      title: 'Customer',
                      dataField: 'customer',
                      dataProcess: {
                        action: DataHelper.getValueFromData,
                        data: props.customers,
                        field: ValueTemplate.CONTACT,
                      },
                      type: DataTableType.STRING,
                      mobileDisplay: false,
                    },
                    {
                      title: 'Company',
                      dataField: 'customer',
                      dataProcess: {
                        action: DataHelper.getValueFromData,
                        data: props.customers,
                        field: 'companyName',
                      },
                      type: DataTableType.STRING,
                      mobileDisplay: false,
                    },
                    {
                      title: 'Sales Status',
                      dataField: 'stages.pos.status',
                      type: DataTableType.STRING,
                      mobileDisplay: true,
                      className: (value) => DataHelper.statusColourClass(value),
                    },
                    {
                      title: 'Survey Status',
                      dataField: 'stages.pre.status',
                      type: DataTableType.STRING,
                      mobileDisplay: true,
                      className: (value) => DataHelper.statusColourClass(value),
                    },
                    {
                      title: 'Installation Status',
                      dataField: 'stages.post.status',
                      type: DataTableType.STRING,
                      mobileDisplay: true,
                      className: (value) => DataHelper.statusColourClass(value),
                    },
                    {
                      title: 'Service Status',
                      dataField: 'stages.service.status',
                      type: DataTableType.STRING,
                      mobileDisplay: true,
                      className: (value) => DataHelper.statusColourClass(value),
                    },
                  ]}
                />
              ),
            },
          ],
        },
      ]}
    />
  );
};

export default CustomerModal;
