import {
  BlobProvider,
  Document,
  Font,
  Image,
  Link,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import {cloneDeep, startCase} from 'lodash';
import moment from 'moment';
import React, {useState} from 'react';
import {Button} from 'semantic-ui-react';
import {DataType} from '../const/crud';
import {Company, CompanyThemeItemType} from '../models/Company';
import {Customer} from '../models/Customer';
import {Job, JobStage, jobStageMap, JobType} from '../models/Job';
import {User} from '../models/User';
import {DataHelper} from '../utils/dataHelper';
import MediaHelper from '../utils/mediaHelper';
import {CRUDType} from './Data/DataConst';
import {Rating} from './SignatureView';
interface PdfBuilderProps {
  buttonTitle: string;
  buttonTitleView: string;
  data: any;
  stage: JobStage;
  loggedInUser: string;
  loggedInCompany: Company;
  action: any;
  customer: Customer;
  contact: any;
  users: User[];
  company: Company;
  onChange: any;
}

const ratingMap = (rating: number): Rating => {
  switch (rating) {
    case 1:
      return Rating.POOR;
    case 2:
      return Rating.OKAY;
    case 3:
      return Rating.GOOD;
    case 4:
      return Rating.VERY_GOOD;
    case 5:
      return Rating.EXCELLENT;
    default:
      return Rating.NO_RATING;
  }
};

const booleanToStringMap = (boolean: boolean = false) => {
  if (boolean) {
    return 'yes';
  } else {
    return 'no';
  }
};

const latoReg = require('../assets/Lato-Regular.ttf').default;
const latoBold = require('../assets/Lato-Bold.ttf').default;

Font.register({
  family: 'Lato',
  src: '',
  fonts: [
    {
      src: latoReg,
      fontWeight: 'normal',
    },
    {
      src: latoBold,
      fontWeight: 'bold',
    },
  ],
});

const createFilename = (stage: JobStage, customerId: string, stageTitles) =>
  `${jobStageMap(
    stage,
    stageTitles,
  ).toLowerCase()}-completion-report-${customerId}-${moment().format(
    'DD-MM-YYYY-HH-mm-ss',
  )}.pdf`;

const styles = StyleSheet.create({
  logo: {
    backgroundColor: '#ffffff',
    marginVertical: 15,
    marginHorizontal: 150,
  },
  page: {
    flexDirection: 'column',
    paddingVertical: 20,
    paddingHorizontal: 40,
  },
  section: {
    flexDirection: 'column',
    marginVertical: 5,
  },
  fullWidth: {
    justifyContent: 'space-between',
  },
  row: {
    flexDirection: 'row',
  },
  boxed: {
    padding: 10,
    borderColor: '#dfe4ea',
    borderWidth: 1,
    borderRadius: 5,
  },
  block: {
    marginRight: 20,
  },
  quarter: {
    width: '24.25%',
    marginRight: 10,
  },
  quarterLast: {
    width: '24.25%',
  },
  contact: {
    width: '100%',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginTop: 10,
  },
  halfFirst: {
    width: '49%',
    marginRight: 10,
  },
  halfSecond: {
    width: '49%',
  },
  text: {
    color: '#000000',
    fontFamily: 'Lato',
    fontSize: 8,
    fontWeight: 'normal',
    lineHeight: 1,
  },
  center: {
    textAlign: 'center',
  },
  header1: {
    color: '#233592',
    fontWeight: 'bold',
    fontSize: 16,
    marginBottom: 10,
  },
  header2: {
    color: '#233592',
    fontWeight: 'bold',
    fontSize: 10,
  },
  header3: {
    color: '#233592',
    fontWeight: 'bold',
    fontSize: 8,
    marginBottom: 5,
  },
  checklistHeader: {
    color: '#233592',
    fontWeight: 'bold',
    fontSize: 8,
  },
  link: {
    color: '#20abe2',
  },
  marginTop: {
    marginTop: 10,
  },
  smallFont: {
    fontSize: 7,
  },
  signature: {
    backgroundColor: '#ffffff',
    borderColor: '#dfe4ea',
    borderWidth: 1,
    borderRadius: 5,

    width: 210,
    height: 70,
  },
  media: {
    backgroundColor: '#ffffff',
    borderColor: '#dfe4ea',
    borderWidth: 1,
    borderRadius: 5,

    minHeight: 100,
    maxHeight: 250,
    width: '100%',
  },
  mediaWrapper: {
    width: '45%',
    marginBottom: 10,
  },
});

const listSignatures = (stageData, stage, users, loggedInCompany, jobType) => {
  return stageData.signature.map((signature, index) => {
    const user = users.find((u) => u._id === signature.createdBy);

    return (
      <View
        key={`signature-${index}`}
        style={
          stageData.signature.length > 0
            ? [styles.boxed, {paddingVertical: 0, marginTop: 10}]
            : []
        }
      >
        <View style={[styles.section, styles.row]}>
          <View style={styles.quarter}>
            <Text style={styles.header3}>Completion Status</Text>
            <Text style={styles.text}>{startCase(signature.status)}</Text>
          </View>

          {signature && typeof signature.customerPresent !== 'undefined' ? (
            <View style={styles.quarter}>
              <Text style={styles.header3}>Is Customer Present</Text>
              <Text style={styles.text}>
                {signature
                  ? startCase(booleanToStringMap(signature.customerPresent))
                  : null}
              </Text>
            </View>
          ) : null}

          {signature && typeof signature.signatureObtained !== 'undefined' ? (
            <View style={styles.quarter}>
              <Text style={styles.header3}>
                Agrees{' '}
                {startCase(jobStageMap(stage, loggedInCompany.stageTitles))} Is
                Complete
              </Text>
              <Text style={styles.text}>
                {signature
                  ? startCase(booleanToStringMap(signature.signatureObtained))
                  : null}
              </Text>
            </View>
          ) : null}
          {signature && typeof signature.signatureObtained !== 'undefined' ? (
            <View style={styles.quarterLast}>
              <Text style={styles.header3}>Customer Satisfaction Rating</Text>
              <Text style={styles.text}>
                {signature && signature.rating
                  ? `${signature.rating}/ 5 - ${startCase(
                      ratingMap(signature.rating || 0),
                    )}`
                  : 'N/A'}
              </Text>
            </View>
          ) : null}
        </View>

        {signature?.comments ? (
          <View style={[styles.section]}>
            <Text style={styles.header3}>Comments</Text>
            <Text style={styles.text}>{signature.comments}</Text>
          </View>
        ) : null}

        <View style={[styles.section, styles.row]}>
          {signature && signature.name ? (
            <View style={styles.quarter}>
              <Text style={styles.header3}>Full Name Of Signatory</Text>
              <Text style={styles.text}>{signature && signature.name}</Text>
            </View>
          ) : null}

          <View style={styles.quarter}>
            <Text style={styles.header3}>Created Date</Text>
            <Text style={styles.text}>
              {moment(signature && signature.created).format(
                'DD/MM/YYYY HH:mm',
              )}
            </Text>
          </View>
          <View style={styles.quarterLast}>
            <Text style={styles.header3}>Created By</Text>
            <Text style={styles.text}>
              {user ? `${user.firstName} ${user.lastName}` : 'User'}
            </Text>
          </View>
        </View>

        {signature && signature.media ? (
          <View style={[styles.section, styles.row]}>
            <View>
              <Text style={styles.header3}>
                {jobType === JobType.COMMERCIAL ? 'Onsite User' : 'Customer'}{' '}
                Signature
              </Text>
              <Text style={[styles.text, styles.smallFont, {marginBottom: 10}]}>
                {DataHelper.declarationMap(stage, true, jobType)}
              </Text>
              <Image
                style={styles.signature}
                src={MediaHelper.getMediaUrl(signature.media.mediaId)}
              />
            </View>
          </View>
        ) : null}
      </View>
    );
  });
};

const buildPdf = (
  jobData: Job,
  customer: Customer,
  contact: any,
  users: User[],
  stage: JobStage,
  company: Company,
  loggedInCompany: Company,
) => {
  const stageData = jobData.stages[stage];

  const logo = company.theme.find((t) => t.type === CompanyThemeItemType.LOGO);

  return (
    <Document
      title={`${jobData.jobPrefix ? `${jobData.jobPrefix}-` : ''}${
        jobData.jobId ? `${jobData.jobId} - ` : ''
      }${startCase(
        jobStageMap(stage, loggedInCompany.stageTitles),
      )} Completion Report`}
    >
      <Page size={'a4'} style={styles.page}>
        {logo && (
          <Image
            style={[styles.logo]}
            src={MediaHelper.getMediaUrl(logo.mediaId)}
          />
        )}

        <View style={styles.section}>
          <Text style={[styles.header1, styles.center]}>{`${startCase(
            jobStageMap(stage, loggedInCompany.stageTitles),
          )} Completion Report`}</Text>
        </View>

        <View style={[styles.section, styles.row]}>
          {/*Company Details*/}
          <View style={[styles.boxed, styles.halfFirst]}>
            <Text style={styles.header2}>Company Details</Text>
            <View style={{marginTop: 10}}>
              {company.name ? (
                <Text style={styles.text}>{company.name}</Text>
              ) : null}
              <Text style={styles.text}>
                {company.contact && company.contact.address.address1}
              </Text>
              {company.contact && company.contact.address.address2 ? (
                <Text style={styles.text}>
                  {company.contact.address.address2}
                </Text>
              ) : null}
              {company.contact && company.contact.address.address3 ? (
                <Text style={styles.text}>
                  {company.contact.address.address3}
                </Text>
              ) : null}
              <Text style={styles.text}>
                {company.contact && company.contact.address.postalCode}
              </Text>
              <Text style={styles.text}>
                {company.contact && company.contact.phone}
              </Text>
              <Link
                src={`mailto:${company.contact && company.contact.email}`}
                style={[styles.link, styles.marginTop]}
              >
                <Text style={styles.text}>
                  {company.contact && company.contact.email}
                </Text>
              </Link>
            </View>
          </View>

          {/*Customer Details*/}
          <View style={[styles.boxed, styles.halfSecond]}>
            <Text style={styles.header2}>Customer Details</Text>
            <View style={{marginTop: 10}}>
              {customer.companyName ? (
                <Text style={styles.text}>{customer.companyName}</Text>
              ) : null}
              {customer.account ? (
                <Text style={styles.text}>{customer.account}</Text>
              ) : null}

              <View style={styles.contact}>
                <View style={[styles.halfFirst]}>
                  <Text style={styles.header3}>Billing Address</Text>
                  <Text style={styles.text}>
                    {startCase(contact.billing.title)}{' '}
                    {contact.billing.firstName
                      ? contact.billing.firstName + ' '
                      : ''}
                    {contact.billing.lastName}
                  </Text>
                  <Text style={styles.text}>
                    {contact.billing.address.address1}
                  </Text>
                  {contact.billing.address.address2 ? (
                    <Text style={styles.text}>
                      {contact.billing.address.address2}
                    </Text>
                  ) : null}
                  {contact.billing.address.address3 ? (
                    <Text style={styles.text}>
                      {contact.billing.address.address3}
                    </Text>
                  ) : null}
                  <Text style={styles.text}>
                    {contact.billing.address.postalCode}
                  </Text>
                  <Text style={styles.text}>{contact.billing.phone}</Text>
                  {contact.billing.email ? (
                    <Link
                      src={`mailto:${contact.billing.email}`}
                      style={[styles.link, styles.marginTop]}
                    >
                      <Text style={styles.text}>{contact.billing.email}</Text>
                    </Link>
                  ) : null}
                </View>

                <View style={styles.halfSecond}>
                  <Text style={styles.header3}>Installation Address</Text>
                  <Text style={styles.text}>
                    {startCase(contact.installation.title)}{' '}
                    {contact.installation.firstName
                      ? contact.installation.firstName + ' '
                      : ''}
                    {contact.installation.lastName}
                  </Text>
                  <Text style={styles.text}>
                    {contact.installation.address.address1}
                  </Text>
                  {contact.installation.address.address2 ? (
                    <Text style={styles.text}>
                      {contact.installation.address.address2}
                    </Text>
                  ) : null}
                  {contact.installation.address.address3 ? (
                    <Text style={styles.text}>
                      {contact.installation.address.address3}
                    </Text>
                  ) : null}
                  <Text style={styles.text}>
                    {contact.installation.address.postalCode}
                  </Text>
                  <Text style={styles.text}>{contact.installation.phone}</Text>
                  {contact.installation.email ? (
                    <Link
                      src={`mailto:${contact.installation.email}`}
                      style={[styles.link, styles.marginTop]}
                    >
                      <Text style={styles.text}>
                        {contact.installation.email}
                      </Text>
                    </Link>
                  ) : null}
                </View>
              </View>
            </View>
          </View>
        </View>

        <View style={[styles.section, styles.boxed]}>
          <Text style={styles.header2}>Job Details</Text>

          <View style={[styles.section, styles.row]}>
            <View style={styles.block}>
              <Text style={styles.header3}>Job ID</Text>
              <Text style={styles.text}>{`${
                jobData.jobPrefix ? `${jobData.jobPrefix}-` : ''
              }${jobData.jobId || jobData._id}`}</Text>
            </View>

            {stageData && stageData.start ? (
              <View style={styles.block}>
                <Text style={styles.header3}>Job Date</Text>
                <Text style={styles.text}>
                  {stageData.start
                    ? moment(stageData.start).format('DD/MM/YYYY')
                    : null}
                </Text>
              </View>
            ) : null}

            <View>
              <Text style={styles.header3}>Job Price</Text>
              <Text style={styles.text}>£{jobData.price.value}</Text>
            </View>
          </View>

          {jobData.description ? (
            <View style={[styles.section, styles.row]}>
              <View>
                <Text style={styles.header3}>Job Description</Text>
                <Text style={styles.text}>{jobData.description}</Text>
              </View>
            </View>
          ) : null}
        </View>

        {[JobStage.PRE, JobStage.POST].includes(stage) ? (
          <View style={[styles.section, styles.boxed]}>
            <Text style={styles.header2}>Detail Of Changes Or Delays</Text>
            <View style={[styles.section, styles.row]}>
              <View style={styles.halfFirst}>
                <Text style={styles.header3}>
                  Changes to initial customer contract/order?
                </Text>
                <Text style={styles.text}>
                  {startCase(
                    booleanToStringMap(
                      stageData.additional &&
                        stageData.additional.contractChanges,
                    ),
                  )}
                </Text>
              </View>
              {stageData.additional && stageData.additional.contractChanges ? (
                <View style={styles.halfSecond}>
                  <Text style={styles.header3}>
                    Who suggested change & provide details of change
                  </Text>
                  <Text style={styles.text}>
                    {stageData.additional.contractChangeDetails}
                  </Text>
                </View>
              ) : null}
            </View>

            {stage === JobStage.POST ? (
              <View style={[styles.section, styles.row]}>
                <View style={styles.halfFirst}>
                  <Text style={styles.header3}>
                    Have there been delays on this job?
                  </Text>
                  <Text style={styles.text}>
                    {startCase(
                      booleanToStringMap(
                        stageData.additional && stageData.additional.delays,
                      ),
                    )}
                  </Text>
                </View>
                {stageData.additional && stageData.additional.delays ? (
                  <View style={styles.halfSecond}>
                    <Text style={styles.header3}>Provide reasons why</Text>
                    <Text style={styles.text}>
                      {stageData.additional.delayDetails}
                    </Text>
                  </View>
                ) : null}
              </View>
            ) : null}
          </View>
        ) : null}

        {jobData.customFields && jobData.customFields.length ? (
          <View style={[styles.section, styles.boxed]}>
            <Text style={styles.header2}>Custom Fields</Text>
            {jobData.customFields.map((item, index: number) => {
              return (
                <View
                  style={[styles.section, styles.row]}
                  key={`custom-field-${index}`}
                >
                  <View>
                    <Text style={styles.checklistHeader}>
                      {item.field}:{' '}
                      <Text style={styles.text}>{startCase(item.value)}</Text>
                    </Text>
                  </View>
                </View>
              );
            })}
          </View>
        ) : null}

        {stageData.checklist && stageData.checklist.length ? (
          <View style={[styles.section, styles.boxed]}>
            <Text style={styles.header2}>
              {startCase(jobStageMap(stage, loggedInCompany.stageTitles))}{' '}
              Checklist
            </Text>
            {stageData.checklist.map((item, index: number) => {
              return (
                <View
                  style={[styles.section, styles.row]}
                  key={`checklist-${index}`}
                >
                  <View>
                    <Text style={styles.checklistHeader}>
                      {item.text}:{' '}
                      <Text style={styles.text}>{startCase(item.status)}</Text>
                    </Text>
                  </View>
                </View>
              );
            })}
          </View>
        ) : null}

        <View style={[styles.section, styles.boxed]}>
          <Text style={styles.header2}>
            {startCase(jobStageMap(stage, loggedInCompany.stageTitles))}{' '}
            Completion
          </Text>

          {listSignatures(
            stageData,
            stage,
            users,
            loggedInCompany,
            jobData.jobType,
          )}
        </View>

        {/*Photos*/}
        {stageData.media &&
        stageData.media.filter(
          (m) =>
            (m as Record<string, unknown>).mediaType === 'image' &&
            (m as Record<string, unknown>).displayInReports !== false &&
            (m as Record<string, unknown>).displayInReports !== 'false',
        ).length ? (
          <View style={[styles.section, styles.boxed]}>
            <Text style={[styles.header2, {marginBottom: 10}]}>
              {startCase(jobStageMap(stage, loggedInCompany.stageTitles))} Media
            </Text>

            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
              }}
            >
              {stageData.media
                .filter(
                  (m) =>
                    (m as Record<string, unknown>).mediaType === 'image' &&
                    (m as Record<string, unknown>).displayInReports !== false &&
                    (m as Record<string, unknown>).displayInReports !== 'false',
                )
                .map((media: Record<string, unknown>) => (
                  <View style={styles.mediaWrapper}>
                    <Image
                      style={styles.media}
                      src={MediaHelper.getMediaUrl(media.mediaId as string)}
                    />
                    <Text
                      style={[styles.text, styles.smallFont, {marginTop: 5}]}
                    >
                      {media.title || ''}{' '}
                      {media.location && media.location !== '-'
                        ? `- ${media.location}`
                        : ''}{' '}
                      {moment(media.created).format('DD/MM/yyyy hh:mm')}
                    </Text>
                  </View>
                ))}
            </View>
          </View>
        ) : null}
      </Page>
    </Document>
  );
};

const PdfBuilder: React.FunctionComponent<PdfBuilderProps> = (
  props: PdfBuilderProps,
) => {
  const [loadingPdf, setLoadingPdf] = useState(true);

  if (!props.data) {
    return null;
  }

  const stage = props.data.stages[props.stage];

  const uploadAttachment = async (blob) => {
    const result = await props.action(
      CRUDType.UPLOAD_MEDIA,
      DataType.ATTACHMENT,
      {
        body: {
          created: new Date(),
          createdBy: props.loggedInUser,
          title: createFilename(
            props.stage,
            props.data.customer,
            props.loggedInCompany,
          ),
        },
        blob,
      },
      false,
    );

    const clonedData = cloneDeep(props.data);

    // Mark as report generated
    clonedData.stages[props.stage].report = result.data._id;

    props.onChange(clonedData);
  };

  return (
    <div className={'PdfBuilderContainer'}>
      {stage.report ? (
        <Button
          primary
          as={'a'}
          href={MediaHelper.getMediaUrl(stage.report)}
          target={'_blank'}
        >
          {props.buttonTitleView}
        </Button>
      ) : null}

      <Button
        loading={!loadingPdf && !stage.report}
        secondary={!!(stage.report && stage.report.length)}
        onClick={async () => {
          setLoadingPdf(false);
        }}
      >
        {props.buttonTitle}
      </Button>

      {!loadingPdf ? (
        <BlobProvider
          document={buildPdf(
            props.data,
            props.customer,
            props.contact,
            props.users,
            props.stage,
            props.company,
            props.loggedInCompany,
          )}
        >
          {({blob}) => {
            if (blob) {
              uploadAttachment(blob);
              setLoadingPdf(true);
            }
            return null;
          }}
        </BlobProvider>
      ) : null}
    </div>
  );
};

export default PdfBuilder;
