import moment from 'moment';
import * as React from 'react';
import {useState} from 'react';
import {CRUDType} from '../components/Data/DataConst';
import NavBar from '../components/NavBar';
import versioning from '../config/versioning';
import {Company} from '../models/Company';
import {User} from '../models/User';
import {DataHelper} from '../utils/dataHelper';

export interface PageProps {
  authToken?;
  pageTitle: string;
  loggedInUser: User;
  loggedInCompany: Company;
  isHome: boolean;
  crudAction?: any;
  showDataForm: {
    status: boolean;
    formType: CRUDType;
  };
  setDataForm: any;
  helpers;
}

const Page: React.FunctionComponent<PageProps> = (props) => {
  const [liveUpdateStatus, setLiveUpdateStatus] = useState(false);
  if (props.helpers && props.helpers.socket) {
    props.helpers.socket.addLiveUpdateSetter(setLiveUpdateStatus);
  }

  return (
    <main className={'MainContainer'}>
      <NavBar loggedInUser={props.loggedInUser} loggedInCompany={props.loggedInCompany} />
      <div className="Page">{props.children}</div>
      <footer>
        <div className={'FooterVersion'}>
          <p>
            v{versioning.version} - Live update status:
            <span
              className={`FooterVersionStatusIndicator ${DataHelper.systemStatusColourClass(
                liveUpdateStatus,
              )}`}
            />
            {liveUpdateStatus ? 'Connected' : 'Not Connected'}
          </p>
        </div>
        <div className={'FooterContent'}>
          ©{moment().format('YYYY')} Onsite 7 -{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://onsite7.co.uk/privacy-policy/"
          >
            Privacy
          </a>
        </div>
      </footer>
    </main>
  );
};

export default Page;
