import {cloneDeep, get} from 'lodash';
import {Field, Section, ValidationType} from './DataConst';

export interface ValidateFSectionsResult {
  status: boolean;
  sections: Section[];
}

export function validateSectionsOnSubmit(
  sections: Section[],
  data: any[],
): ValidateFSectionsResult {
  const clonedSections = cloneDeep(sections);
  const result: ValidateFSectionsResult = {
    status: true,
    sections: clonedSections,
  };

  for (const section of clonedSections) {
    for (const field of section.fields) {
      if (field.validation) {
        for (const valid of field.validation) {
          if (!validateField(get(data, field.dataField), valid, data)) {
            result.status = false;
            field.error = true;
            break;
          }
        }
      }
    }
  }

  return result;
}

export function validateSectionFieldsOnChange(
  newVal: string,
  field: Field,
  section: Section,
  fullDataSet: any,
): Section {
  if (field.validation) {
    let isError: boolean = false;

    for (const vType of field.validation) {
      if (!validateField(newVal, vType, fullDataSet)) {
        isError = true;
      }
    }

    for (let f of section.fields) {
      if (f.title === field.title) {
        field.error = isError;
        f = field;
        break;
      }
    }
  }

  return section;
}

export function validateField(
  value: string,
  validationType: ValidationType,
  fullDataSet?: any,
): boolean {
  let pass = false;
  switch (validationType) {
    case ValidationType.REQUIRED:
      if (value && value.length) {
        pass = true;
      } else if (value && typeof value === 'number') {
        pass = true;
      } else if (typeof value === 'boolean') {
        pass = true;
      }

      break;
    case ValidationType.STAGE_ASSIGNED:
      if (fullDataSet) {
        const stage = fullDataSet.stage;
        if (stage && fullDataSet.stages[stage].assignedTo.length) {
          pass = true;
        }
      }
      break;
  }

  return pass;
}
