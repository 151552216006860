import {
  faCheckCircle,
  faDownload,
  faEye,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import axios from 'axios';
import {cloneDeep, get, orderBy, startCase} from 'lodash';
import moment from 'moment';
import React, {useCallback, useEffect, useState} from 'react';
import {Calendar, momentLocalizer} from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import {PieChart} from 'react-minimal-pie-chart';
import Moment from 'react-moment';
import VideoThumbnail from 'react-video-thumbnail';
import {Button, Dropdown, Icon, Pagination, Table} from 'semantic-ui-react';
import {SemanticWIDTHS} from 'semantic-ui-react/dist/commonjs/generic';
import config from '../../config/config';
import {DataType} from '../../const/crud';
import {Customer} from '../../models/Customer';
import {
  jobDefaultStageTitleMap,
  JobStage,
  jobStageMap,
  PricingItemStatus,
} from '../../models/Job';
import {MediaType} from '../../models/Media';
import {useAuth0} from '../../react-auth0-spa';
import {DataHelper} from '../../utils/dataHelper';
import JobExportData from '../../utils/exporter';
import MediaHelper from '../../utils/mediaHelper';
import InvoicePdfBuilder from '../InvoicePdfBuilder';
import {buildStarRating} from '../SignatureView';
import {
  CalendarEvent,
  Column,
  DataTableProps,
  DataTableType,
} from './DataConst';

export enum SortOrder {
  NEWEST_FIRST = 'newest-first',
  OLDEST_FIRST = 'oldest-first',
}

enum ViewType {
  TABLE = 'table',
  CALENDAR = 'calendar',
  SUMMARY = 'summary',
}

const localizer = momentLocalizer(moment);
const DragAndDropCalendar = withDragAndDrop(Calendar);

const DataTable: React.FunctionComponent<DataTableProps> = (
  props: DataTableProps,
) => {
  const {getTokenSilently} = useAuth0();

  const [sortedData, setSortedData] = useState<any>();
  const [pageData, setPageData] = useState<any>();
  const [activePage, setActivePage] = useState(1);
  const [viewType, setViewType] = useState<ViewType>(ViewType.TABLE);
  const [exporting, setExporting] = useState<boolean>(false);
  const [calendarView, setCalendarView] = useState<string>();

  // Handle pagination change
  const handlePaginationChange = (activePage, activeSortOrder?) => {
    const page: number =
      typeof activePage === 'string' ? parseInt(activePage) : activePage;

    // If API get data
    if (props.paginationAction) {
      props.paginationAction(page, false, activeSortOrder);
    }

    setActivePage(page);

    setPageData(filterPaginationData(page));
  };

  const filterPaginationData = useCallback(
    (page) => {
      if (props.paginationAction) {
        return sortedData;
      }

      if (!sortedData) {
        return [];
      }

      return (
        sortedData &&
        sortedData.slice(
          (page - 1) * (props.pageSize || 10),
          page * (props.pageSize || 10),
        )
      );
    },
    [props.paginationAction, props.pageSize, sortedData],
  );

  const pPaginationAction = props.paginationAction;
  useEffect(() => {
    if (props.data) {
      if (activePage > 1 && !props.data.length) {
        setActivePage(1);
        pPaginationAction(activePage - 1);
      } else {
        const sortedData = props.sortField
          ? orderBy(props.data, props.sortField, [props.sortDirection || 'asc'])
          : props.data;

        setSortedData(sortedData);
      }
    }
  }, [
    props.collectionTotal,
    props.pageSize,
    pPaginationAction,
    activePage,
    props.sortDirection,
    props.sortField,
    props.data,
  ]);

  useEffect(() => {
    if (sortedData) {
      let arrData = filterPaginationData(activePage);
      if (!arrData.length && activePage !== 1) {
        arrData = filterPaginationData(1);
      }
      setPageData(arrData);
    }
  }, [sortedData, filterPaginationData, activePage]);

  // Create header columns
  const headerElements = props.cols.map((col, index) => {
    return (
      <Table.HeaderCell
        className={!col.mobileDisplay ? 'hideMob' : ''}
        key={`${col.title}-${index}`}
        width={col.width as SemanticWIDTHS}
      >
        {col.title}
      </Table.HeaderCell>
    );
  });

  // Add actions header column if required
  if (props.actions) {
    headerElements.push(
      <Table.HeaderCell className={'actionCol'} key={'action'}>
        {''}
      </Table.HeaderCell>,
    );
  }

  // Create body content
  const bodyElements =
    !pageData || !pageData.length ? (
      <Table.Row className={'NoData'}>
        <Table.Cell
          colSpan={headerElements.length}
          textAlign={'center'}
          key={'no-data'}
        >
          No Data - Please try refreshing or adding more data.
        </Table.Cell>
      </Table.Row>
    ) : (
      pageData.map((item, index) => {
        let elements: any[] = [];
        const clonedCols = cloneDeep(props.cols);
        for (let i = 0; i < clonedCols.length; i++) {
          let col: Column = clonedCols[i];

          // Replace param strings in dataField
          let varParam = col.dataField.match(/\[(.*)]/);

          if (varParam) {
            let replacementField = item[varParam[1]];
            col.dataField = col.dataField.replace(
              varParam[0],
              `[${replacementField}]`,
            );
          }

          let value =
            ((get(item, col.dataField) as unknown) as boolean) === false
              ? 'false'
              : get(item, col.dataField) || '';

          if (col.dataProcess) {
            let process = true;
            if (col.dataProcess.condition) {
              const condition = col.dataProcess.condition;
              if (item[condition.field] !== condition.value) {
                process = false;
              }
            }

            if (process) {
              value = col.dataProcess.action(
                value,
                col.dataProcess.data,
                col.dataProcess.field,
                col.dataProcess.dataField
                  ? get(item, col.dataProcess.dataField)
                  : null,
              );
            }
          }

          switch (col.type) {
            case DataTableType.DATE:
              value = value ? (
                <Moment format="DD/MM/YYYY">{value}</Moment>
              ) : (
                '-'
              );
              break;
            case DataTableType.DATE_TIME:
              value = value ? (
                <Moment format="DD/MM/YYYY HH:mm">{value}</Moment>
              ) : (
                '-'
              );
              break;
            case DataTableType.EMAIL:
              break;
            case DataTableType.POSTAL_CODE:
              value = value.toUpperCase();
              break;
            case DataTableType.NUMBER:
              value = parseFloat(value ? value : 0) || 0;
              break;
            case DataTableType.MEDIA:
              if (item.mediaType === MediaType.VIDEO) {
                value = (
                  <div className={'DataTableImageContainer'}>
                    <VideoThumbnail
                      videoUrl={value}
                      width={100}
                      height={70}
                      snapshotAtTime={0}
                    />
                    <a href={value} target={'_blank'} rel={'noreferrer'}>
                      <FontAwesomeIcon icon={faDownload} />
                    </a>
                  </div>
                );
              } else {
                value = (
                  <div
                    className={'DataTableImageContainer'}
                    style={{backgroundImage: `url(${value})`}}
                  >
                    <a href={value} target={'_blank'} rel={'noreferrer'}>
                      <FontAwesomeIcon icon={faDownload} />
                    </a>
                  </div>
                );
              }
              break;
            case DataTableType.ATTACHMENT:
              value = (
                <div className={'DataTableDownload'}>
                  <a href={value} target={'_blank'} rel={'noreferrer'}>
                    <FontAwesomeIcon icon={faDownload} />
                  </a>
                </div>
              );
              break;
            case DataTableType.CURRENCY:
              value = `£${parseFloat(value || 0).toFixed(2)}`;
              break;
            case DataTableType.STRING:
              value = startCase(value?.toString());
              break;
            case DataTableType.STATUS_CHECK:
              value =
                value === 'completed' ? (
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    color={'#8dc83c'}
                    size={'2x'}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    color={'#a2a4a7'}
                    size={'2x'}
                  />
                );
              break;
            case DataTableType.JOB_PREFIX_ID:
              value = `${
                item.jobPrefix ? `${item.jobPrefix}-` : ''
              }${item.jobId || ''}`;
              break;
            case DataTableType.BUILD_INVOICE_PDF:
              if (item.status !== PricingItemStatus.SENT) {
                value = '-';
                break;
              }
              const contactBilling = props.additional.customer?.contact.find(
                (c) => c._id === props.additional.selected.contact.billing,
              );
              const contactInstallation = props.additional.customer?.contact.find(
                (c) => c._id === props.additional.selected.contact.installation,
              );
              value = (
                <InvoicePdfBuilder
                  buttonTitle={'Create Invoice'}
                  buttonTitleView={'Create Invoice'}
                  data={props.additional.selected}
                  loggedInUser={props.additional.loggedInUser?._id}
                  loggedInCompany={props.additional.loggedInCompany}
                  action={props.crudAction}
                  customer={props.additional.customer as Customer}
                  contact={{
                    billing: contactBilling || contactInstallation,
                    installation: contactInstallation,
                  }}
                  payment={item}
                  onChange={(result) =>
                    window.open(MediaHelper.getMediaUrl(result))
                  }
                />
              );
              break;
            default:
              value = value || '-';
              break;
          }

          elements.push(
            <Table.Cell
              className={!col.mobileDisplay ? 'hideMob' : ''}
              key={`${col.dataField}-${value}-${index}-${i}`}
              onClick={
                props.clickAction
                  ? () =>
                      props.handleAction(
                        props.clickAction,
                        // NEED TO MAKE GENERIC
                        item._id || item.mediaId,
                      )
                  : null
              }
            >
              <div
                className={col.className ? col.className(value, item) : null}
                style={{maxWidth: 490, wordWrap: 'break-word'}}
              >
                {col.type === DataTableType.NUMBER && (value || 0)}
                {col.type !== DataTableType.NUMBER && (value || '-')}
              </div>
            </Table.Cell>,
          );
        }

        // Add actions to body if required
        if (props.actions) {
          const actionElement = props.actions.map((action) => (
            <Button
              className={action.className}
              key={`action-${action.title}`}
              type="submit"
              icon
              labelPosition="left"
              onClick={() =>
                props.handleAction(
                  action.action,
                  // NEED TO MAKE GENERIC
                  item._id || item.mediaId,
                  action.data,
                  action.dataType,
                )
              }
              size="small"
            >
              {action.icon && <Icon name={action.icon} />}
              {action.title}
            </Button>
          ));

          elements.push(
            <Table.Cell key={`action-${actionElement}`} width={1}>
              {actionElement}
            </Table.Cell>,
          );
        }

        return (
          <Table.Row key={`${item._id}${item.created}-${index}`}>{elements}</Table.Row>
        );
      })
    );

  return (
    <div
      className={`DataTableContainer ${props.className}`}
      style={props.style}
    >
      <div className={'DataTableHeader'}>
        {props.title && (
          <div className={'DataTableTitleContainer'}>
            {props.titleIcon}
            {typeof props.title === 'string' ? (
              <h1>{props.title}</h1>
            ) : (
              props.title
            )}
          </div>
        )}

        {props.calendar && (
          <div className={'DataTableCalendarSwitch'}>
            <label>
              <FontAwesomeIcon icon={faEye} />
              <Dropdown
                style={{minWidth: 100}}
                selection
                value={viewType}
                options={[
                  {
                    value: ViewType.TABLE,
                    text: 'Table',
                  },
                  {
                    value: ViewType.CALENDAR,
                    text: 'Calendar',
                  },
                  {
                    value: ViewType.SUMMARY,
                    text: 'Summary',
                  },
                ]}
                onChange={(e, data) => {
                  if (data.value !== ViewType.CALENDAR) {
                    props.paginationAction(1, true);
                  } else {
                    const start = moment()
                      .startOf('month')
                      .subtract(7, 'days');

                    const end = moment()
                      .endOf('month')
                      .add(7, 'days');
                    props.calendarAction(start, end);
                  }

                  setViewType(data.value as ViewType);
                }}
              />
            </label>
          </div>
        )}

        {props.export && (
          <Button
            loading={exporting}
            disabled={
              exporting ||
              !props.data?.length ||
              !props.additional.customers?.length ||
              !props.additional.users?.length
            }
            className={'ExportButton'}
            style={{marginRight: 10}}
            icon
            secondary
            labelPosition="left"
            onClick={async () => {
              setExporting(true);
              await JobExportData(
                props.additional.filters,
                await getTokenSilently(),
                props.additional.loggedInUser,
              );
              setExporting(false);
            }}
          >
            <Icon name="download" />
            Export
          </Button>
        )}

        {props.setHideFilter && (
          <Button
            className={'HideFilterButton'}
            style={{marginRight: 10}}
            icon
            secondary
            labelPosition="left"
            onClick={() => props.setHideFilter(!props.hideFilter)}
          >
            <Icon name="filter" />
            {props.hideFilter ? 'Show Filters' : 'Hide Filters'}
          </Button>
        )}

        {props.primaryAction && (
          <Button
            className={props.primaryActionClass}
            disabled={props.disabledPrimaryAction}
            icon
            primary
            labelPosition="left"
            onClick={() =>
              props.handleAction(props.primaryAction, null, props.addParams)
            }
          >
            <Icon name="add" />
            {props.primaryActionTitle
              ? props.primaryActionTitle
              : `Add ${props.dataType === DataType.MEDIA ? 'Photos/Videos' : ''}
            ${props.dataType === DataType.CONTACT ? 'Contact/Address' : ''}
            ${props.dataType === DataType.CHECKLIST ? 'Task' : ''}
            ${
              props.dataType !== DataType.MEDIA &&
              props.dataType !== DataType.CONTACT &&
              props.dataType !== DataType.CHECKLIST
                ? startCase(props.dataType)
                : ''
            }`}
          </Button>
        )}
      </div>

      {props.headline && (
        <div className={'DataTableHeader'}>{props.headline}</div>
      )}

      {props.description && viewType === ViewType.TABLE && (
        <div className={'DataTableDescription'}>{props.description}</div>
      )}

      {viewType === ViewType.SUMMARY && props.metrics && (
        <div className={'DataTableSummaryContainer'}>
          {props.loggedInUser?.permissions.financial.job.pricing && (
            <>
              <div className={'SummaryStatRow'}>
                <div className={'SummaryStat'}>
                  <p>Total Jobs</p>
                  <p>{props.metrics?.total || 0}</p>
                </div>

                <div className={'SummaryStat'}>
                  <p>Total Net Value</p>
                  <p>£{props.metrics?.value || 0}</p>
                </div>

                <div className={'SummaryStat'}>
                  <p>Total Gross Profit</p>
                  <p>£{props.metrics?.profit || 0}</p>
                </div>
              </div>
            </>
          )}

          <div className={'SummaryStatRow'}>
            {props.metrics?.stages && (
              <div className={'SummaryGraph'}>
                <p>Job Stages</p>

                {Object.values(props.metrics?.stages || {}).every(
                  (s) => !s,
                ) && <p style={{fontSize: 14}}>No data to show</p>}

                {Object.values(props.metrics?.stages || {}).some(
                  (s) => s > 0,
                ) && (
                  <PieChart
                    animate
                    lineWidth={35}
                    paddingAngle={5}
                    label={({dataEntry}) => dataEntry.value}
                    labelStyle={(i) => ({
                      fill:
                        i % 3 === 1
                          ? '#233592'
                          : i % 2 === 1
                          ? '#8dc83c'
                          : '#20abe2',
                      fontSize: '5px',
                      fontFamily: 'sans-serif',
                    })}
                    labelPosition={112}
                    data={Object.entries(props.metrics.stages)
                      .filter(([s, c]) => c > 0)
                      ?.map(([s, c], i) => {
                        return {
                          title: startCase(
                            jobStageMap(
                              s as JobStage,
                              props.loggedInCompany.stageTitles,
                            ),
                          ),
                          value: c as number,
                          color:
                            i % 3 === 1
                              ? '#233592'
                              : i % 2 === 1
                              ? '#8dc83c'
                              : '#20abe2',
                        };
                      })}
                  />
                )}
              </div>
            )}

            {props.metrics?.status && (
              <div className={'SummaryGraph'}>
                <p>Job Status</p>

                {Object.values(props.metrics?.status || {}).every(
                  (s) => !s,
                ) && <p style={{fontSize: 14}}>No data to show</p>}

                {Object.values(props.metrics?.status || {}).some(
                  (s) => s > 0,
                ) && (
                  <PieChart
                    animate
                    lineWidth={35}
                    paddingAngle={5}
                    label={({dataEntry}) => dataEntry.value}
                    labelStyle={(i) => ({
                      fill: DataHelper.statusColourHex(
                        Object.entries(props.metrics.status).filter(
                          ([s, c]) => c > 0,
                        )[i][0] as string,
                      ),
                      fontSize: '5px',
                      fontFamily: 'sans-serif',
                    })}
                    labelPosition={112}
                    data={Object.entries(props.metrics.status)
                      .filter(([s, c]) => c > 0)
                      ?.map(([s, c], i) => {
                        return {
                          title: startCase(s),
                          value: c as number,
                          color: DataHelper.statusColourHex(s),
                        };
                      })}
                  />
                )}
              </div>
            )}
          </div>

          <div className={'RatingStatRow'}>
            <p>Customer Satisfaction Rating</p>
            <div>
              {Object.entries(props.metrics.ratings).map(
                ([rating, count], i) => (
                  <div
                    className={'StarRatingContainer'}
                    key={`rating-${rating}-${i}`}
                  >
                    {buildStarRating(+rating || 0)}
                    <p>{count}</p>
                  </div>
                ),
              )}
            </div>
          </div>
        </div>
      )}

      {viewType === ViewType.CALENDAR && (
        <div className={'DataTableCalendarContainer'}>
          <DragAndDropCalendar
            localizer={localizer}
            views={['month', 'week']}
            events={props.calendarData}
            startAccessor="start"
            endAccessor="end"
            titleAccessor={(obj) => {
              const jobType = obj.title.type;
              const customer = obj.title.customer;
              const address = obj.title.address;

              return `${jobType} - ${customer} - ${address}`;
            }}
            tooltipAccessor={(obj) => {
              let title = `Job Type: ${obj.title.type}`;

              if (obj.title.customer) {
                title += `\nCustomer: ${obj.title.customer}`;
              }
              if (obj.title.address) {
                title += `\nAddress: ${obj.title.address}`;
              }
              if (obj.title.user) {
                title += `\nAssigned To: ${obj.title.user}`;
              }

              title += `\nSold: ${obj.title.sold ? 'Yes' : 'No'}`;

              if (obj.title.balance) {
                title += `\nValue: £${obj.title.balance}`;
              }

              return title;
            }}
            popup
            style={{height: '100%'}}
            eventPropGetter={(event: CalendarEvent) => ({
              className: event.backgroundColor,
            })}
            onSelectEvent={(event: CalendarEvent) => {
              return props.clickAction
                ? props.handleAction(props.clickAction, event._id)
                : null;
            }}
            onNavigate={(date) => {
              const start = moment(date)
                .startOf('month')
                .subtract(7, 'days');

              const end = moment(date)
                .endOf('month')
                .add(7, 'days');

              props.calendarAction(start, end);
            }}
            components={{
              event: (event) => (
                <div className={'DataTableCalendarEvent'}>
                  <span className={'DataTableCalendarEventLabel'}>
                    {event.event.label}
                  </span>
                  <span>{event.title}</span>
                </div>
              ),
            }}
            onEventDrop={async (event) => {
              const job = cloneDeep(
                props.data.find((j) => j._id === event.event._id),
              );

              const stage = Object.entries(
                props.loggedInCompany.stageTitles,
              ).find(
                ([stage, title]) =>
                  title.toLowerCase() === event.event.label.toLowerCase(),
              ) || [
                jobDefaultStageTitleMap(event.event.label.toLowerCase()),
                event.event.label,
              ];

              if (
                calendarView !== 'week' ||
                (!event.event.allDay && !event.isAllDay) ||
                (event.event.allDay && event.isAllDay)
              ) {
                if (
                  job.stages[stage[0]].start !==
                  moment(event.start).toISOString()
                ) {
                  job.stages[stage[0]].start = moment(
                    event.start,
                  ).toISOString();
                  job.stages[stage[0]].due = moment(event.end).toISOString();
                  const token = await getTokenSilently();

                  await axios.put(
                    `${config.api}/job/?company=${props.loggedInUser.company}`,
                    job,
                    {
                      headers: {Authorization: 'Bearer ' + token},
                    },
                  );
                }
              }
            }}
            onView={(view) => setCalendarView(view)}
          />
        </div>
      )}

      {viewType === ViewType.TABLE && (
        <div className={'DataTableDataContainer'}>
          <Table celled padded columns={props.width as SemanticWIDTHS}>
            <Table.Header>
              <Table.Row>{headerElements}</Table.Row>
            </Table.Header>

            <Table.Body>{bodyElements}</Table.Body>
          </Table>
        </div>
      )}
      {viewType === ViewType.TABLE && props.data && props.data.length ? (
        <div className={'DataTablePagination'}>
          <div className={'DataTablePaginationTotals'}>
            Showing{' '}
            {activePage === 1
              ? '1'
              : (activePage - 1) * (props.pageSize || 10) + 1}{' '}
            -{' '}
            {Math.min(
              activePage * (props.pageSize || 10),
              activePage > 1
                ? props.collectionTotal || props.data.length
                : props.data.length || props.collectionTotal || 0,
            )}{' '}
            of {props.collectionTotal || props.data.length || 0} results
          </div>
          {props.apiSortOrder && (
            <div className={'DataTablePaginationSort'}>
              <Dropdown
                value={props.apiSortOrder}
                selection
                options={[
                  {
                    key: SortOrder.NEWEST_FIRST,
                    text: 'Newest first',
                    value: SortOrder.NEWEST_FIRST,
                  },
                  {
                    key: SortOrder.OLDEST_FIRST,
                    text: 'Oldest first',
                    value: SortOrder.OLDEST_FIRST,
                  },
                ]}
                onChange={(e, data) =>
                  handlePaginationChange(activePage, data.value as SortOrder)
                }
              />
            </div>
          )}
          {(activePage === 1
            ? (props.collectionTotal || props.data.length) >
              (props.pageSize || 10)
            : (props.collectionTotal || props.data.length || 0) >
              (props.pageSize || 10)) && (
            <div className={'DataTablePaginationButtons'}>
              <Pagination
                onPageChange={(e, data) =>
                  handlePaginationChange(data.activePage)
                }
                activePage={activePage}
                totalPages={Math.ceil(
                  (props.collectionTotal || props.data.length || 0) /
                    (props.pageSize || 10),
                )}
                firstItem={null}
                lastItem={null}
              />
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default DataTable;
