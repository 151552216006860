import {cloneDeep, isEqual} from 'lodash';
import React, {useCallback, useEffect, useState} from 'react';
import {Router, Switch} from 'react-router-dom';
import {Dimmer, Loader} from 'semantic-ui-react';
import 'react-datepicker/dist/react-datepicker.css';
import {CRUDType} from './components/Data/DataConst';
import {showDataFormInitialState} from './components/Data/DataForm';
import {PromptModal} from './components/Data/PromptModal';
import PrivateRoute from './components/PrivateRoute';
import config from './config/config';
import {DataSubType, DataType} from './const/crud';
import {HeapWindow} from './const/window';
import Companies from './containers/Companies';
import Customers from './containers/Customers';
import Jobs from './containers/Jobs';
import Page from './containers/Page';
import Users from './containers/Users';
import {Company, companyInitialState, CompanyStatus} from './models/Company';
import {userInitialState, UserRole} from './models/User';
import {useAuth0} from './react-auth0-spa';
import CRUDHelper from './utils/crudHelper';
import history from './utils/history';
import {
  ModalHelper,
  ModalType,
  showModalInitialState,
} from './utils/modalHelper';
import SocketHelper from './utils/socketHelper';

const socketHelper = new SocketHelper();

function App() {
  // Set states
  const [token, setToken] = useState<string>();
  const [socketConnecting, setSocketConnecting] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [isExpired, setIsExpired] = useState(false);
  const [notPermitted, setNotPermitted] = useState(false);

  const [isLoadingLoggedInUser, setIsLoadingLoggedInUser] = useState(false);
  const [loggedInUser, setLoggedinUser] = useState(userInitialState);
  const [loggedInCompany, setLoggedInCompany] = useState(companyInitialState);

  const [showDataForm, setShowDataForm] = useState(showDataFormInitialState);
  const [showModal, setShowModal] = useState(showModalInitialState);

  const crudAction = async (
    action: CRUDType,
    dataType: DataType,
    data: any,
    completeAction = true,
  ) => {
    try {
      const result = await helpers.crud
        .process(cloneDeep(data), loggedInUser._id)
        .run(action, dataType, undefined);

      if (completeAction) {
        setDataForm(false);
        helpers.modal.showModal(ModalType.SUCCESS);
      }

      if (action === CRUDType.UPDATE && dataType === DataType.COMPANY) {
        if (result.data._id === loggedInCompany._id) {
          setLoggedInCompany(result.data);
        }
      }

      return result;
    } catch (err) {
      setIsLoading(false);
      setDataForm(false);
      helpers.modal.showModal(
        ModalType.ERROR,
        {
          status: [CRUDType.CREATE, CRUDType.UPDATE].includes(action),
          formType: action,
        },
        undefined,
        dataType === DataType.USER
          ? `User could not be created. Please check that the email address isn't already registered and try again.`
          : 'Something went wrong, please try again later.',
      );
    }
  };

  const helpers = {
    crud: new CRUDHelper(token, loggedInUser, loggedInCompany, setIsLoading),
    modal: new ModalHelper(setShowModal, setShowDataForm),
    socket: socketHelper,
  };

  const {getTokenSilently, loading, user} = useAuth0();

  const getToken = useCallback(async () => {
    setToken(await getTokenSilently());
  }, [getTokenSilently]);

  const getLoggedInUser = useCallback(async () => {
    if (!isLoadingLoggedInUser && !isExpired && !notPermitted) {
      setIsLoadingLoggedInUser(true);
      const authId = user && user.sub;

      if (isEqual(loggedInUser, userInitialState)) {
        let result;
        try {
          const url = `${config.api}/${DataType.USER}/auth/${authId}?company=${loggedInUser.company}`;
          result = await helpers.crud.run(
            CRUDType.READ_ONE,
            DataType.LOGGED_IN_USER,
            url,
          );
        } catch (err) {
          setNotPermitted(true);
          helpers.modal.showModal(
            ModalType.NOT_PERMITTED,
            {status: false},
            undefined,
            null,
            undefined,
          );
          return;
        }

        // Check if is allowed to login to the admin panel
        if (
          !result ||
          ![UserRole.SUPER_ADMIN, UserRole.MANAGER, UserRole.ADMIN].includes(
            result.data.role,
          )
        ) {
          setNotPermitted(true);
          helpers.modal.showModal(
            ModalType.NOT_PERMITTED,
            {status: false},
            undefined,
            null,
            undefined,
          );
          return;
        }

        try {
          // Look up company to see if subscription is active
          const companyUrl = `${config.api}/${DataType.COMPANY}/${result.data.company}?company=${result.data.company}`;
          const company: Company = (await helpers.crud.run(
            CRUDType.READ_ONE,
            DataType.COMPANY,
            companyUrl,
          )).data;

          if (company.status === CompanyStatus.INACTIVE || company.archived === true) {
            setIsExpired(true);

            helpers.modal.showModal(
              ModalType.SUBSCRIPTION_EXPIRED,
              {status: false},
              undefined,
              null,
              {
                action: {
                  type: 'logout',
                  dataType: DataType.COMPANY,
                  dataSubType: DataSubType.SELECTED,
                },
              },
            );
            return;
          }

          setLoggedInCompany(company);
        } catch (err) {
          setIsExpired(true);

          helpers.modal.showModal(
            ModalType.SUBSCRIPTION_EXPIRED,
            {status: false},
            undefined,
            null,
            {
              action: {
                type: 'logout',
                dataType: DataType.COMPANY,
                dataSubType: DataSubType.SELECTED,
              },
            },
          );
          return;
        }

        setLoggedinUser(result.data);
      }
    }
  }, [
    notPermitted,
    isExpired,
    loggedInUser,
    user,
    helpers.crud,
    helpers.modal,
    isLoadingLoggedInUser,
  ]);

  useEffect(() => {
    if (loggedInUser.company && token && !socketConnecting) {
      setSocketConnecting(true);
      socketHelper.connect(token, loggedInUser.company);
    }
  }, [loggedInUser.company, token, socketConnecting]);

  const getData = useCallback(async () => {
    await getToken();

    if (token) {
      await getLoggedInUser();

      setIsLoading(false);
    }
  }, [getToken, getLoggedInUser, token]);

  const setDataForm = (
    status: boolean,
    formType: CRUDType = CRUDType.CREATE,
  ) => {
    setShowDataForm({status, formType});
  };

  useEffect(() => {
    if (loggedInUser.company) {
      const heapWindow = (window as unknown) as HeapWindow;
      heapWindow.heap.identify(loggedInUser._id);
      heapWindow.heap.addUserProperties({
        Name: `${loggedInUser.firstName} ${loggedInUser.lastName}`,
        Role: loggedInUser.role,
        CompanyId: loggedInUser.company,
        Company: loggedInCompany.name,
      });
    }
  }, [
    loggedInCompany.name,
    loggedInUser._id,
    loggedInUser.company,
    loggedInUser.firstName,
    loggedInUser.lastName,
    loggedInUser.role,
  ]);

  useEffect(() => {
    if (!loading) {
      getData();
    }
  }, [loading, getData]);

  const commonProps = {
    authToken: token,
    loggedInUser: loggedInUser,
    loggedInCompany: loggedInCompany,
    crudAction: crudAction,
    showDataForm: showDataForm,
    setDataForm: setDataForm,
    helpers,
  };

  const getHomeScreen = (props) => {
    let title;
    let content;
    switch (loggedInUser.role) {
      case UserRole.SUPER_ADMIN:
      case UserRole.MANAGER:
        title = 'Companies';
        content = <Companies {...props} {...commonProps} isHome={true} />;
        break;
      case UserRole.ADMIN:
        title = 'Users';
        content = <Users {...props} {...commonProps} isHome={true} />;
        break;
      default:
        title = 'Jobs';
        content = <Customers {...props} {...commonProps} isHome={true} />;
    }

    return (
      <Page {...props} {...commonProps} pageTitle={title}>
        {content}
      </Page>
    );
  };

  const isHome = (page: string) => {
    switch (loggedInUser.role) {
      case UserRole.SUPER_ADMIN:
      case UserRole.MANAGER:
        if (page === 'Companies') {
          return true;
        }
        break;
      case UserRole.ADMIN:
        if (page === 'Users') {
          return true;
        }
        break;
      default:
        if (page === 'Jobs') {
          return true;
        }
    }
  };

  if (notPermitted || isExpired) {
    return (
      <Dimmer.Dimmable as={'div'} blurring dimmed={isLoading} className="App">
        <PromptModal
          modalHelper={helpers.modal}
          showModal={showModal}
          crudAction={crudAction}
          loggedInUser={loggedInUser}
          loggedInCompany={loggedInCompany}
          dataControl={() => socketHelper.getState()}
          setLoading={setIsLoading}
        />

        <Dimmer inverted active={isLoading} page>
          <Loader size={'big'}>Loading</Loader>
        </Dimmer>
      </Dimmer.Dimmable>
    );
  }

  return (
    <Dimmer.Dimmable as={'div'} blurring dimmed={isLoading} className="App">
      <Router history={history}>
        <Switch>
          <PrivateRoute
            path="/"
            exact
            loggedInUser={loggedInUser}
            render={(props) => getHomeScreen(props)}
          />
          <PrivateRoute
            path="/companies"
            exact
            loggedInUser={loggedInUser}
            render={(props) => (
              <Page {...props} {...commonProps} pageTitle={'Companies'}>
                <Companies
                  {...props}
                  {...commonProps}
                  isHome={isHome('Companies')}
                />
              </Page>
            )}
          />
          <PrivateRoute
            path="/users"
            exact
            loggedInUser={loggedInUser}
            render={(props) => (
              <Page {...props} {...commonProps} pageTitle={'Users'}>
                <Users {...props} {...commonProps} isHome={isHome('Users')} />
              </Page>
            )}
          />
          <PrivateRoute
            path="/customers"
            exact
            loggedInUser={loggedInUser}
            render={(props) => (
              <Page {...props} {...commonProps} pageTitle={'Customers'}>
                <Customers
                  {...props}
                  {...commonProps}
                  isHome={isHome('Customers')}
                />
              </Page>
            )}
          />
          <PrivateRoute
            path="/jobs"
            exact
            loggedInUser={loggedInUser}
            render={(props) => (
              <Page {...props} {...commonProps} pageTitle={'Jobs'}>
                <Jobs {...props} {...commonProps} isHome={isHome('Jobs')} />
              </Page>
            )}
          />
          <PrivateRoute
            path="/forbidden"
            exact
            loggedInUser={loggedInUser}
            render={(props) => (
              <Page {...props} {...commonProps} pageTitle={'Forbidden'}>
                <div>
                  <h1>This page is forbidden.</h1>
                </div>
              </Page>
            )}
          />
        </Switch>
      </Router>

      <PromptModal
        modalHelper={helpers.modal}
        showModal={showModal}
        crudAction={crudAction}
        loggedInUser={loggedInUser}
        loggedInCompany={loggedInCompany}
        dataControl={() => socketHelper.getState()}
        setLoading={setIsLoading}
      />

      <Dimmer inverted active={isLoading} page>
        <Loader size={'big'}>Loading</Loader>
      </Dimmer>
    </Dimmer.Dimmable>
  );
}

export default App;
