import ObjectId from 'bson-objectid';
import {cloneDeep} from 'lodash';
import React, {useState} from 'react';
import {Button, Form, Icon, Input, Table} from 'semantic-ui-react';
import {User} from '../models/User';

interface ChecklistProps {
  data: any;
  setTasks: any;
  loggedInUser: User;
}

const ChecklistTemplate: React.FunctionComponent<ChecklistProps> = (
  props: ChecklistProps,
) => {
  const [task, setTask] = useState<string>();

  const deleteTask = (id) => {
    const cloned = props.data['checklist.tasks'].filter((i) => i._id !== id);
    props.setTasks(cloned);
  };

  return (
    <div className={'ChecklistTemplate'}>
      <h1>Add Task</h1>

      <div className={'AddTask'}>
        <Form.Field>
          <Input
            style={{marginBottom: 20}}
            placeholder={`Enter a task`}
            value={task}
            onChange={(e, data) => {
              setTask(data.value);
            }}
          />
        </Form.Field>
        <Button
          disabled={!task?.length}
          primary
          onClick={() => {
            const taskObj = {
              _id: new ObjectId().toHexString(),
              created: new Date(),
              createdBy: props.loggedInUser._id,
              text: task,
              status: 'not-completed',
            };

            const cloned = cloneDeep(props.data['checklist.tasks'] || []);
            cloned.push(taskObj);

            props.setTasks(cloned);

            setTask('');
          }}
        >
          Add Task
        </Button>
      </div>

      <h1>Tasks</h1>

      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign={'center'}>Task Name</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {(!props.data ||
            !props.data['checklist.tasks'] ||
            !props.data['checklist.tasks'].length) && (
            <Table.Row>
              <Table.Cell textAlign={'center'}>
                <p>No tasks found</p>
              </Table.Cell>
            </Table.Row>
          )}

          {props.data &&
            props.data['checklist.tasks'] &&
            props.data['checklist.tasks'].map((i) => {
              return (
                <Table.Row key={`checklist-task-${i.text}-${i._id}`}>
                  <Table.Cell width={10} textAlign={'center'}>
                    <p>{i.text}</p>
                  </Table.Cell>
                  <Table.Cell width={1} textAlign={'center'}>
                    <Button
                      className={'redButton'}
                      icon={true}
                      labelPosition={'left'}
                      onClick={() => deleteTask(i._id)}
                    >
                      <Icon name="delete" />
                      Delete
                    </Button>
                  </Table.Cell>
                </Table.Row>
              );
            })}
        </Table.Body>
      </Table>
    </div>
  );
};

export default ChecklistTemplate;
